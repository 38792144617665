export const actionTypes = {
    GET_COMMENT_LIST: "GET_COMMENT_LIST",
    SET_COMMENT_LIST: "SET_COMMENT_LIST",
    ADD_COMMENT: "ADD_COMMENT",
    ADD_COMMENT_RESPONSE: "ADD_COMMENT_RESPONSE",
    UPDATE_COMMENT: "UPDATE_COMMENT",
    UPDATE_COMMENT_RESPONSE: "UPDATE_COMMENT_RESPONSE",
    DELETE_COMMENT: "DELETE_COMMENT",
    DELETE_COMMENT_RESPONSE: "DELETE_COMMENT_RESPONSE",
};

export function getCommentList(payload) {
    return {
        type: actionTypes.GET_COMMENT_LIST,
        payload: payload
    };
}

export function addComment(payload) {
    return {
        type: actionTypes.ADD_COMMENT,
        payload: payload
    };
}

export function updateComment(payload) {
    return {
        type: actionTypes.UPDATE_COMMENT,
        payload: payload
    };
}

export function deleteComment(payload) {
    return {
        type: actionTypes.DELETE_COMMENT,
        payload: payload
    };
}