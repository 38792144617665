import React, { useState } from 'react'
import PropTypes from "prop-types";
import moment from 'moment';
import CommonLoading from '../common/components/commonLoading';
import { Grid } from '@mui/material';
import "../../styles/dashboard.scss";
import xls_export from "../../assets/images/marker/xls-export.png";
import harvest from "../../assets/images/harvest.svg";
import fruit from "../../assets/images/allfruit.jpg";



const NewMapSearchResultComponent = () => {

    const [isLoading, setIsLoading]=useState(false);
    const [searchResultView, setSearchResultView] = useState(false);
    const [singleSection, setSingleSection] = useState(false);
    const [sortBy, setSortBy] = useState("asc");


    const getTodayStatus = (item) => {
        let displayWorkingClass = "btnLinkRed";
        let checkHoliday = "Closed Today"
        if(item.openToday === "Y"){
          displayWorkingClass = "btnLink";
          checkHoliday = "Open Today";
          if((new Date().getTime() > new Date(moment(item.todayStartTime, "hh:mm a")).getTime() && new Date().getTime() < new Date(moment(item.todayEndTime, "hh:mm a")).getTime())){
            checkHoliday = "Open Now";
          }else if(new Date().getTime() > new Date(moment(item.todayEndTime, "hh:mm a")).getTime()){
            checkHoliday = "Closed Now";
            displayWorkingClass = "btnLinkRed";
          }
        }
        return (<>
          <div className="row">
            <div className="col-md-5 col-lg-5 col-sm-11 ml-3 mr-2">
                <small className={`${displayWorkingClass} mb-2 mt-2`} style={{ display: "inline-block" }}>{checkHoliday}</small>
            </div>
          {/* {item.store.specialOperationFlag === "Y" && (
            <div className="col-md-2 col-sm-1 p-0 popover__wrapper m-0">
              <img src={soh_warning} className="soh-warning" data-toggle="tooltip" onMouseOver={()=>{
                   if (specialHourId !==item.store.id) {
                      setSpecialHourId(item.store.id);
                      // getSpecialHourData(item.store.id);
                   }                     
              }} data-placement="bottom" style={{ color: "#006e52", display: "inline-block"}} />
              <div className={`${item.store.storeSpecialOperation && item.store.storeSpecialOperation.length ? "popover__content" : "d-none"}`}>
                <div className="row">
                  <div className="col-md-12 p-0">
                    <div className="special-timings" id="specialTiming_1ce781cf-dc40-15c9-5ae8-90246d330745"><small className="boldFont resText">Special Hour(s) of Operation</small>
                      {
                        item.store.storeSpecialOperation && item.store.storeSpecialOperation.length &&
                        item.store.storeSpecialOperation.map((item,i)=>(
                          getSpecialHoursRows(item,i)
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}  //warning pop-up */}
          </div>
          {item.openToday  === "Y" && item.todayStartTime && item.todayEndTime && 
            <div className={`col-md-6 col-sm-12`}>
              <button aria-label="button" aria-labelledby="button" className="btn btn-default cursor-none btn-rounded btn-block btn-lg mt-2 btn-font reSizeFont" style={{ padding: "12px 10px", display: "block" }}>{item.todayStartTime+" - "+ item.todayEndTime}</button> 
            </div>
          }
        </>)
      }

      const handleClick= () => {
        setSearchResultView(false);
        setSingleSection(false);
      }
  return (
       <Grid container xs={12} spacing={2} >
          <Grid item xs={6} >
            <div className='bg-white d-none d-sm-none d-md-block d-lg-block active'
              style={{height:'420.547px',width:'100%'}}
              id="setHeight"
              aria-hidden="false"
            >
              <div className='row' style={{margin:'10px'}}>
               <div className="col-md-12 text-right resultLabel">
                  <span style={{ marginRight: "10px", fontSize: "18px" }} >
                    <i id='upArrow' className='fa fa-long-arrow-up sortColor' style={{color:"#2a6e52"}} aria-hidden='true'
                     tabIndex={0} aria-label='Sort Up'></i>
                    <i id='downArrow' className='fa fa-long-arrow-down sortColorGreen' aria-hidden='true' tabIndex={0} aria-label='Sort-Down'></i>
                  </span>
                  <a className='mr-3 cursor' tabIndex={'0'}>
                    <img src={xls_export} width={'26'} alt='export' />
                    <small className='boldFont'><u>Export</u></small>
                  </a>
                  <a  className="cursor btn btn-rounded btn-default btn-sm btn-bg btn-font" style={{float:'right'}}
                    onClick={handleClick}
                    tabIndex={'0'}
                  >
                    <i className='fa fa-close cusIcon cursor'></i>
                  </a>

                </div>
                <div className={`row storeList`} tabIndex={'0'} style={{overflow:'auto',margin:'10px',height: '389.594px'}}>
                  
                <div
                  className="col-md-12 p-none"
                  // key={location.store.id}
                  // id={location.store.id}
                  >
                  <div className="card cardShadow cardHover mt-2 253" style={{border:'1px solid rgba(0,0,0,0.125)',backgroundColor:'rgb(255,255,255)'}}>
                    <div className="row mr-0 ml-0">
                      <div className="col-10 card-body">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <div className='row'>
                              <div className='col-md-12'>
                                <h4 className='text-color p-none address-heading'> {`Emily farm`}</h4>
                              </div>
                              <div className='col-md-12'>
                                <small className='boldFont'> {`HIP Mobile Market Stop`}</small>
                              </div>
                              <div className='col-md-10'>
                                <small className='boldFont'>
                                  <i className='fa fa-location-arrow cusIcon'></i>{" "}
                                  {`15.43` +
                                " miles away"}
                                </small>
                              </div>
                              <div className='col-md-10'>
                                <small>
                                  <i className='fa fa-map-pin cusIcon'></i>{" "}
                                  {` Walpole`}
                                </small>
                              </div>
                              <div className='col-md-12'>
                                <i className='fa fa-map-signs cusIcon'></i>&nbsp;
                                <small>{`999 North St Walpole MA 02081`}</small>
                              </div>
                              <div className='col-md-12'>
                                <i className='fa fa-phone cusIcon'></i>&nbsp;
                                <small><u>{`(726) 674-6726`}</u></small>
                                &nbsp;{" "}
                                <i className='fa fa-envelope cusIcon'></i>&nbsp;
                                <small>{`abc@gmail.com`}</small>
                              </div>
                              <div className='col-md-4 col-lg-4 p-0 col-sm-11 ml-3 mr-2'>
                                <small className='btnLinkRed mb-2 mt-2' style={{display:'inline-block',padding: '10px 5px'}}>{`Closed Now`}</small>
                              </div>
                              <div className='col-md-6 col-sm-12 ' style={{display:'block'}}>
                                <button aria-label='button' className='cursor-none btn btn-default btn-block btn-lg mt-2 btn-font reSizeFont' 
                                style={{padding:'12px 10px',display:'inline-block'}}>{`12:00 am - 12:00 pm`}</button>
                              </div>
                            </div>
                          </div>
   
                              
                        </div>
                      </div>
                      <div className="col-2">
                        <button
                        tabIndex={0}
                          className="btn btn-outline-primary right-arrow"
                          onClick={() => setSingleSection(true)}
                        >
                          <i className="n_nav-right-icon fa fa-chevron-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card cardShadow cardHover mt-2 253" style={{border:'1px solid rgba(0,0,0,0.125)',backgroundColor:'rgb(255,255,255)'}}>
                    <div className="row mr-0 ml-0">
                      <div className="col-10 card-body">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <div className='row'>
                              <div className='col-md-12'>
                                <h4 className='text-color p-none address-heading'> {`Emily farm`}</h4>
                              </div>
                              <div className='col-md-12'>
                                <small className='boldFont'> {`HIP Mobile Market Stop`}</small>
                              </div>
                              <div className='col-md-10'>
                                <small className='boldFont'>
                                  <i className='fa fa-location-arrow cusIcon'></i>{" "}
                                  {`15.43` +
                                " miles away"}
                                </small>
                              </div>
                              <div className='col-md-10'>
                                <small>
                                  <i className='fa fa-map-pin cusIcon'></i>{" "}
                                  {` Walpole`}
                                </small>
                              </div>
                              <div className='col-md-12 ' >
                                <i className='fa fa-map-signs cusIcon'></i>&nbsp;
                                <small>{`999 North St Walpole MA 02081`}</small>
                              </div>
                              <div className='col-md-12'>
                                <i className='fa fa-phone cusIcon'></i>&nbsp;
                                <small><u>{`(726) 674-6726`}</u></small>
                                {" "}
                                <i className='fa fa-envelope cusIcon'></i>&nbsp;
                                <small>{`abc@gmail.com`}</small>
                              </div>
                              <div className='col-md-4 col-lg-4 p-0 col-sm-11 ml-3 mr-2'>
                                <small className='btnLinkRed mb-2 mt-2' style={{display:'inline-block',padding: '10px 5px'}}>{`Closed Now`}</small>
                              </div>
                              <div className='col-md-6 col-sm-12 ' style={{display:'block'}}>
                                <button aria-label='button' className='cursor-none btn btn-default btn-block btn-lg mt-2 btn-font reSizeFont' 
                                style={{padding:'12px 10px',display:'inline-block'}}>{`12:00 am - 12:00 pm`}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                         <button
                         tabIndex={0}
                          className="btn btn-outline-primary right-arrow"
                          onClick={() => setSingleSection(true)}
                        >
                          <i className="n_nav-right-icon fa fa-chevron-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
        </Grid>
        <Grid item  xs={6}>
        {singleSection && (<>
           <div className='bg-white d-none d-sm-none d-md-block d-lg-block active'
              style={{height:'420.547px',width:'100%'}}
              id="setHeight"
              aria-hidden="false"
            >
              <div className="col-md-12 totalLabelHeight">
                <div className='col-md-8 text-left p-none resultLabel'>
                  <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                      <li className='breadcrumb-item active' aria-current='page'>{`Emily Farm`}</li>
                    </ol>
                  </nav>
                </div>
                <div className='col-md-4 text-right p-none resultClose'>
                  <a className='cursor btn btn-rounded btn-default btn-sm btn-bg btn-font'
                  onClick={() => setSingleSection(false)}
                    >
                      <i className='fa fa-close cusIcon cursor'></i>
                  </a>
                </div>
              </div>
              <div className='row m-0 storeList' tabIndex={0} style={{overflow:'auto',margin:'10px',height: '389.594px'}}>
                <div className='col-md-12 singleStoreView'>
                  <div className='row card-overflow'>
                    <div className='card cardHover cursor bg' 
                      style={{border:'1px solid #006e52',background:'#e8f1ec',boxShadow:'0 1px 11px 1px rgba(0, 0, 0, 0.2)'
                      }}
                      >
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <h4 className='text-color p-none address-heading'>{`Emily farm`}</h4>
                            </div>
                            <div className='col-md-12'>
                              <small>
                                <i className='fa fa-map-pin cusIcon'></i>
                                &nbsp;
                                {`Walpole`}
                              </small>
                            </div>
                            <div className='col-md-12'>
                              <i className='fa fa-map-signs cusIcon'></i>
                              &nbsp;
                              <small>{`999 North St Walpole MA 02081`}</small>
                            </div>
                            <div className='col-md-12'>
                              <i className='fa fa-phone cusIcon'></i>&nbsp;
                              <small><u>{`(726) 674-6726`}</u></small>
                               &nbsp;
                               <i className='fa fa-envelope cusIcon'></i>&nbsp;
                               <small>{`dtavendor200@yopmail.com`}</small>
                            </div>
                            <div className='col-md-5 col-sm-12'>
                              <small className='btnLinkRed mb-2 mt-2'>{`Closed  today`}</small>
                            </div>

                          </div>
                        </div>
                        <div className='card bg-light' style={{width:'100%',background:'#e8f1ec',borderTop:'none'}}>
                          <div className='card-body'>
                            <div className='col-md-12 mt-3 ml-2'>
                              <small>
                                <i className='fa fa-map text-muted cusIcon'></i>&nbsp;{ " "}
                                <a href='#' target='blank' style={{textDecoration:'underline'}}>Navigate to location</a>
                              </small>
                              <br />
                              <small>
                                <i className='fa fa-globe text-muted cusIcon'></i>&nbsp;{" "}
                                <a href='#' style={{textDecoration:'underline'}}>Go to store website</a>
                              </small>
                              <br />
                              <small className='d-md-none d-lg-none d-sm-block'></small>
                            </div>
                            <div className='col-md-12 ml-2 mt-3'>
                              <p className='p-none smallTitle'>
                              <i className='fa fa-home cusIcon'></i>&nbsp;
                                Vendor Description
                              </p>
                              <div className='mt-1'>
                                <small> We sell fruits & Vegetables</small>
                              </div>
                            </div>
                            <div className='col-md-12 ml-2 mt-3 d-none'>
                              <p className='p-none smallTitle'>
                                <i className='fa fa-info-circle cusIcon'></i>&nbsp;
                                Special Instructions 
                              </p>
                              <div className='mt-1'>
                                <small></small>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <p title='Regular Hour(s) of Operation' className='soh-text  p-none smallTitle'>
                                <i className='fa fa-clock-o cusIcon'></i>&nbsp;
                                Regular Hour(s) of Operation
                              </p>
                              <div className='mt-1'>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Sunday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small style={{color:'red'}}>{`Closed`}</small>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Monday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small style={{color:'red'}}>{`Closed`}</small>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Tuesday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small>{`10:00 am - 12:00 pm`}</small>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Wednesday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small>{`10:00 am - 12:00 pm`}</small>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Thursday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small>{`10:00 am - 12:00 pm`}</small>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Friday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small>{`10:00 am - 12:00 pm`}</small>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-6'>
                                    <small className='mt-2'>{`Saturday`} </small>
                                  </div>
                                  <div className='col-md-6'>
                                    <small>{`10:00 am - 12:00 pm`}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12 mt-3'>
                              <p className='p-none ml-2 smallTitle'>
                                <i className='fa fa-calendar cusIcon'></i>&nbsp;
                                Month(s) of Operation
                              </p>
                              <div className='row mt-2'>
                                <div className='col-md-12 p-none mapResult' style={{padding:'0 20px 0 43px'}}>
                                  <ul className='row ml-2' style={{listStyle:'none',padding:'0',margin:'0 !important'}}>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`January`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`February`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`March`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`April`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`May`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`June`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`July`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`August`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`September`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`October`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`November`}</small>
                                    </li>
                                    <li className='col-md-4 col-6 monthBorder' style={{background:'white'}}>
                                      <small>{`December`}</small>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12 mt-3'>
                              <p className='p-none ml-2 smallTitle'>
                                <i className='fa fa-check-square-o cusIcon'></i>&nbsp;
                                {`Order Option(s)`}
                              </p>
                              <div className='row mt-2'>
                                <div className='col-md-12 p-none mapResult' style={{padding:'0 20px 0 43px'}}>
                                  <ul className='row ml-2' style={{listStyle:'none',padding:'0',margin:'0 !important'}}>
                                    <li className='col-md-12 col-12 monthBorder' style={{background:'white'}}>
                                      <small>{`Phone pre-selection`}</small>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          <div className='col-md-12 ml-2 mt-3'>
                            <p className='p-none smallTitle'>
                              <img className='harvest-results' src={harvest} aria-label='harvest' alt='img'/>
                              &nbsp;
                              <span>Products</span>
                            </p>
                            <small>{` Fresh fruit`}</small>
                          </div>
                          <div className='col-md-12  mb-2 mt-3 ml-2'>
                            <p className='p-none  smallTitle'>
                              <i className='fa fa-image cusIcon'></i>
                              {`Vendor  photos`}
                            </p>
                            <div className='mt-1 text-center'>
                              <div className='mt-1'>
                                <img alt='img' src={fruit} className='img-thumbnail' aria-label='harvestGreen' style={{marginBottom:'5px', marginTop:'5px',width:'250px'}}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>)}
        </Grid>
      </Grid>
    // </div>
  )
}

NewMapSearchResultComponent.prototype = {
    // updateBooleanValue: PropTypes.bool.isRequired
}

export default NewMapSearchResultComponent