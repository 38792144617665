import React from "react";
import PropTypes from "prop-types";

import {
    FormControl,
    Select, 
    Checkbox,
    ListItemText,
    MenuItem,
    ListItemIcon
} from "@mui/material";
import { MenuProps, useStyles } from "../utils/multi-utils";

function MultiSelect(props) { //{ options, value, onChange, label, language }
    const classes = useStyles(props);
    const { options, value, onChange, label, language, ...other } = props;
    const isAllSelected = options.length > 0 && value.length === options.length;

    const handleTypeChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue.includes("all")) {
            onChange(options.length === value.length ? [] : options);
        } else {
            onChange(selectedValue);
        }
    };

    return (
        <FormControl sx={{ width: "100%" }} className={classes.formControl}>
            <Select
                className="multiselect-input"
                sx={{ boxShadow: 'none', background: "#FFFFFF", '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                labelId={label}
                multiple
                value={value}
                onChange={(e) => { 
                    handleTypeChange(e);
                }}
                renderValue={(selected) => {
                    const selectedValue = selected.length;
                    switch (label) {
                        case "Types":
                            return selectedValue === 5 ? language?.["All selected"] : getSelectedValues(selected);
                        case "Order Of Operations":
                            return selectedValue === 6 ? language?.["All selected"] : getSelectedValues(selected);
                        case "Months of Operation":
                            return selectedValue === 12 ? language?.["All selected"] : getSelectedValues(selected);
                        case "Days of Operation":
                            return selectedValue === 7 ? language?.["All selected"] : getSelectedValues(selected);
                        default:
                            return getSelectedValues(selected);
                    }
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': label }}
            >

                <MenuItem id="custom-menu"
                    value="all"
                    tabIndex={0}
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                value.length > 0 && value.length < options.length
                            }
                            role="checkbox"
                            aria-checked={isAllSelected ? "true" : "false"}
                            inputProps={{
                                "aria-label": isAllSelected ? " All Checkbox Checked press enter to uncheck" : "Checbox unchecked press enter to select all",
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText id="custom-listText"
                        classes={{ primary: classes.selectAllText }}

                        primary={language?.["Select All"]} />
                </MenuItem>

                {options.map((item) => (
                    <MenuItem key={item.id} value={item} id="custom-menu">
                        <Checkbox
                            checked={value.map((el) => el.id).indexOf(item.id) > -1}
                            inputProps={{
                                "aria-label":
                                    value.map((el) => el.id).indexOf(item.id) > -1 ? "Checkbox Checked press enter to uncheck" : "Checkbox unchecked",
                            }}
                            role="checkbox"
                            aria-checked={value.map((el) => el.id).indexOf(item.id) > -1 ? "Checkbox Checked press enter to uncheck" : "Checkbox unchecked"}
                        />
                        <ListItemText id="custom-listText" primary={language?.[item.values]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

function getSelectedValues(selected) {
    return selected.map(item => item.values).join(', ');
}
MultiSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    language: PropTypes.object
}

export default MultiSelect;
