import React, { useEffect } from "react";
import Bowser from "bowser";
import axios from 'axios';
import "font-awesome/css/font-awesome.min.css";
import "animate.css/animate.css";
import "select2/dist/css/select2.min.css";
import "datatables/media/css/jquery.dataTables.css";
import "./styles/App.css";
import RouterComponent from "./routes";
import ReactGA from 'react-ga';

ReactGA.initialize({ trackingId: process.env.GOOGLE_ANALYTICS_KEY});

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  
  let systemInfo = {
    ip: "1",
    deviceId: ""
  }
  sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
  const  browser = Bowser.getParser(navigator.userAgent);
  useEffect(()=>{
    if (browser) {
      systemInfo.deviceId = browser.getPlatform().type + " - " + browser.getBrowser().name + " " + browser.getBrowser().version + " - " + browser.getOS().name + " " + browser.getOS().version || '';
    }
    axios.get('https://api.ipify.org')
    .then((response) => {
      systemInfo.ip = response.data;
      sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
    }).catch((error) => {
      console.log(error);
      systemInfo.ip = '1'
      sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
    });
  })

  return (
    <>
      {/* <DashboardComponent /> */}
      <RouterComponent />
    </>
  );
}

export default App;
