import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const SelectDropdown = ({
  value,
  options,
  //   placeholder = "Select",
  onChange,
  ariaLabel,
  isSearch = false,
  defaultValueProps = { default: false },
  disabled = false,
  readOnly = false
  //   keyValue
}) => {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filterList, setFilterList] = useState(options);
  const checkIfClickedOutside = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (value) => {
    onChange(value);
    setOpen(false);
  };
  const handleDropdown = () =>{
    setOpen(!open);
    setSearch("");
    setFilterList(options);
  }
  const handleKeyPress = (event,via,optionList) => {
    const classList = event.target.classList;
    const targetClass = "select2-container--disabled";
    const isPresent = Object.values(classList).includes(targetClass);
    if (!isPresent) {
      if (event.key === 'Enter') {
        if(via === 'list'){
          handleChange(optionList)
        }else{
          handleDropdown()
        }
      }
    } 
  };

  useEffect(() => {
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  return (
    <>
      <span tabIndex={0} aria-label={ariaLabel + 'dropdown'} aria-labelledby={ariaLabel + 'dropdown'}
        className={`select2 select2-container select2-container--default ${open
          ? "select2-container--below select2-container--open"
          : "select2-container--below select2-container--focus"
          }
        ${disabled || readOnly ? "select2-container--disabled" : ""}
        `}
        style={{ width: "100%" }}
        onKeyPress={(event) => handleKeyPress(event)}
        onClick={() => {
          handleDropdown();
        }}

        ref={node}
      >
        <span className="selected-option select2-selection select2-selection--single">
          <span
            className="select2-selection__rendered"
            aria-label="input"
            id="select2-deliveryCity-container"
            role="textbox"
            aria-readonly="true"
            title={
              value === "" && defaultValueProps?.default
                ? defaultValueProps?.defaultOption?.values
                : value?.values
            }
          >
            {value?.name === defaultValueProps?.defaultOption?.name ||
              value === "" ? (
              <span>
                {" "}
                {defaultValueProps.default &&
                  defaultValueProps.cssProps &&
                  defaultValueProps.cssProps.fa && (
                    <i className={defaultValueProps.cssProps.fa}></i>
                  )}
                <span
                  className={
                    defaultValueProps.cssProps &&
                      defaultValueProps.cssProps.spanClass
                      ? defaultValueProps.cssProps.spanClass
                      : ""
                  }
                >
                  {defaultValueProps?.defaultOption?.values}
                </span>
              </span>
            ) : (
              filterList && filterList[0]?.values ==="No Result found" ? "" : value?.values
            )}
          </span>
          <span className="select2-selection__arrow" role="presentation">
            <b role="presentation"></b>
          </span>
        </span>
        {open && (
          <span className="select2-dropdown select2-dropdown--below">
            {isSearch && (
              <span className="select2-search select2-search--dropdown">
                <input
                  type="text"
                  className="select2-search__field"
                  defaultValue={search}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!search) {
                      setFilterList(options);
                    }
                  }}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setFilterList(options);
                    }
                    setSearch(e.target.value);
                    const filter = options.filter(item => item.values.toLowerCase().includes(e.target.value.toLowerCase())); //options.indexOf(e.target.value) > -1;//options.includes(e.target.value)
                    setFilterList(filter);
                  }}
                />
              </span>
            )}
            <span className="select2-results" >
              <ul className="select2-results__options">
                {filterList &&
                  filterList.length > 0 &&
                  filterList
                    .filter((option) =>
                      option?.values
                    )
                    .map((option, i) => (
                      <li tabIndex={0}
                        key={i + option?.name}
                        className={`select2-results__option ${value?.name === option?.name ? "active-option" : ""}`}
                        onKeyPress={(event) => handleKeyPress(event,"list",option)}
                        onClick={() => {
                          handleChange(option);
                        }}
                      >
                        {defaultValueProps.cssProps &&
                          option?.name ===
                          defaultValueProps?.defaultOption?.name && (
                            <i className={defaultValueProps.cssProps.fa}></i>
                          )}
                        <span
                          className={
                            defaultValueProps.cssProps &&
                              defaultValueProps.cssProps.spanClass &&
                              option?.name === defaultValueProps?.defaultOption?.name
                              ? defaultValueProps.cssProps.spanClass
                              : ""
                          }
                        >
                          {option?.values}
                        </span>
                      </li>
                    ))}
              </ul>
            </span>
          </span>
        )}
      </span>
    </>
  );
};

SelectDropdown.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  //   placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isSearch: PropTypes.bool,
  defaultValueProps: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  ariaLabel: PropTypes.string,
  //   keyValue: PropTypes.object.isRequired
};

export default SelectDropdown;
