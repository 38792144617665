import React, { useState, useEffect  } from "react";
import {  NavLink, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/dta-finder.svg";
import LoginComponent from "../../user/login/login";
import AdminLoginComponent from "../../user/login/admin-login";
import ForgotPwdComponent from "../../user/forgotpassword/forgotpassword";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HelpComponent from "../../user/help";

const Header = (props) => {
  const navigate = useNavigate();

  const [modalShown, toggleModal] = useState(false);
  const [modalShownAdmin, toggleModalAdmin] = useState(false);
  const [forgotShown, toggleForgotModal] = useState(false);
  const [helpModalShow, setHelpModalShow] = useState(false);
  const [menuList, SetMenuList] = useState(false);

  const [language, setLanguage] = useState({});
  const [pathname,setPathName] = useState(window.location.pathname);

  const {authenticated, user} = props.userAuth;



  useEffect(() => {
    setLanguage(props.language)
  }, [props.language]);

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [window.location.pathname])

  useEffect(() => {
    const pathList = pathname.split('/')
    SetMenuList(pathList.includes("dtahip"));
    let path = pathList[pathList.length-1]
    if(pathname.slice(-1)=="/"){
      path = pathList[pathList.length-2]
    }
    
    if(user.Role==="VENDOR"){
      if(!(path == "" || path=="dtahip" || path=="mystore" )){
        navigate("/dtahip");
      }
    }else if(Object.keys(user).length==0){
      if(!(path == "" || path=="dtahip" || path=="registration" )){
        navigate("/dtahip");
      }
    }

  }, [pathname]);
 
  const openForgotPwd = () => {
    toggleForgotModal(true);
  };

  return (
    <>
      {forgotShown ?
        (
          <ForgotPwdComponent
            shown={forgotShown}
            close={() => {
              toggleForgotModal(false);
            }}
          />
        )
        : (
          ""
        )}
        {helpModalShow ?
        (
          <HelpComponent
            shown={helpModalShow}
            close={() => {
              setHelpModalShow(false);
            }}
            language={language}
          />
        )
        : (
          ""
        )}
      <div className="header">
      {modalShown && (
            <LoginComponent
              shown={modalShown}
              close={(forgotpwd = "") => {
                toggleModal(false);
                if (forgotpwd) {
                  openForgotPwd();
                }
              }}
              language={language}
            />
          )}
          {modalShownAdmin && (
            <AdminLoginComponent
              shown={modalShownAdmin}
              close={(forgotpwd = "") => {
                toggleModalAdmin(false);
                if (forgotpwd) {
                  openForgotPwd();
                }
              }}
              language={language}
            />
          )}
        
          <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink to="/" reloadDocument="true">
              <img
                src={logo}
                alt="DTA Finder Logo"
                className="header-logo-responsive mr-0"
              />
              
              <span className="boldFont page-title header-title" aria-label="DTA Finder">
              {menuList ? language.HealthyIncentivesProgram+" "+language.hip : language["DTA Finder"]}
              </span>
            </NavLink>
        
          </nav>
        {/* )} */}
      </div>
    </>
  );
};

Header.propTypes = {
  vendorDetails: PropTypes.object,
  language: PropTypes.object,
  mapdata: PropTypes.object,
  selectedLanguage:PropTypes.string,
  userAuth:PropTypes.object,
  authUserDetails:PropTypes.func,
};

export default connect(
  mapStateToProps
)(Header);