import React from "react"; //, {useEffect}
import PropTypes from "prop-types";
const SidebarNavigation = (props) => {
    const staticMenu = props.staticMenu;
    const enableMenuList = Object.entries(props.dynamicMenuEnable).filter(([, v]) => (v === true || v === "true")).map(([k]) => k);

    return (<div className="col-md-3">
        <ul className="list-group" id="vendorRegMenus">
            {staticMenu?.map((menu, i) => (
                <li key={`sidebar_${i}`}
                    onClick={() => {
                        if(props.newmenus.length > 0 && menu.label === "Location Types"){
                            props.onMenuClick(menu.id, menu?.name, enableMenuList);
                        }
                    }}
                    className={` ${menu?.isHide && !enableMenuList.includes(menu?.name) ? "d-none" : ""}`} //${menu.liClassName}
                >
                    <span
                        className={menu.activeTab === menu.id ? `active ${menu.className}` : menu.className}
                        id={menu.id}
                    >
                        {menu.label}
                    </span>
                </li>
            ))}


            {props.newmenus.length > 0 && (
                props.newmenus.map((item, i) => (
                    <li key={`sidebar_${i + 4}`}
                        onClick={() => {
                            props.onMenuClick(`menuStep${i + 4}`, item?.id, enableMenuList);
                        }}
                        className={`${!enableMenuList.includes(item?.id) ? "d-none" : ""}`}
                    >
                        <span
                            className={`menuStep${i + 4}` === props.activeTab ? "active list-group-item menu-list" : "list-group-item menu-list"} //{"list-group-item dynamic-menu menu-list "}
                            id={`menuStep${i + 4}`}
                        >
                            {item.values[0].value}
                        </span>
                    </li>
                ))
            )}

            {/* {props.newmenus.length === 0 && (
                props.dynamicMenu.loctype && props.dynamicMenu.loctype.map((item, i) => (
                    <li key={`sidebar_${i + 4}`}
                        onClick={() => {
                            // console.log(e);
                            // setCurrentMenu(e)
                            props.onMenuClick(`menuStep${i + 4}`, item?.name, enableMenuList);
                        }}
                        className={`${!enableMenuList.includes(item?.name) ? "d-none" : ""}`}
                    >
                        <span
                            className={`menuStep${i + 4}` === props.activeTab ? "active list-group-item menu-list" : "list-group-item menu-list"} //{"list-group-item dynamic-menu menu-list "}
                            id={`menuStep${i + 4}`}
                        >
                            {item.values}
                        </span>
                    </li>
                ))
            )} */}

            {/* {
                    props.dynamicMenu.loctype.map((menu,i) => (
                        <li key={`sidebar_${i+3}`}
                        onClick={() => {
                            props.onMenuClick(menu.id);
                          }}
                        className={`${menu?.isHide && !enableMenuList.includes(menu?.name) ? "d-none" : "" }`}
                        >
                        <span
                            className={ menu.activeTab === menu.id ?  `active ${menu.className}`: menu.className  }
                            id={menu.id}
                        >
                            {menu.label}
                        </span>
                        </li>               
                    ))
                } */}
        </ul>
    </div>);
};

SidebarNavigation.propTypes = {
    activeTab: PropTypes.string,
    onMenuClick: PropTypes.func.isRequired,
    staticMenu: PropTypes.array.isRequired,
    dynamicMenu: PropTypes.object,
    dynamicMenuEnable: PropTypes.object.isRequired,
    newmenus: PropTypes.array
};

export default SidebarNavigation;