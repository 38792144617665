const username = sessionStorage.getItem("username");
const userData = sessionStorage.getItem("userData");
const initialState = {
  authenticated: username ? username:"",
  user: userData ? JSON.parse(userData) : {},
  login: {
    status:"",
    message:"",
  },
  tokenRevoke :{
    status: ""
  },
};
  
  const authReducer = (state=initialState, action) => {
      switch(action.type) {
        case "SET_AUTHENTICATE_USER":{
          return {...state, authenticated:action.payload};
        }
        case "SET_HIP_USER_DETAILS" :{
          return {...state, user: action.payload};
        }
        case "SET_AUTHENTICATE_USER_ERROR":{
          return {...state, login:action.payload};
        }
        case "SET_TOKEN_REVOKE": {
          return {...state, tokenRevoke: {...action.payload}};
        }
        default:
          return state;
      }
    };
  
    export default authReducer;