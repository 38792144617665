export const area = [
    {name:"1",values:"1 mile",isActive:1},
    {name:"2",values:"2 miles",isActive:1},
    {name:"5",values:"5 miles",isActive:1},
    {name:"10",values:"10 miles",isActive:1},
    {name:"15",values:"15 miles",isActive:1},
    {name:"20",values:"20 miles",isActive:1},
    {name:"25",values:"25 miles",isActive:1},
    {name:"30",values:"30 miles",isActive:1}
]

export const vendorType = [
    {
      id: 'csaPickupSite',
      name: 'csaPickupSite',
      values: 'HIP CSA Program',
      dec: "Does your farm or nonprofit buying co-op offer a HIP CSA?",
      isActive: 1,
      addId: "csaPickupSite",
      storeTypeCode:"HPCSAP",
      info:"csaSignupInfo"
    },
    {
      id: 'farmStand',
      name: 'farmStand',
      values: 'HIP Farm Stand',
      dec: "Do you accept HIP at a Farm Stand?",
      isActive: 1,
      addId: "farmStand",
      storeTypeCode:"HPFRSD",
      info:"farmStandInfo"
    },
    {
      id: `farmersMarketBooth`,
      name: `farmersMarketBooth`,
      values: `HIP Farmer's Market Booth`,
      dec: "Do you accept HIP at a Farmers' Market Booth?",
      isActive: 1,
      addId: "farmersMarket",
      storeTypeCode:"HPFRMB",
      info:"farmersMarketInfo"
    },
    {
      id: 'mobileMarket',
      name: 'mobileMarket',
      values: 'HIP Mobile Market Stop',
      dec: "Do you process HIP via a Mobile Market with multiple stops?",
      isActive: 1,
      addId: "mobileMarket",
      storeTypeCode:"HPMMSP",
      info:"mobileMarketInfo"
    },
    {
      id: 'farmersMarket',
      name: 'farmersMarket',
      values: `HIP Farmer's Market`,
      dec: "Are you a market manager who processes HIP at a Farmers’ Market on behalf of your vendors?",
      isActive: 1,
      addId: "farmersMarketManagers",
      storeTypeCode:"HPFRMT",
      info:"farmersMarketInfo"
    },
];

export const orderOptions = [  
    {
      id: 'curbsidePickup',
      name: 'curbsidePickup',
      values: 'Curbside pickup',
      isActive: 1,
    },
    {
      id: 'delivery',
      name: 'delivery',
      values: 'Delivery',
      isActive: 1,
    },
    {
      id: `inPerson`,
      name: `inPerson`,
      values: `In-Person`,
      isActive: 1,
    },
    {
      id: 'onlinePreselection',
      name: 'onlinePreselection',
      values: 'Online Pre-selection',
      isActive: 1,
    },
    {
      id: 'phonePreselection',
      name: 'phonePreselection',
      values: 'Phone Pre-selection',
      isActive: 1,
    },
    {
      id: 'otherType',
      name: 'otherType',
      values: 'Other',
      isActive: 1,
    },
];

export const monthsOfOperation = [  
    {
      id: 'janOperation',
      name: 'janOperation',
      values: 'January',
      isActive: 1,
      month:"1"
    },
    {
      id: 'febOperation',
      name: 'febOperation',
      values: 'February',
      isActive: 1,
      month:"2"
    },
    {
      id: `marOperation`,
      name: `marOperation`,
      values: `March`,
      isActive: 1,
      month:"3"
    },
    {
      id: 'aprOperation',
      name: 'aprOperation',
      values: 'April',
      isActive: 1,
      month:"4"
    },
    {
      id: 'mayOperation',
      name: 'mayOperation',
      values: 'May',
      isActive: 1,
      month:"5"
    },
    {
      id: 'junOperation',
      name: 'junOperation',
      values: 'June',
      isActive: 1,
      month:"6"
    },
    {
      id: 'julOperation',
      name: 'julOperation',
      values: 'July',
      isActive: 1,
      month:"7"
    },
    {
      id: 'augOperation',
      name: 'augOperation',
      values: 'August',
      isActive: 1,
      month:"8"
    },
    {
      id: 'sepOperation',
      name: 'sepOperation',
      values: 'September',
      isActive: 1,
      month:"9"
    },
    {
      id: 'octOperation',
      name: 'octOperation',
      values: 'October',
      isActive: 1,
      month:"10"
    },
    {
      id: 'novOperation',
      name: 'novOperation',
      values: 'November',
      isActive: 1,
      month:"11"
    },
    {
      id: 'decOperation',
      name: 'decOperation',
      values: 'December',
      isActive: 1,
      month:"12"
    },

];

export const daysOfOperation = [  
    {
      id: 'monOperation',
      name: 'monOperation',
      values: 'Monday',
      isActive: 1,
      weekDay:"02"
    },
    {
      id: 'tueOperation',
      name: 'tueOperation',
      values: 'Tuesday',
      isActive: 1,
      weekDay:"03"
    },
    {
      id: `wedOperation`,
      name: `wedOperation`,
      values: `Wednesday`,
      isActive: 1,
      weekDay:"04"
    },
    {
      id: 'thuOperation',
      name: 'thuOperation',
      values: 'Thursday',
      isActive: 1,
      weekDay:"05"
    },
    {
      id: 'friOperation',
      name: 'friOperation',
      values: 'Friday',
      isActive: 1,
      weekDay:"06"
    },
    {
      id: 'satOperation',
      name: 'satOperation',
      values: 'Saturday',
      isActive: 1,
      weekDay:"07"
    },
    {
      id: 'sunOperation',
      name: 'sunOperation',
      values: 'Sunday',
      isActive: 1,
      weekDay:"01"
    },
];