import React, {
    useState,
    useEffect
  } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
// import Modal from "react-bootstrap/Modal";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { authenticateAdminUser, clearLoginError } from "../../../redux/actionTypes/auth";
import "../../../styles/header.scss";
import ModalComponent from "../../common/components/modal";


const AdminLoginComponent = (props) => {
  const [modalShown] = useState(props.shown);
  const [toogleInput, setToogleInput] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [language, setLanguage] = useState({});

  const { register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm();

  useEffect(() => {
    setLanguage(props.language)
  }, [props.language])
  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (event) => { 
    if(props.userAuth.login.status || errors.loginError){
      clearErrors(["loginError"]);
      props.clearLoginError();
    }
  };

  useEffect(() => {
    if(props.userAuth.login.status){
      props.clearLoginError();
    }
  }, []);
  
  useEffect(() => {
    if(isSubmitting && props.userAuth.login.status=="error"){
      setError('loginError', { type: "custom", message: props.userAuth.login.message });
      setSubmitting(false);
    }else if(props.userAuth.login.status=="success"){
      props.close();
    }
  }, [props.userAuth.login]);
    
  const onSubmit = (data) => {
    if(Object.keys(errors).length === 0){
      clearErrors(["loginError"]);
      setSubmitting(true);
      const email = data.email.toLowerCase();
      const encodedPassword = encodeURIComponent(data.password);
      props.authenticateAdminUser(email, encodedPassword);
    }
  };
  const handleKeyPress = (event,via) => {
    if (event.key === 'Enter') {
      if(via === "password"){
        setToogleInput(!toogleInput);
      }
    }
  };

  const renderModal = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-0">
          <div className="row">
            <div
              className="col-md-12 errorMsg mb-2"
              style={{ fontSize: "19px" }}
            >
              {Object.keys(errors).length > 0 && 
                <span className="text-danger"><i className="fa fa-exclamation-circle"></i> &nbsp;{errors[Object.keys(errors)[0]].message}</span>
              }
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="inputLabel">
                  {language.Email} <small>*</small>
                </label>
                <input
                  {...register("email", { required:{value:true, message: props.language?.errorEmail}})}
                  className="form-control form-control-lg lowercase"
                  maxLength="100"
                  placeholder=""
                  type="text"
                  id="username"
                  tabIndex="0"
                  required={true}
                  onClick={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-12 position-relative">
              <div className="form-group">
                <label className="inputLabel" style={{ width: "100%" }}>
                  {language.Password} <small>*</small>
                </label>
                <input
                  {...register("password", { required: {value:true, message: props.language?.errorPassword} })}
                  type={toogleInput ? "password" : "text"}
                  className="form-control form-control-lg show "
                  tabIndex="0"
                  id="password"
                  maxLength="100"
                  required={true}
                  onClick={handleInputChange}
                  autoComplete="off"
                />
                <span
                  tabIndex="0"
                  onKeyPress={(event) => handleKeyPress(event, 'password')}
                  className="pass-toggle btnShow "
                  onClick={() => {
                    setToogleInput(!toogleInput);
                  }}
                >
                  {toogleInput ? language.Show : language.hide}
                </span>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
                tabIndex="0"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    {" "}
                    <i className="fa fa-spinner fa-spin"></i>{" "}
                    Authenticating
                  </>
                ) : (
                  <>
                    {language.Continue} &nbsp;
                    <i
                      style={{ verticalAlign: "inherit" }}
                      className="fa fa-arrow-right"
                    ></i>{" "}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
  
    return (
      <ModalComponent 
        shown = {modalShown}
        close = {() => props.close()}
        title = {language.AdminLogin}
        modalBody = {renderModal}
        size={"md"}
        centered={true}
        label={language.AdminLogin}
        id={"loginModal"}

      />
    );
  };
  
  AdminLoginComponent.propTypes = {
    shown: PropTypes.bool,
    close: PropTypes.func,
    authenticateAdminUser: PropTypes.func.isRequired,
    clearLoginError: PropTypes.func.isRequired,
    language: PropTypes.object,
    userAuth: PropTypes.object,
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      clearLoginError: () => dispatch(clearLoginError({status:"",message:""})),
      authenticateAdminUser: (emailId, password) => dispatch(authenticateAdminUser({ username: emailId, password: password })),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminLoginComponent);
  