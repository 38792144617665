import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import {Row, Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button'; 
import '../../styles/home.scss';
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import SelectDropdown from "../../components/SelectDropdown";
// import SelectDropdown from "../common/selectdropdown-component";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
const UserListAddEditComponent = (props) => {
    const {validated,submitUserData,onEdit,row} = props;
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
      );
    let initial = onEdit? {
        firstName: row.firstName,
        lastName: row.lastName,
        adUserId: row.adUserId,
        userRole: row.userRole,
        primaryEmailAddr: row.primaryEmailAddr,
        id: row.id,
    } : {
        firstName: "",
        lastName: "",
        adUserId: "",
        userRole: "ADMIN",
        primaryEmailAddr: "",
    };
    // eslint-disable-next-line no-unused-vars
    const [formValue, setFormValue] = useState(initial);
        const [roleValue, setRoleValue] = useState({ id: "", label: "System Administrator"});
    // const [userRole , setUserRole] = useState([ 
    // { id: "ADMIN", label: "System Administrator"},
    // { id: "HIPCRD", label: "DTA HIP Co-ordinator"}
    // ]);
    const {
        register,setValue,clearErrors,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: formValue
    });

    useEffect(() => {
        const roleLabels = {
            HIPCRD: { id: "HIPCRD", label: "DTA HIP Co-ordinator" },
            ADMIN: { id: "ADMIN", label: "System Administrator" }
        };
    
        if (row.userRole && roleLabels[row.userRole]) {
            setRoleValue(roleLabels[row.userRole]);
        }
    }, [row]);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    const onSubmit = data => {
        if(Object.keys(errors).length === 0){
            let payload = {
                "firstName": data.firstName.trim(),
                "lastName": data.lastName.trim(),
                "adUserId": data.adUserId.trim(),
                "userRole": data.userRole,
                // "userRole" : data.userRole,
                "primaryEmailAddr":data.primaryEmailAddr.toLowerCase().trim()
            }
            if(onEdit)
                payload["id"] = data.id;
            submitUserData(payload);
        } 
    };


  return (

    <div className="form-block">
        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}> 
            <Row>
                <Col>
                    {Object.keys(errors).length > 0 && <Form.Text className="dh-txt-danger">
                        <b><i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {errors[Object.keys(errors)[0]].message} </b>
                    </Form.Text>}
                    <Form.Group as={Col} controlId="formUserFirstName">
                        <Form.Label className="smallTitle">First Name&nbsp;*</Form.Label>
                        <Form.Control type="text" size="lg" {...register("firstName", { required: "Please enter first Name",
                                                                validate: (value) => {
                                                                    if (value.trim() === "") {
                                                                        return "First name must contain characters";
                                                                    }
                                                                    return true;
                                                                }})}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formUserLastName">
                        <Form.Label className="smallTitle">Last Name&nbsp;*</Form.Label>
                        <Form.Control type="text" size="lg" {...register("lastName", { required: "Please enter last Name",
                                                                validate: (value) => {
                                                                if (value.trim() === "") {
                                                                    return "Last name must contain characters";
                                                                }
                                                                return true;
                                                            }
                                                            })}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formActiveDirectoryName">
                        <Form.Label className="smallTitle">PC / Active Directory Name&nbsp;*</Form.Label>
                        <Form.Control type="text" size="lg" {...register("adUserId", { required: "Please enter Active Directory Name",
                                                                        validate: (value) => {
                                                                            if (value.trim() === "") {
                                                                                return "Active Directory name must contain characters";
                                                                            }
                                                                            return true;
                                                                        }})} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formPrimaryEmailAddr">
                        <Form.Label className="smallTitle">Email&nbsp;*</Form.Label>
                        <Form.Control type="text" size="lg" className="lowercase" {...register("primaryEmailAddr", { required: "Please enter Email",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email format"
                        }})} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formRole">
                        <Form.Label className="smallTitle">Role&nbsp;*</Form.Label>
                        
                        {/* {windowDimensions.width < 768 ? ( */}
                            <SelectDropdown 
                            {...register("userRole", { required: "Please select user role" })}
                            label={"Role"}
                                defaultValue={roleValue}
                                dataList={[
                                    { id: "ADMIN", label: "System Administrator"},
                                    { id: "HIPCRD", label: "DTA HIP Co-ordinator"}
                                  ]}
                                onChange={(e) => {
                                    // setUserRole(e);
                                    setRoleValue(e);
                                    setValue(`userRole`,e.id);

                                }}
                                
                            />
                            
                            {/* ) : ( */}
                            {/* <>
                                <Form.Select size="2" {...register("userRole", { required: "Please select user role" })}>
                                <option value="ADMIN">System Administrator</option>
                                <option value="HIPCRD">DTA HIP Co-ordinator</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                Please select Role.
                                </Form.Control.Feedback>
                            </> */}
                        {/* )}  */}
               
                        
                    </Form.Group>
                </Col>
            </Row>
            {onEdit && <Form.Control type="text" value={row.id} hidden />}
            <Row className="modal-footer">
                <Col>
                    <Button variant="primary" type="submit" className="float-right">
                        {onEdit ? 'Save':'Add'}
                    </Button>
                </Col>
            </Row>
        </Form>
    </div>
  );
};

UserListAddEditComponent.propTypes = {
    submitUserData: PropTypes.func,
    onEdit: PropTypes.bool,
    row:PropTypes.object,
    windowDimensions: PropTypes.object,
    validated: PropTypes.bool,
};


export default connect(mapStateToProps)(UserListAddEditComponent);
