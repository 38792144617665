// var config = require('config');

//Service API PROD ENV
// const BaseUrl = config.serviceURL;
// const homeUrl = config.appHomeUrl;
// const TigerURL = config.tigerURL;
const idleTimeout = 20; //config.idleTimeout; // 20 minutes
// const applyTimeOut = config.applyTimeOut; // 2 minutes

//Axios Http Options
// const HttpOptions = {
// 'headers': { 'Content-Type': 'application/json' },
// 'mode': 'no-cors',
// 'Access-Control-Allow-Origin': '*',
// };

// //rights and responsibility pdf URLs
// const rights_en_url = config.rights_en_url;
// const rights_es_url = config.rights_es_url;
// const rights_pt_url = config.rights_pt_url;
// const rights_zh_url = config.rights_zh_url;
// const rights_vi_url = config.rights_vi_url;

// const source = config.source;

// //google analytics Configurations
// const TRACKING_ID  = config.TRACKING_ID; // DEV ENV Only
// //this.trackId ='UA-112764375-3'; // PROD CONFIG
// const CLIENT_ID  = config.CLIENT_ID;


// // site key for Dev
// const SITE_KEY = config.SITE_KEY;
// const SECRETE_KEY = config.SECRETE_KEY;

// // site key for QA
// // const SITE_KEY = '6LeyJU8UAAAAAMTGxyi0QeAachTHvMUaV_2w4Cjb';
// // const SECRETE_KEY = '6LeyJU8UAAAAAK4Mw90gDrEmFXdbSuF9NrYRtySN'

// // site key for PROD
// // const SITE_KEY = '6LeSU0sUAAAAAOU43Fe_31w-W7hap0q8tUGCqaAM';
// // const SECRETE_KEY = '6LeSU0sUAAAAAMpQM6QswVED6UIZ91euwFRUMvfZ'

// //flag to enable and disable invisible recaptcha
// const RECAPTCHA_FLAG = config.RECAPTCHA_FLAG
//export const RECAPTCHA_FLAG = true

// Config object to be passed to Msal on creation
const appHomeUrl = "http://localhost:8000/";

const msalConfig = {
    auth: {
        clientId: "b5c93736-461d-4bdb-81c9-7cbdac5be947",
        authority: "https://admin.login.dev.tss.mass.gov/b5c93736-461d-4bdb-81c9-7cbdac5be947/B2C_1A_PARTNER_SISU_FEDERATION/v2.0/.well-known/openid-configuration",
        knownAuthorities: ["admin.login.dev.tss.mass.gov"],
        redirectUri: appHomeUrl,
        postLogoutRedirectUri: appHomeUrl,
        response_type: "id_token",
        prompt: "login",
        // idps:"aad",
        // capp: "dta"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
 const loginRequest = {
    scopes: ["openid"],
    // extraQueryParameters: { idps: 'aad', capp: 'dta'},
    extraQueryParameters: {},
    authority:"https://admin.login.dev.tss.mass.gov/b5c93736-461d-4bdb-81c9-7cbdac5be947/B2C_1A_PARTNER_SISU_FEDERATION/v2.0/.well-known/openid-configuration"
};
// eslint-disable-next-line no-unused-vars
const forgotPasswordRequest = {
    authority: "https://DevMassGovB2C.b2clogin.com/DevMassGovB2C.onmicrosoft.com/B2C_1A_EMAILPASSWORDRESET",
}

//  const editProfile ={
//     authority: "https://DevMassGovB2C.b2clogin.com/DevMassGovB2C.onmicrosoft.com/B2C_1A_EMAILPROFILEEDIT",
//     // extraQueryParameters: { capp: 'dta'}
//     extraQueryParameters: { }
// }
// eslint-disable-next-line no-unused-vars
 const signUp = {
    authority: "https://DevMassGovB2C.b2clogin.com/DevMassGovB2C.onmicrosoft.com/B2C_1A_EMAIL_SIGNUP_SIGNIN",
    // extraQueryParameters: { idps: 'signup', capp: 'dta' , domain_hint: 'signup'}
    extraQueryParameters: {  domain_hint: 'signup'}

}

const postLogoutRedirectUri = {
redirectUri: appHomeUrl
}


//export default { BaseUrl, HttpOptions };
module.exports = {
// BaseUrl: BaseUrl,
// homeUrl: homeUrl,
// HttpOptions: HttpOptions,
// TigerURL: TigerURL,
idleTimeout: idleTimeout,
// applyTimeOut: applyTimeOut,
// SITE_KEY: SITE_KEY,
// SECRETE_KEY: SECRETE_KEY,
// RECAPTCHA_FLAG: RECAPTCHA_FLAG,
// TRACKING_ID:TRACKING_ID ,
// CLIENT_ID: CLIENT_ID,
// rights_en_url: rights_en_url,
// rights_es_url: rights_es_url,
// rights_pt_url: rights_pt_url,
// rights_vi_url: rights_vi_url,
// rights_zh_url: rights_zh_url,
// source,
appHomeUrl:appHomeUrl,
msalConfig: msalConfig,
loginRequest:loginRequest,
postLogoutRedirectUri:postLogoutRedirectUri
};
