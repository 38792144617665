import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom'

import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/reducers";
import PhoneCall from '@mui/icons-material/PhoneRounded'
import TwitterIcon from '@mui/icons-material/Twitter';
import masslogo from "../../../assets/images/mass-logo.png";
import dta_1 from "../../../assets/images/dta_1.png";
import dta_2 from "../../../assets/images/dta_2.png";
import SNAP_PathToWork from "../../../assets/images/SNAP_ PathToWork.png";
import HIP_Logo from "../../../assets/images/HIP-Logo.png";
import UMASS_Logo_DTA_SNAPEd from "../../../assets/images/UMASS_Logo_DTA_SNAPEd.png";
import '../../../styles/footer.scss'
import config from "../../../config";
import HelpComponent from "../../../pages/user/help";
// import FeedbackComponent from "../../user/FeedbackComponent";
import ModalComponent from "../components/modal";
import PropTypes from 'prop-types';
import { Button, Collapse, FormControl, FormControlLabel, Grid, Link as MaterialLink, Radio, RadioGroup, TextareaAutosize, Typography } from '@mui/material';
import '../../../styles/feedback.scss';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { sendFeedback } from "../../../redux/actionTypes/administration";
import { lang } from "moment";

const Footer = (props) => {
  const [language, setLanguage] = useState({});
  const [helpModalShow, setHelpModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [feedback, setFeedback] = useState('');
  const [showThank, setShowThank] = useState(false);

  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(null);
  const [text, setText] = useState('');
  const [checked, setChecked] = useState(false);

  const maxCharacters = 500;

  const handleChange = () => {
      setChecked((prev) => !prev)
    }
  const handleTextChange = (event) => {
    setError(null)
       const inputValue = event.target.value;
      if (inputValue.length <= maxCharacters) {
          setText(inputValue);
      }
      setFeedback(inputValue);
  };


  const onSubmit = () => {
    if (!feedback) {
        setError(language["Please let us know how we can improve this page."]);
        return
      } else {
        setError(''); 
        const isHelpfulValue = selectedOption === 'Yes' ? 'Y' : 'N';
        const payload = {
          feedbackMessage: feedback,
          isHelpful : isHelpfulValue
        };
        props.sendFeedback(payload);
      }
  };

  useEffect(() => {
      const { sendFeedback } = props.administration;
      if(sendFeedback?.data?.code === '200') {
        setShowThank(true);
      }
  }, [props.administration?.sendFeedback]);

  const handleOptionChange = (event) => {
    setError(null);
    setText('');
    setSelectedOption(event.target.value);
  };



  useEffect(() => {
    setLanguage(props.language)
  }, [props.language]);

  const toggleHelpModal = () => {
    setHelpModalShow(true);
}

const renderModal = () => {
  // return (<FeedbackComponent />)
  return (<React.Fragment>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction={'row'} style={{ display: 'flex', justifyContent: 'center' }} pb={4}>
        <Grid xs={11} sx={{ border: '1px solid #dcdcdc' }}>
          <Grid item>
            <Typography variant='h5' className='feedback-title'>{language["Help Us Improve Mass.gov"]}</Typography>
          </Grid>
          {showThank ? (
            <Grid item className='feedback-content'>
              <Typography className='font-regular14'>{language["Thank you for your website feedback! We will use this information to improve this page."]} </Typography>
              {/* <Typography className='font-regular14'>If you would like to continue helping us improve Mass.gov,&nbsp;  
                  <Link className='feedback-link' onClick={() => { window.open(config.navUrls.userPanel) }}><b>join our user panel</b></Link> to test new features for the site.
              </Typography> */}
            </Grid>
          ) : (
            <Grid item className='feedback-content'>
              <FormControl component={'fieldset'}>
                <Typography className='font-regular14'>{language["Did you find what you were looking for on this webpage?"]}</Typography>
                <RadioGroup
                  row
                  aria-label="options"
                  className='font-regular14'
                  name="options"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <FormControlLabel  value="Yes" control={<Radio />} label={language["Yes"]}  classes={{ label: 'font-regular14' }} />
                  <FormControlLabel  value="No" control={<Radio />} label={language["No"]} classes={{ label: 'font-regular14' }} />
                </RadioGroup>
              </FormControl>
              {selectedOption === 'Yes' && (
                <Grid item>
                  <Typography className='font-regular14'>{language["Do you have anything else to tell us"]}?&nbsp;*</Typography>
                  <Typography className='font-regular14'>{language["We will use this information to improve the site."]}</Typography>
                  
                  <Grid className='text-area' sx={{ marginTop: '.5em' }}>
                    <TextareaAutosize className={`textArea-content font-regular14 ${error ? 'danger-border' : ''}`} {...register("feedbackMessage", { required: true})}
                      value={text}
                      onChange={handleTextChange}
                      maxLength={'500'}
                      minRows={3}
                      style={{ width: '100%', overflow: 'hidden' }}
                    />
                    <Typography className='maxCharacter font-light13'>{maxCharacters - text.length}/{maxCharacters}</Typography>
                  </Grid>
                  <Typography className='font-regular13'>{language["Do not include sensitive information, such as Social Security or bank account numbers."]} </Typography>
                  {error && (
                          <div style={{borderLeft:"2px solid rgb(205, 13, 13)",paddingLeft:'13px'}}>
                            <span className="dh-txt-danger">{error}</span>
                          </div>
                      )}
                  <Grid className='btn-wrapper' mt={2}>
                    <Button className='submit-btn font-regular14' variant='contained' onClick={onSubmit}>{language["SEND FEEDBACK"]}</Button>
                  </Grid>
                </Grid>
              )} 
              {selectedOption === 'No' && (
                <Grid item>
                  <Typography className='font-regular14'> {language["Please tell us what you were looking for"]}.*</Typography>
                  <Typography className='font-regular14'>{language["We will use this information to improve the site."]}</Typography>
                
                  <Grid className='text-area' sx={{ marginTop: '.5em' }}>
                  <TextareaAutosize className={`textArea-content font-regular14 ${error ? 'danger-border' : ''}`} {...register("feedbackMessage", { required: true})}
                    value={text}
                    onChange={handleTextChange}
                    maxLength={'500'}
                    minRows={3}
                    style={{ width: '100%',overflow: 'hidden' }}
                  />
                  <Typography className='maxCharacter font-light13'>{maxCharacters - text.length}/{maxCharacters}</Typography>
                  </Grid> 
                  <Typography className='font-regular13'>{language["Do not include sensitive information, such as Social Security or bank account numbers."]}</Typography>

                  <Grid style={{ marginBottom: '.5em',position:'relative' }}>
                    <Grid item xs={12} display="flex">
                      <Typography variant='body1' className='font-semibold14'>{language["Your feedback will not receive a response."]}
                        <span className='accordin-btn' onClick={handleChange}>{language["Why?"]}&nbsp;
                        <i className={`fa ${checked ? "fa-chevron-up" : "fa-chevron-down" }`} style={{fontSize:"12px"}} aria-hidden="true"></i>
                        </span>
                      </Typography>
                    </Grid>
                    <Collapse in={checked} style={{ background: "#E8EEF4", marginTop: '.5em',position:'relative' }}>
                        <Grid item xs={12} style={{ padding: '.5em', overflowY: 'auto' }}>
                          <Typography className='feedback-help-text font-regular14'>
                           {language["The feedback will only be used for improving the website. If you need assistance, please"]}&nbsp;
                            <Link className='feedback-link' onClick={() => { window.open(config.navUrls.healthAndHumanService) }}><b>{language["contact the Executive Office of Health and Human Services"]}</b></Link>
                          </Typography>
                          <IconButton
                                  style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  }}
                                  onClick={handleChange}
                                  aria-label="close"
                          >
                                  <CloseIcon />
                          </IconButton>
                        </Grid>
                    </Collapse>
                  </Grid>
                      {error && (
                          <div style={{borderLeft:"4px solid rgb(205, 13, 13)",paddingLeft:'13px'}}>
                          <span className="dh-txt-danger font-regular14">{error}</span>
                          </div>
                      )}
                  <Grid className='btn-wrapper' mt={2}>
                    <Button className='submit-btn font-regular14' variant='contained' onClick={onSubmit}>{language["SEND FEEDBACK"]} </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Form>
  </React.Fragment>)
}

  const currentYear = new Date().getFullYear();
  
    return (<>
      {helpModalShow ?
        (
          <HelpComponent
            shown={helpModalShow}
            close={() => {
              setHelpModalShow(false);
            }}
            language={language}
          />
        )
        : (
          ""
        )}
        {showModal && <ModalComponent shown={showModal}
        close={() => {
          setShowModal(false);
          setShowThank(false);
          setSelectedOption("")
          setText("")
        }}
          title={"Feedback"}
          modalBody={renderModal}
          size ={"lg"}
          label={language["Feedback"]}
        />}
      <Grid container className="footer" role="contentinfo" columnSpacing={.5}>
        <Grid item xs={12} lg={6} className="footer-logo">
          <Grid container className="footer-logo-list">
            <Grid>
              <Link onClick={() => { window.open(config.navUrls.massGov) }} aria-label='MassHealth Logo'>
                <img className="footer-lg-img" width={"95px"} src={masslogo} alt="MassHealth logo" />
              </Link>
            </Grid>
            <Grid >
              <Link onClick={() => { window.open(config.navUrls.massGovSnap) }} aria-label='SNAP Logo'>
                <img className="footer-lg-img" width={"120px"}  src={dta_1} alt="SNAP Logo" />
              </Link>
            </Grid>
            <Grid >
              <Link onClick={() => { window.open(config.navUrls.dtaConnect) }} aria-label='DTA Connect Logo'>
                <img className="footer-lg-img" width={"100px"} src={dta_2} alt="DTA Connect Logo" />
              </Link>
            </Grid>
            <Grid >
              <Link onClick={() => { window.open(config.navUrls.snapNetwork) }} aria-label='DTA SNAP Path To Work Logo'>
                <img className="footer-lg-img" width={"100px"} src={SNAP_PathToWork} alt="DTA SNAP Path To Work Logo" />
              </Link>
            </Grid>
            <Grid>
              <Link onClick={() => { window.open(config.navUrls.dtaHip) }} aria-label='HIP Logo'>
                <img className="footer-lg-img" width={"100px"} src={HIP_Logo} alt="HIP Logo" />
              </Link>
            </Grid>
            <Grid>
              <Link onClick={() => { window.open(config.navUrls.mahealthyfoodsinasnap) }} aria-label='SNAPED logo'>
                <img className="footer-lg-img" width={"140px"} src={UMASS_Logo_DTA_SNAPEd} alt="SNAPED logo" />
              </Link>
            </Grid> 
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ m: 1, p: 0 }} textAlign={{ xs: 'left', md: 'left', lg: 'left' }} >
            <p className="footer-mission-text">
              {language.TheMission}
            </p>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ m: 1, p: 0, pb: 2 }} textAlign={{ xs: 'left', md: 'left', lg: 'left' }} >
            <p className="footer-mission-text" style={{marginTop: 0}}>&#169; {currentYear} {language["Commonwealth of Massachusetts"]}</p>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12} className="font-18 footer-list" aria-label='navigation'>
            <div>
              <MaterialLink href="tel:(877)382-2363" aria-label="phonenumber">
                <PhoneCall fontSize="medium" className="footerIcons" />(877)382-2363
              </MaterialLink>
            </div>
            <div>
              <MaterialLink component={Link} onClick={() => { window.open(config.navUrls.contactUs) }} aria-label='contactUs'>
              {language["Contact Us"]}
              </MaterialLink>
            </div>
            <div>
            <MaterialLink component={Link} onClick={() => { window.open(config.navUrls.dtaListensUrl) }} aria-label="contact">
              <TwitterIcon fontSize="medium" className="footerIcons" />@DTA Listens
            </MaterialLink>
            </div>
            <div>
              <MaterialLink component={Link} onClick={() => { window.open(config.navUrls.sitePoliciesUrl) }} aria-label='site policies'>
              {language["Site Policies"]}
              </MaterialLink>
            </div>
            <div>
              <MaterialLink component={Link} onClick={() => { window.open(config.navUrls.accessibilityUrl) }} aria-label='accessibility'>
              {language["Accessibility"]}
              </MaterialLink>
            </div>
            <div>
              <MaterialLink component={Link} aria-label='feedback' onClick={() => {setShowModal(true)}}>
              {language["Feedback"]}
              </MaterialLink>
            </div>
            <div>
              <MaterialLink className="border-none" component={Link} onClick={toggleHelpModal} aria-label='help'>
              {language.help}
              </MaterialLink>
            </div>
          </Grid>
        </Grid>
      </Grid>
      </>);
};

Footer.propTypes = {
  sendFeedback: PropTypes.func,
  language: PropTypes.object,
  administration:PropTypes.object
};


function mapDispatchToProps(dispatch) {
  return {
    sendFeedback: (payload) => dispatch(sendFeedback(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);