import React from 'react'
import BoxWrapper from './BoxWrapper'
import { Grid } from '@mui/material'
import '../../styles/home.scss'
import MapComponent from './MapComponent';


const HomeComponent = () => {

  return (
    <>
      <Grid container className='map-area'>
        <BoxWrapper />
        <MapComponent />
      </Grid>
    </>
  );
};


export default HomeComponent;