export const actionTypes = {
    GET_FARMERS_MARKET : "GET_FARMERS_MARKET",
    SET_FARMERS_MARKET : "SET_FARMERS_MARKET",
    EXPORT_FARMERS_MARKET: "EXPORT_FARMERS_MARKET",
    SET_EXPORT_FARMERS_MARKET: "SET_EXPORT_FARMERS_MARKET",
    GET_ACCESS_LOGS : "GET_ACCESS_LOGS",
    SET_ACCESS_LOGS : "SET_ACCESS_LOGS",
    GET_LOGIN_LOGS : "GET_LOGIN_LOGS",
    SET_LOGIN_LOGS : "SET_LOGIN_LOGS",
    GET_USER_LIST : "GET_USER_LIST",
    SET_USER_LIST : "SET_USER_LIST",
    GET_MANAGE_COORDINATES : "GET_MANAGE_COORDINATES",
    SET_MANAGE_COORDINATES : "SET_MANAGE_COORDINATES",
    ADD_FARMERS_MARKET : "ADD_FARMERS_MARKET",
    EDIT_FARMERS_MARKET : "EDIT_FARMERS_MARKET",
    BULK_UPDATE_FARMERS_MARKET : "BULK_UPDATE_FARMERS_MARKET",
    DELETE_FARMERS_MARKET : "DELETE_FARMERS_MARKET",
    REFRESH_KEY: "REFRESH_KEY",
    ADD_USER_LIST:"ADD_USER_LIST",
    EDIT_USER_LIST:"EDIT_USER_LIST",
    DELETE_USER_LIST:"DELETE_USER_LIST",
    EDIT_COORDINATES : "EDIT_COORDINATES",
    SEND_FEEDBACK : "SEND_FEEDBACK",
    SEND_FEEDBACK_SUCCESS : "SEND_FEEDBACK_SUCCESS"
  };
  
  export function sendFeedback(payload) {
    return {
      type: actionTypes.SEND_FEEDBACK,
      payload: payload
    }
  }
  
  export  function getFarmersMarket(payload) {
      return {
        type: actionTypes.GET_FARMERS_MARKET,
        payload: payload
      };
  }

  export function exportFarmersMarket() {
    return {
      type: actionTypes.EXPORT_FARMERS_MARKET
    };
  }

  export  function addFarmersMarket(payload) {
    return {
      type: actionTypes.ADD_FARMERS_MARKET,
      payload: payload
    };
  }

  export  function editFarmersMarket(payload) {
    return {
      type: actionTypes.EDIT_FARMERS_MARKET,
      payload: payload
    };
  }

  export  function bulkUpdateFarmersMarket(payload) {
    return {
      type: actionTypes.BULK_UPDATE_FARMERS_MARKET,
      payload: payload
    };
  }

  export  function deleteFarmersMarket(payload) {
    return {
      type: actionTypes.DELETE_FARMERS_MARKET,
      payload: payload
    };
  }

  export  function refreshKey() {
    return {
      type: actionTypes.REFRESH_KEY,
    };
  }

  export function getAccessLogs(payload){
    return{
      type:actionTypes.GET_ACCESS_LOGS,
      payload: payload
    };
  }

  export function getLoginLogs(payload){
    return{
      type:actionTypes.GET_LOGIN_LOGS,
      payload: payload
    };
  }

  export function getUserList(payload){
    return{
      type:actionTypes.GET_USER_LIST,
      payload: payload
    };
  }

  export  function addUserList(payload) {
    return {
      type: actionTypes.ADD_USER_LIST,
      payload: payload
    };
  }

  export  function editUserList(payload) {
    return {
      type: actionTypes.EDIT_USER_LIST,
      payload: payload
    };
  }

  export  function deleteUserList(payload) {
    return {
      type: actionTypes.DELETE_USER_LIST,
      payload: payload
    };
  }


  export function getCoordinatesList(payload){
    return{
      type:actionTypes.GET_MANAGE_COORDINATES,
      payload: payload
    };
  }

  export  function editCoordinates(payload) {
    return {
      type: actionTypes.EDIT_COORDINATES,
      payload: payload
    };
  }