import React from "react";
import PropTypes from "prop-types";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const CoordinatesDetails = ({ row,handleOnEditAction }) => {
    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell>Store / Vendor Name</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody >
                {row.storeInfo.map((storeInfo) => (
                    <TableRow key={storeInfo.storeId}>
                        <TableCell component="th" scope="row" className="tableFont">
                            {storeInfo.vendorName}
                        </TableCell>
                        <TableCell>
                            <IconButton aria-label="edit" onClick={() => handleOnEditAction(storeInfo)}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}


CoordinatesDetails.propTypes = {
    row: PropTypes.object.isRequired,
    handleOnEditAction: PropTypes.func,
};

export default CoordinatesDetails;