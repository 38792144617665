export const AUTH_API = process.env.REACT_APP_BASE_URL;
export const API_URL = AUTH_API+"/res";
export const PUBLIC_API = AUTH_API+"/public";
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;
const config = {
    navUrls: {
        massGov: "https://www.mass.gov/",
        massGovSnap: "http://mass.gov/snap",
        dtaConnect: "https://dtaconnect.eohhs.mass.gov/",
        snapNetwork: "https://www.snappathtowork.org/",
        dtaHip: "https://www.mass.gov/hip",
        mahealthyfoodsinasnap: "https://masnaped.org/",
        dtaListensUrl: "https://www.twitter.com/DTA_Listens",
        sitePoliciesUrl: "https://www.mass.gov/lists/eohhs-site-policies",
        accessibilityUrl: "http://www.mass.gov/eohhs/utility/eohhs-accessibility-policy.html",
        healthAndHumanService: "https://www.mass.gov/orgs/executive-office-of-health-and-human-services",
        userPanel:"https://www.mass.gov/user-panel?utm_source=survey",
        contactUs:"https://www.mass.gov/guides/how-to-contact-dta"
    }
};

export default config;
