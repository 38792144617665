import React from "react";
import PropTypes from "prop-types";
import {Modal, Box} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import "../../../styles/home.scss"
import { ModalClose, ModalDialog } from "@mui/joy";



const ModalComponent = ({shown,close,title,modalBody,size,centered=false,label, dialogClassName="", id=""}) => {

  const getResizableWidth = (size) => {
    switch (size) {
      case 'sm':
        return 300;
      case 'md':
        return 500;
      case 'lg':
        return 900;
      case 'xl':
        return 1220;
      default:
        return 500;
    }
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: getResizableWidth(size),
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, 0.175) !important',
    boxShadow: 24,
    borderRadius: "0.5rem !important",
    p: 2, 
    maxHeight: '90vh',
    overflow: "auto",
    // paddingBottom:5
   
  };
  return (
      
    <Modal
      open={shown}
      onClose={() => close()}
      aria-labelledby={title?.toUpperCase()}
      aria-describedby={label?.toUpperCase()}
      
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      centered={centered}
      id={ id ? id : "modal-popup"}
      dialogClassName={dialogClassName}   
    >
      <Box sx={{ ...style }}>
        <Typography id={title} className="modal-title modalTitle resText" variant="h6" component="h2" aria-label={label?.toUpperCase()}>
          <>{label?.toUpperCase()}</>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => close()}
          sx={{
            position: 'absolute',
            top: "18px",
            right: "4px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ mt: 2 , fontFamily: "Texta-book !important",fontSize: '1.25rem', }} id={label} className="modal-body">
          {modalBody()}
        </Typography>
      </Box>
    </Modal>
  );
};

ModalComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  modalBody: PropTypes.func, 
  size: PropTypes.string,
  centered: PropTypes.bool,
  dialogClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string
};

export default ModalComponent;
