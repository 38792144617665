import React from "react";
import csa_farms from "../../assets/images/marker/csa-farms.png";
import farm_stands from "../../assets/images/marker/farm-stands.png";
import farmers_market from "../../assets/images/marker/farmers-market-managers.png";
import farmers_market_managers from "../../assets/images/marker/market-managers.png";
import mobile_market from "../../assets/images/marker/mobile-market.png";
import blue_cluster from "../../assets/images/marker/blue-cluster.png";
import yellow_cluster from "../../assets/images/marker/yellow-cluster.png";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { GET_TYPE } from "../../redux/actionTypes";
import "../../styles/dashboard.scss";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



const MapKeyComponent = (props) => {
    
    const getImageTooltip = (name)=>{
        switch (name) {
          case "csaPickupSite":
            return {
                img:csa_farms,
                tooltip: props.language["CSA Program Tooltip"]
            };
          case "farmersMarketManagers":
            return {
                img:farmers_market_managers,
                tooltip:props.language["Farmer Market Tooltip"]
            };
          case "farmStand":
            return  {
                img:farm_stands,
                tooltip:props.language["Farm Stand Tooltip"]
            };
          case "mobileMarket":
            return  {
                img:mobile_market,
                tooltip:props.language["Market Stop Tooltip"]
            };
          case "farmersMarket":
            return  {
                img:farmers_market,
                tooltip:props.language["Market Booth Tooltip"]
            };
          default:
            return {
                img:csa_farms,
                tooltip:props.language["CSA Program Tooltip"]
            };
        }
    };
    const {isOpened, setIsOpened} = props;
   
    return (
    <div className="legend-box d-none d-sm-none d-md-block d-lg-block">
                <div className={`legend-border legend-text ${isOpened ? "expand" : ""}`}>
                    <p className="boldFont text-brand d-inline-block  m-0 py-1"> {props.language["Legend"]} </p>
                    <p onClick={()=>setIsOpened(!isOpened)} className="pull-right arrow-font collapse-icon d-inline-block mb-0 pt-1 px-3"><i className={`fa ${isOpened ? "fa-angle-double-left" : "fa-angle-double-right"}`} aria-hidden="true"></i></p>
                </div>
                {/* {isOpened && (      */}
                    <div className={`legend-container bg-white legend-collapse ${isOpened ? "selected" : "dismiss d-none"}`} id="legendCollapse">
                        <div className="card card-body  border-bottom-0 p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <ul className="p-0 m-0"> */}
                                        {/* <li className=""> */}
                                            {/* <ul className="typesSection p-0"> */}
                                                { 
                                                    props.mapdata.vendorType !== undefined && props.mapdata.vendorType.map((item,i)=>(
                                                        <div tabIndex={0} key={i} onKeyPress={(e)=>{
                                                            if(e.key === "Enter") {
                                                                props.onMapKeyClick(item.name);
                                                            }
                                                        }}  onClick={()=>{
                                                            props.onMapKeyClick(item.name);
                                                        }} className="p-2 border-bottom cursor-pointer tooltip">
                                                        <OverlayTrigger
                                                            key={i}
                                                            placement="right"
                                                            overlay={
                                                                    <Tooltip id={`tooltip-${i}`}>
                                                                       <div>{getImageTooltip(item.addId)["tooltip"]}</div>
                                                                    </Tooltip>
                                                        
                                                            }
                                                            >
                                                            <Button variant="light" className="btn-to-div">
                                                                <img src={getImageTooltip(item.addId)["img"]} width="24" className="mx-2"  alt={`${props.language[item.values]} image`}/> <small className="boldFont">{props.language[item.values]}</small></Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    ))
                                                }
                                                {/* <li className=""> */}
                                                    <div className="pt-2" >
                                                        <div className="d-inline-block text-center pl-2 float-left">
                                                            <img tabIndex={0} src={blue_cluster} width="34" className="mr-1 ml-2" aria-label="Blue Cluster represent the selected region there are 2-9 vendors" alt="img" />
                                                            <p tabIndex={0} className=" text-center font-14">(2-9)</p>
                                                        </div>
                                                        <div className="d-inline-block text-center float-left">
                                                            <img tabIndex={0} src={yellow_cluster} width="34" className="mr-2" aria-label="Yellow Cluster represent that selected region has 10+ vendors" alt="img"/>
                                                            <p tabIndex={0} className=" text-center font-14">(10+)</p>
                                                        </div>

                                                        <div className="d-inline-block text-center float-left  verticalAlign">
                                                            <p tabIndex={0} className=" mt-3 font-16">
                                                            {props.language["Number of HIP vendor locations"]}</p>
                                                        </div>
                                                    </div>
                                                {/* </li> */}
                                            {/* </ul> */}
                                        {/* </li> */}
                                    {/* </ul> */}
                                </div>
                            </div>
                        </div>
                    </div> 
                {/* )} */}
            </div>
    );
};

MapKeyComponent.propTypes = {
    mapdata: PropTypes.object.isRequired,
    onMapKeyClick: PropTypes.func.isRequired,
    getType: PropTypes.func.isRequired,
    type: PropTypes.object,
    language: PropTypes.object,
    isOpened: PropTypes.bool,
    setIsOpened: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {        
        getType: () => {
            dispatch({ type: GET_TYPE });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MapKeyComponent);
