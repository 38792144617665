import axiosInstance from "../interceptor";
import { takeLatest, call, put } from "redux-saga/effects"; //takeEvery
import { actionTypes } from "../actionTypes/adminDashboard";
import { API_URL } from "../../config";

function* getVendorStatsSaga(props) {
    let url =  `${API_URL}/vendor/stats`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);  
        yield put({ type: actionTypes.SET_VENDOR_STATS, payload: result.data.statisticsData });
    } 
    catch {
        console.log("Failed");
    }
}

function* getStoreStatsSage(props) {
    let url =  `${API_URL}/store/stats`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_STORE_STATS, payload: result.data });
    } 
    catch {
        console.log("Failed");
    }
}

export const watchGetVendorStatsSaga = function*() {
    yield takeLatest(actionTypes.GET_VENDOR_STATS, getVendorStatsSaga);
};

export const watchGetStoreStatsSage = function*() {
    yield takeLatest(actionTypes.GET_STORE_STATS, getStoreStatsSage);
};