import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import TableComponent from "../common/components/table";
import PageHeaderComponent from "../common/components/pageHeader";
import Stack from '@mui/material/Stack';
import {CustomButton} from "../common/components/customButton";
import { getUserList, addUserList, editUserList, deleteUserList, refreshKey } from "../../redux/actionTypes/administration";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalComponent from "../common/components/modal";
import UserListAddEditComponent from "./usersAddEdit";
import { popUpAlert, convertUTCDateToLocalDate, checkToken } from "../../utils/globalMethods";
import '../../styles/home.scss';
import SearchComponent from "../common/components/searchComponent";
import { useNavigate } from 'react-router-dom';

function createUserListData(id, firstName, lastName, adUserId, primaryEmailAddr, userRole, lastUpdtTs, createTs, row) {
  return {
    id,
    firstName,
    lastName,
    adUserId,
    primaryEmailAddr,
    userRole: userRole === "ADMIN" ? "System Administrator" : "DTA HIP Co-ordinator",
    lastUpdtTs: convertUTCDateToLocalDate(lastUpdtTs),
    createTs: convertUTCDateToLocalDate(createTs),
    row
  };
}

const UsersComponent = (props) => {
  const navigate = useNavigate();
 
  const [usersListTotal, setUsersListTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [onEdit, setOnEdit] = useState({});
  const [search, setSearch] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [tableSort, setTableSort] = useState({"lastUpdtTs":"DESC"});
  const [validated, setValidated] = useState(false);

  // const handleEditOpen = () => setShowEdit(true);

  const handleSubmit = (payload) => {
    setValidated(true);
    if(Object.keys(onEdit).length !== 0){
      props.editUserList(payload);
    }else{
      props.addUserList(payload);
    }
    setShowModal(false);
    setOnEdit({});
  };

  const handleNext = (page, rowSize) => {
    (size != rowSize) && setSize(rowSize);
    setFrom(rowSize * page);
  }

  const handleSort = (item) => {
    let sortitem={}
    if(item.sort=="" || item.sort=="DESC"){
      sortitem[item.id] = "ASC"
    }else{
      sortitem[item.id] = "DESC"
    }
    setTableSort(sortitem)
  }

  useEffect(() => {
    const userInfo = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
     if (props?.allowedRoles && userInfo && userInfo !== null && Object.keys(userInfo).length != 0) {
      const checkRole = props.allowedRoles.find((role) => userInfo?.Role.includes(role));
      if (!checkRole) {
        navigate("/dtahip");
        return;
      }
    }
    const payload = {
      "name": search,
      "size": size,
      "from": from,
      "lastUpdtTsSort": "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"]:"",
      "createTsSort": "createTs" in tableSort ? tableSort["createTs"]:""
    }
    if(checkToken()){
      props.getUserList(payload);
    }
  }, [size, from, props.administration.refreshKey,search,tableSort]);

  useEffect(() => {
    const { userList } = props.administration;
    let data = []
    userList.list.forEach(element => {
      data.push(createUserListData(element.id, element.firstName, element.lastName, element.adUserId, element.primaryEmailAddr, element.userRole,  element.lastUpdtTs,element.createTs,element))
    });
    setRows(data);
    setUsersListTotal(userList.total);
    setShowLoader(userList.loading);
  }, [props.administration.userList]);

  const columns = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    {
      id: 'adUserId',
      label: 'PC / Active Directory Name',
    },
    {
      id:'primaryEmailAddr',
      label:'Email'
    },
    {
      id: 'userRole',
      label: 'Role',
    },
    {
      id: 'lastUpdtTs',
      label: 'Last Updated Time',
      sort:"lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"]:"",
    },
    {
      id: 'createTs',
      label: 'Created Time',
      sort:"createTs" in tableSort ? tableSort["createTs"]:"",
    },
    {
      id: 'action',
      label: 'Action',
      cell: (item) => {
        return (<>
          <IconButton aria-label="delete" onClick={() => handleOnEditAction(item.row)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="edit" onClick={() => handleOnDeleteAction(item)} >
            <DeleteIcon />
          </IconButton>
        </>)
      }
    }
  ];

  const handleOnEditAction = (row) => {
    setOnEdit(row);
    setShowModal(true);
  }

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    setFrom(0);

  }

  const renderModal = () => {
    return (<UserListAddEditComponent 
      validated={validated}
      submitUserData={handleSubmit}
      onEdit={Object.keys(onEdit).length !== 0}
      row={onEdit}
    />)
  }

  const handleDelete = (row) => {
    const payload = {
      "id":row.id
     }
    props.deleteUserList(payload);
  }

  const handleOnDeleteAction = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "User will be removed from the application",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm:()=>handleDelete(row),
    });
  }

  const handleRefresh = () => {
    setShowLoader(true);
    props.refreshKey();
  }
  
  return (
    <>
      <Grid container className="container-block" rowSpacing={2}>
        {showModal && <ModalComponent shown={showModal}
          close={() => {
            setShowModal(false);
            setOnEdit({});

          }}
          title={Object.keys(onEdit).length === 0 ? "ADD USER" : "EDIT USER"}
          modalBody={renderModal}
          size ={"md"}
          label={Object.keys(onEdit).length === 0 ? "ADD USER" : "EDIT USER"}
        />}
        <Grid item xs={12} md={6}>
          <PageHeaderComponent
            title={"APPLICATION USERS"}
            showSubtitle={true}
            subtitle={"Total Users"}
            count={usersListTotal}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            <CustomButton variant="contained" startIcon={<i className="fa fa-plus" aria-hidden="true"></i>} onClick={() => {
                setShowModal(true); setOnEdit({});
              }}>
              Add 
            </CustomButton>
            <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={handleRefresh}>
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ flexGrow: 1 }}>
          <SearchComponent 
            placeholder={"Name"}
            handleChange={handleSearch}
            searchText={search}
          />
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            handlePagination={handleNext}
            total={usersListTotal}
            handleSort={handleSort}
            page={Math.floor(from/size)}
            rowsPerPage={size}
            dataLoading={showLoader}
          />
        </Grid>
      </Grid>
    </>
  );
};

UsersComponent.propTypes = {
  getUserList: PropTypes.func.isRequired,
  administration: PropTypes.object,
  addUserList: PropTypes.func.isRequired,
  editUserList: PropTypes.func.isRequired,
  deleteUserList: PropTypes.func.isRequired,
  refreshKey:PropTypes.func.isRequired,
  allowedRoles: PropTypes.array,
  userAuth: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    getUserList: (payload) => dispatch(getUserList(payload)),
    addUserList: (payload) => dispatch(addUserList(payload)),
    editUserList: (payload) => dispatch(editUserList(payload)),
    deleteUserList: (payload) => dispatch(deleteUserList(payload)),
    refreshKey: () => dispatch(refreshKey()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);