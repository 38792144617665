import React, {useState, useEffect} from 'react';
import { Grid, Link as MaterialLink, Collapse } from '@mui/material';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";

import Stateseal from '../../assets/images/stateseal.png';
import UpArrow from '../../assets/images/up-arrow_header.svg';
import GovtIcon from '../../assets/images/govt-small-icon.svg';
import LockLarge from '../../assets/images/Lock-large.svg';
import LockSmall from '../../assets/images/lock-small-icon.svg';
import '../../styles/greenBanner.scss';

const GreenBanner = (props) => {
  const [checked, setChecked] = useState(false);
  const [language, setLanguage] = useState({});

  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  useEffect(() => {
    setLanguage(props.language)
  }, [props.language])
    return (
        <Grid className="green-wrapper">
        <Grid container maxWidth="lg" className="wrapper-banner">
          <Grid item xs={12} sx={{ mx: 2 }} display={{ xs: 'none', md: 'block' }} className="banner div-center"
            onClick={handleChange}>
            <Grid item xs={12} sx={{ mx: 2,cursor: 'pointer' }} display="flex" alignItems="center">
              <div className="banner-logo"> 
                <img src={Stateseal} alt="Massachusetts State Seal" className="banner-logo-dark" />
              </div>
              <div className="banner-text div-center font-state-banner">
                <MaterialLink style={{ color: 'white' }} component={Link} className="">
                  <span className="banner-subtext">{language.bannerTitle}</span>
                  <span className="button-icon--quaternary">
                  <b className="font-state-how">{language.bannerSubTitle} </b>
                  <img src={UpArrow} alt={!checked ? 'Down' : 'Up'} className={!checked ? 'img-animation img-updown' : 'img-downup'} />
                  </span>
                </MaterialLink>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} display={{ xs: 'block', md: 'none' }}
            className="cursor-pointer" onClick={handleChange}>
            <Grid item xs={12} display="flex">
              <img src={Stateseal} alt="Massachusetts State Seal logo" className="banner-logo-dark" />
              <p className="banner-text font-state-banner">
              {language.bannerTitle} &nbsp;&nbsp;&nbsp;
                <b className="font-state-how"> {language.bannerSubTitle} </b>&nbsp;&nbsp;
                <img src={UpArrow} alt={!checked ? 'Down' : 'Up'} width="15" className={!checked ? 'img-animation img-updown' : 'img-downup'} />
              </p>
            </Grid>
          </Grid>
          <Collapse in={checked} className="content-wrapper">
            <Grid container sx={{ mx: 2 }}>
              <Grid item xs={12} md={5.5}>
                <div className='green-banner-contents-wrapper'>
                  <div className='mass-gov-banner-icon'> <img alt="Government building logo" src={GovtIcon} /></div>
                  <div className='green-banner-contents'>
                    <span className="font-state-banner">{language.OfficialWebsitesUseMassGov}</span>
                    <p className="font-state-banner">{language.AMassGovWebsiteBelongsToAnOfficialGovernmentOrganizationInMassachusetts}</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <div className='green-banner-contents-wrapper'>
                  <div className='mass-gov-banner-icon'> <img alt="HTTPS logo" src={LockLarge} /></div>
                  <div className='green-banner-contents'>
                    <span className="font-state-banner">{language.SecureWebsitesUseHTTPSCertificate}</span>
                    <p className="font-state-banner m0" >{language.ALockIcon} <img src={LockSmall} alt="" /> {language.ALockIcon1}</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    )
}

GreenBanner.propTypes = {
  language: PropTypes.object,
};

export default connect( mapStateToProps, )(GreenBanner);