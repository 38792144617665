const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";

export function signupLanguageLabels(language) {
    switch (language) {
    case EN:
    return{       
        reg:"REGISTER",
        regCnt:"First-time visitor? HIP-authorized vendors should fill out this registration form with accurate and complete information.",
        needHelp:"Need help?",
        needHelpCnt:"Register below and DTA will verify your info. Once verified, your information will populate the DTA Finder map so SNAP clients can search for HIP locations like yours. Please keep your login information on file. As an authorized HIP vendor, you will be required to",
        needHelpCnt1:"to this site regularly to keep your HIP location information up to date at all times.",
        contact:"Contact",
        needAssist:"if you need assistance.",
        basicReg:"Basic Registration",
        publicInfo:"Public Info",
        locType:"Location Types",
        hipCSA:"HIP CSA Program",
        hipFarmStand:" HIP Farm Stand",
        hipFarmerBooth:"HIP Farmers' Market Booth",
        hipMblMrkt:"HIP Mobile Market Stop",
        hipFrmrMrkt:"HIP Farmers' Market",
        firNm:"First Name",
        lastNm:"Last Name",
        vendorNm:"Farm / Vendor Name",
        priEmail:"Primary Email Address ",
        priPhn:"Primary Phone Number",
        createPwd:"Create Password",
        confirmPwd:"Confirm Password",
        priCntInfo:"Additional Contact Info",
        csaPickupSite: "HIP CSA Program",
        farmStand: "HIP Farm Stand",
        farmersMarketBooth: "HIP Farmers Market Booth",
        farmersMarket: "HIP Farmers Market",
        mobileMarket: "HIP Mobile Market Stop",

        // Program
        pickup_site_name: "Pickup Site Name",
        location_name: "Location Name",
        farmer_market_name:"Farmers' Market Name",
        address1: "Address Line 1",
        address2: "Address Line 2",
        city: "City",
        state: "State",
        zipcode:"Zip Code",
        specialInstr:"Special Instructions",
        specialWorkTime:"Special Hour(s) of Operation",
        mnthOfOprtn:"Month(s) of Operation",
        workingHours: "Regular Hour(s) of Operation"
    };//return End

    case ES:
    return{
        // "reg":"REGISTRARSE",
        // "regCnt": "¿Visitante por primera vez? Los proveedores autorizados por HIP deben completar este formulario de registro con información precisa y completa.",
        // "needHelp":"¿Necesitas ayuda?",
        // "needHelpCnt": "Regístrese a continuación y DTA verificará su información. Una vez verificada, su información llenará el mapa de DTA Finder para que los clientes de SNAP puedan buscar ubicaciones de HIP como la suya. Guarde su información de inicio de sesión en un archivo. Como proveedor autorizado de HIP, se le pedirá que",
        // "needHelpCnt1": "a este sitio con regularidad para mantener la información de ubicación de HIP actualizada en todo momento.",
        // "contact":"Contacto",
        // "needAssist": "si necesita ayuda.",
        // "basicReg":"Registro básico",
        // "publicInfo":"Información pública",
        // "locType":"Tipos de ubicación",      
        // "firNm":"Primer nombre",
        // "lastNm":"Apellido",
        // "vendorNm":"Nombre de la granja / proveedor",
        // "priEmail":"Dirección de correo principal",
        // "priPhn":"Número de teléfono primario",
        // "createPwd":"Crear contraseña",
        // "confirmPwd":"Confirmar contraseña",
        // "priCntInfo":"Información de contacto adicional"
        reg:"REGISTER",
        regCnt:"First-time visitor? HIP-authorized vendors should fill out this registration form with accurate and complete information.",
        needHelp:"Need help?",
        needHelpCnt:"Register below and DTA will verify your info. Once verified, your information will populate the DTA Finder map so SNAP clients can search for HIP locations like yours. Please keep your login information on file. As an authorized HIP vendor, you will be required to",
        needHelpCnt1:"to this site regularly to keep your HIP location information up to date at all times.",
        contact:"Contact",
        needAssist:"if you need assistance.",
        basicReg:"Basic Registration",
        publicInfo:"Public Info",
        locType:"Location Types",
        hipCSA:"HIP CSA Program",
        hipFarmStand:" HIP Farm Stand",
        hipFarmerBooth:"HIP Farmers' Market Booth",
        hipMblMrkt:"HIP Mobile Market Stop",
        hipFrmrMrkt:"HIP Farmers' Market",
        firNm:"First Name",
        lastNm:"Last Name",
        vendorNm:"Farm / Vendor Name",
        priEmail:"Primary Email Address ",
        priPhn:"Primary Phone Number",
        createPwd:"Create Password",
        confirmPwd:"Confirm Password",
        priCntInfo:"Additional Contact Info",
        csaPickupSite: "HIP CSA Program",
        farmStand: "HIP Farm Stand",
        farmersMarketBooth: "HIP Farmers Market Booth",
        farmersMarket: "HIP Farmers Market",
        mobileMarket: "HIP Mobile Market Stop",

        // Program
        pickup_site_name: "Pickup Site Name",
        location_name: "Location Name",
        farmer_market_name:"Farmers' Market Name",
        address1: "Address Line 1",
        address2: "Address Line 2",
        city: "City",
        state: "State",
        zipcode:"Zip Code",
        specialInstr:"Special Instructions",
        specialWorkTime:"Special Hour(s) of Operation",
        mnthOfOprtn:"Month(s) of Operation",
        workingHours: "Regular Hour(s) of Operation"
    };//return End

    case PT:
    return{
      reg:"REGISTER",
      regCnt:"First-time visitor? HIP-authorized vendors should fill out this registration form with accurate and complete information.",
      needHelp:"Need help?",
      needHelpCnt:"Register below and DTA will verify your info. Once verified, your information will populate the DTA Finder map so SNAP clients can search for HIP locations like yours. Please keep your login information on file. As an authorized HIP vendor, you will be required to",
      needHelpCnt1:"to this site regularly to keep your HIP location information up to date at all times.",
      contact:"Contact",
      needAssist:"if you need assistance.",
      basicReg:"Basic Registration",
      publicInfo:"Public Info",
      locType:"Location Types",
      hipCSA:"HIP CSA Program",
      hipFarmStand:" HIP Farm Stand",
      hipFarmerBooth:"HIP Farmers' Market Booth",
      hipMblMrkt:"HIP Mobile Market Stop",
      hipFrmrMrkt:"HIP Farmers' Market",
      firNm:"First Name",
      lastNm:"Last Name",
      vendorNm:"Farm / Vendor Name",
      priEmail:"Primary Email Address ",
      priPhn:"Primary Phone Number",
      createPwd:"Create Password",
      confirmPwd:"Confirm Password",
      priCntInfo:"Additional Contact Info",
      csaPickupSite: "HIP CSA Program",
      farmStand: "HIP Farm Stand",
      farmersMarketBooth: "HIP Farmers Market Booth",
      farmersMarket: "HIP Farmers Market",
      mobileMarket: "HIP Mobile Market Stop",

      // Program
      pickup_site_name: "Pickup Site Name",
      location_name: "Location Name",
      farmer_market_name:"Farmers' Market Name",
      address1: "Address Line 1",
      address2: "Address Line 2",
      city: "City",
      state: "State",
      zipcode:"Zip Code",
      specialInstr:"Special Instructions",
      specialWorkTime:"Special Hour(s) of Operation",
      mnthOfOprtn:"Month(s) of Operation",
      workingHours: "Regular Hour(s) of Operation"
    };//return End

    case ZH:
    return{
      reg:"REGISTER",
      regCnt:"First-time visitor? HIP-authorized vendors should fill out this registration form with accurate and complete information.",
      needHelp:"Need help?",
      needHelpCnt:"Register below and DTA will verify your info. Once verified, your information will populate the DTA Finder map so SNAP clients can search for HIP locations like yours. Please keep your login information on file. As an authorized HIP vendor, you will be required to",
      needHelpCnt1:"to this site regularly to keep your HIP location information up to date at all times.",
      contact:"Contact",
      needAssist:"if you need assistance.",
      basicReg:"Basic Registration",
      publicInfo:"Public Info",
      locType:"Location Types",
      hipCSA:"HIP CSA Program",
      hipFarmStand:" HIP Farm Stand",
      hipFarmerBooth:"HIP Farmers' Market Booth",
      hipMblMrkt:"HIP Mobile Market Stop",
      hipFrmrMrkt:"HIP Farmers' Market",
      firNm:"First Name",
      lastNm:"Last Name",
      vendorNm:"Farm / Vendor Name",
      priEmail:"Primary Email Address ",
      priPhn:"Primary Phone Number",
      createPwd:"Create Password",
      confirmPwd:"Confirm Password",
      priCntInfo:"Additional Contact Info",
      csaPickupSite: "HIP CSA Program",
      farmStand: "HIP Farm Stand",
      farmersMarketBooth: "HIP Farmers Market Booth",
      farmersMarket: "HIP Farmers Market",
      mobileMarket: "HIP Mobile Market Stop",

      // Program
      pickup_site_name: "Pickup Site Name",
      location_name: "Location Name",
      farmer_market_name:"Farmers' Market Name",
      address1: "Address Line 1",
      address2: "Address Line 2",
      city: "City",
      state: "State",
      zipcode:"Zip Code",
      specialInstr:"Special Instructions",
      specialWorkTime:"Special Hour(s) of Operation",
      mnthOfOprtn:"Month(s) of Operation",
      workingHours: "Regular Hour(s) of Operation"
    };//return End

    case VI:
    return{
      reg:"REGISTER",
      regCnt:"First-time visitor? HIP-authorized vendors should fill out this registration form with accurate and complete information.",
      needHelp:"Need help?",
      needHelpCnt:"Register below and DTA will verify your info. Once verified, your information will populate the DTA Finder map so SNAP clients can search for HIP locations like yours. Please keep your login information on file. As an authorized HIP vendor, you will be required to",
      needHelpCnt1:"to this site regularly to keep your HIP location information up to date at all times.",
      contact:"Contact",
      needAssist:"if you need assistance.",
      basicReg:"Basic Registration",
      publicInfo:"Public Info",
      locType:"Location Types",
      hipCSA:"HIP CSA Program",
      hipFarmStand:" HIP Farm Stand",
      hipFarmerBooth:"HIP Farmers' Market Booth",
      hipMblMrkt:"HIP Mobile Market Stop",
      hipFrmrMrkt:"HIP Farmers' Market",
      firNm:"First Name",
      lastNm:"Last Name",
      vendorNm:"Farm / Vendor Name",
      priEmail:"Primary Email Address ",
      priPhn:"Primary Phone Number",
      createPwd:"Create Password",
      confirmPwd:"Confirm Password",
      priCntInfo:"Additional Contact Info",
      csaPickupSite: "HIP CSA Program",
      farmStand: "HIP Farm Stand",
      farmersMarketBooth: "HIP Farmers Market Booth",
      farmersMarket: "HIP Farmers Market",
      mobileMarket: "HIP Mobile Market Stop",

      // Program
      pickup_site_name: "Pickup Site Name",
      location_name: "Location Name",
      farmer_market_name:"Farmers' Market Name",
      address1: "Address Line 1",
      address2: "Address Line 2",
      city: "City",
      state: "State",
      zipcode:"Zip Code",
      specialInstr:"Special Instructions",
      specialWorkTime:"Special Hour(s) of Operation",
      mnthOfOprtn:"Month(s) of Operation",
      workingHours: "Regular Hour(s) of Operation"

    };//return End

    default:
    return;
  }// Switch End

}// function End
