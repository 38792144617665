import { takeLatest, put, call } from "redux-saga/effects";
import { API_URL } from "../../config";
import { actionTypes as adminActionTypes} from "../actionTypes/administration";
import axiosInstance from "../interceptor";
import { actionTypes } from "../actionTypes/comments";
import { popUpAlert } from "../../utils/globalMethods";

function* getCommentListSaga(props) {
    let url =  `${API_URL}/comment/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_COMMENT_LIST, payload: result.data });
    }
    catch {
        console.log("Failed");
    }
}

function* addCommentSaga(props) {
    let url =  `${API_URL}/comment/add`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.ADD_COMMENT_RESPONSE, payload: result.data });
        yield put({ type: adminActionTypes.REFRESH_KEY });
    }
    catch {
        console.log("Failed");
    }
}

function* updateCommentSaga(props) {
    let url =  `${API_URL}/comment/update`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.UPDATE_COMMENT_RESPONSE, payload: result.data });
        yield put({ type: adminActionTypes.REFRESH_KEY });
        
    }
    catch {
        console.log("Failed");
        
    }
}

function* deleteCommentSaga(props) {
    let url =  `${API_URL}/comment/delete`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.DELETE_COMMENT_RESPONSE, payload: result.data });
        yield put({ type: adminActionTypes.REFRESH_KEY });
        popUpAlert({
            title: "Success",
            text: "Comment has been deleted successfully!",
            icon: "success",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
    catch {
        console.log("Failed");
        popUpAlert({
            title: "Error",
            text: "Failed to delete the comment!",
            icon: "error",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }
}

export const watchGetCommentListSaga = function*() {
    yield takeLatest(actionTypes.GET_COMMENT_LIST, getCommentListSaga);
};

export const watchAddCommentSaga = function*() {
    yield takeLatest(actionTypes.ADD_COMMENT, addCommentSaga);
};

export const watchUpdateCommentSaga = function*() {
    yield takeLatest(actionTypes.UPDATE_COMMENT, updateCommentSaga);
};

export const watchDeleteCommentSaga = function*() {
    yield takeLatest(actionTypes.DELETE_COMMENT, deleteCommentSaga);
};