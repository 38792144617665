export const GET_USERS_STARTED = "GET_USER_STARTED";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const SET_USER = "SET_USER";
export const PUT_USER = "PUT_USER";
export const SET_USER_ERROR_RESPONSE = "SET_USER_ERROR_RESPONSE";
export const SET_USER_ASYNC_SUCCESS = "SET_USER_ASYNC_SUCCESS";
export const SET_USER_SUCCESS ="SET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_LOC_TYPE = "SET_LOC_TYPE";
export const GET_LOC_TYPE = "GET_LOC_TYPE";
export const SET_LOC_TYPE_QUES = "SET_LOC_TYPE_QUES";
export const GET_LOC_TYPE_QUES = "GET_LOC_TYPE_QUES";
export const SET_PROGRAM = "SET_PROGRAM";
export const GET_PROGRAM = "GET_PROGRAM";
export const SET_PROGRAM_FAILURE = "SET_PROGRAM_FAILURE";
export const GET_CITY = "GET_CITY";
export const SET_CITY = "SET_CITY";
export const GET_ORDER_OPTION = "GET_ORDER_OPTION";
export const SET_ORDER_OPTION = "SET_ORDER_OPTION";
export const GET_MONTH_OPTION = "GET_MONTH_OPTION";
export const SET_MONTH_OPTION = "SET_MONTH_OPTION";
export const GET_DAY_OPTION = "GET_DAY_OPTION";
export const SET_DAY_OPTION = "SET_DAY_OPTION";
export const STOP_LOADING = "STOP_LOADING";

export const actionTypes = {
    SEND_SUPPORT_EMAIL : "SEND_SUPPORT_EMAIL",
    REFRESH_KEY : "REFRESH_KEY"
};

export  function sendSupportEmail(payload) {
    return {
      type: actionTypes.SEND_SUPPORT_EMAIL,
      payload: payload
    };
}

export  function clearRegistrationStatus(payload) {
    return {
      type: SET_USER_ERROR_RESPONSE,
      payload: payload
    };
}

export function addProgram(payload) {
  return {
    type: actionTypes.GET_PROGRAM,
    payload: payload
  };
}



// export const setRegistration = registration => {
//     console.log("new message", registration);
//     const data = {"first_name":"James","last_name":"Lucas","vendor_name":"New Vendor","primary_email":"primary@test.com","primary_mobile":"(343) 535-2352","password":"12345678","confirm_password":"12345678","primary_contact_info":"test","fns_number":"12345678978"};
//     console.log("new message", data);
//     return {
//       type: SET_USER,
//       data
//     };
//   };