/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import _ from "lodash";
import SignupFieldArray from "./signup-fieldarray";
// import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { popUpAlert, checkToken } from "../../../utils/globalMethods";
import { useNavigate } from "react-router-dom";

const SignupForm = ({
  type,
  vendorType,
  //   location_types_name,
  existPgmData,
  programData,
  enableMenuList,
  section,
  city,
  days,
  months,
  order_option,
  activeTabId,
  onSaveAndNext,
  addProgramData,
  removeVendorStore,
  locShowHide,
  farmermarkets,
  vendorInfo,
  userAuth,
  user,
  deleteStore,
  programArray
}) => {
// const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [latLng, setLatLng] = useState();
  const [farmerMarket, setFarmerMarket]=useState();
  const [submitIndex, setSubmitIndex]=useState();
  const [isReset, setIsReset] = useState(false);
  // const clone = _.cloneDeep(programData);
  const [ refreshKey, setRefreshKey] = useState();

  const navigate = useNavigate();

  // let programArray = [];
  // if (type === "edit" && existPgmData && existPgmData[section.id]) {
  //   existPgmData[section.id].map((item)=>{
  //       let programObj = item;
  //       if (Array.isArray(item.special_hoursOfOperation) && item.special_hoursOfOperation.length === 0) {
  //           programObj.special_hoursOfOperation = [{...clone[section.id].special_hoursOfOperation[0]}];
  //       }
  //       programObj.updated = item.updated;
  //       programArray.push(programObj);
  //   });
  //   console.log(programArray)
  // } else {
  //   programArray = [{
  //       name: { working_hours: clone[section.id].working_hours, storeAvailFlag: true},
  //       special_hoursOfOperation: [{...clone[section.id].special_hoursOfOperation[0]}],
  //       updated: false
  //   }];
  // }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
        // program: [{ firstName: "Bill", lastName: "Luo", age: "2" }]
        program: { 
            [section.id]: programArray
            // [{
            //     name: { working_hours: clone[section.id].working_hours},
            //     special_hoursOfOperation: [{...clone[section.id].special_hoursOfOperation[0]}]
            // }],
        }
      }
  });

  useEffect(()=>{
    if (type === "edit" && existPgmData && existPgmData[section.id]) {
        setIsReset(false);
    } else {
        setIsReset(true);
    }
  },[existPgmData]);

  const handleOnNextFinish = (submitType) => {
    if(submitType=="finish"){
      if(checkToken()){
        navigate("/dtahip/manage-vendors");
      }else{
        navigate("/dtahip");
      }
    }else if(submitType == "next"){
      onSaveAndNext()
    }
  }

  const onSaveAndFinishClick = (submitType) =>{
    const formValues = getValues(`program[${section.id}]`);
    let allFormsSubmitted = false;
    formValues.map(item => {
      if(item.updated && !allFormsSubmitted){
        allFormsSubmitted = true
      }
    })
    if(allFormsSubmitted){
      handleOnNextFinish(submitType);
    }else{
      popUpAlert({
        title: "Unsaved changes",
        html: "<div style='display: inline-block'>Are you sure you want to proceed? <br /><div style='display: inline-block;margin:14px 13px 0 13px;line-height: 1.3;text-align: left;'>The store changes will be ignored, please save any changes you've made on the stores by clicking 'Update'</div></div>",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonClass: "btn-success",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        closeOnConfirm: false,
        onConfirmed: true,
        handleConfirm:()=>handleOnNextFinish(submitType),
      });
    }
  };
  const lastMenuIndex = enableMenuList.length - 1;

  const selectedCityList = (data) => {
    setSelectedCity(data);
  };

  const getZipcode = (data) => {
    setZipcode(data);
  };

  const getLatLng = (data) => {
    setLatLng(data);
  };

  const getFarmerMarketList=(data)=>{
    setFarmerMarket(data);
  };

  const getVendorStoreId=(id,type, showHide=true)=>{
    if (type === "remove") {
        removeVendorStore(id);
    } else {
        locShowHide(id,showHide);
    }
  };

  const onSubmit = (data,submitType="next") => {
    let farmersMarketBoothName = true;

    const programData = [data?.program?.[submitIndex?.[1]]?.[submitIndex?.[0]]];
    const existStore = existPgmData?.[section.id]?.[submitIndex[0]]?.name;
    
    let program = [];
    let isDayMonthEmpty = false;
    let isAddressFilled = false

    if (programData && programData.length) {
      programData.forEach((element, i) => {
        let pgm = {};
        
        if ((section.id === "farmersMarketBooth" || section.id ==="farmersMarket") && element?.name && "farmer_market_name" in element?.name) {
          farmersMarketBoothName = element?.name?.farmer_market_name
          pgm.farmersMarketName = element?.name?.farmer_market_name;
          farmermarkets.map(item => {
            if(item.name==element?.name?.farmer_market_name){
              pgm.farmersMarketId=item.id
            }
          })
        }
        if (section.id === "csaPickupSite") {
            pgm.pickupSiteName = element?.name?.pickup_site_name;
        }
        if (section.id === "mobileMarket" && element?.name && "location_name" in element?.name) {
            pgm.mobileMarketLocName = element?.name?.location_name;
        }
        vendorType.map(item => {
          pgm[item.addId]=item.id==section.id?"Y":"N"
          if(item.id==section.id){
            pgm.storeTypeCode=item.storeTypeCode
            pgm[item.info]=element?.name?.special_instructions
          }
        })
        
        // pgm.location_types_name = section.id;
        pgm.address1 = element?.name?.address_1;
        pgm.address2 = element?.name?.address_2;
        pgm.zip = element?.name?.zipcode ? element?.name?.zipcode : "";
        pgm.city = element?.name?.city && element?.name?.city.name ? element?.name?.city.name : "";
        pgm.state = "MA";
        pgm.isAddressChange = "N";
        pgm.lat = latLng && latLng[`program[${section.id}][${i}].name[latlng]`]
          ? latLng[`program[${section.id}][${i}].name[latlng]`].lat
          : element?.name?.latitude;
        pgm.lon = latLng && latLng[`program[${section.id}][${i}].name[latlng]`]
            ? latLng[`program[${section.id}][${i}].name[latlng]`].lng
            : element?.name?.longitude;
        if (existStore?.addressDetail?.addressLine1?.trim() != pgm?.address1?.trim() || existStore?.addressDetail?.addressLine2?.trim() != pgm?.address2?.trim()
          ||  existStore?.addressDetail?.zipcode?.trim() != pgm?.zip?.trim() || existStore?.addressDetail?.city != pgm?.city) {
          pgm.isAddressChange = "Y";
        }       
        
      
        pgm.workingHours = element?.name?.working_hours.map((item) => {
            const data = {};
            days.map(day => {
              if(day.values === item.day)
                data.weekdayCode = day.weekDay;
                data.startTime = item.open_time + " " + item.open_time_meridian;
                data.endTime = item.close_time + " " + item.close_time_meridian;
                data.isWorkingDay = item.holiday === false || item.holiday === "false" ? "Y":"N";    
            })
          return data;
        });
        
        pgm.specialWorkTime = element?.name?.is_specialhours?"Y":"N";
        if (
          element?.name?.is_specialhours &&
          element.special_hoursOfOperation.length
        ) {
          let pgm_splhr = [];
          element.special_hoursOfOperation.forEach((item) => {
            let sprhr = {};
            const filterDays = item.day ? item.day.map((item) => item.value) : [];
            const filterMonths = item.month
              ? item.month.map((item) => item.value)
              : [];

            if (filterDays.length === 0 || filterMonths.length === 0) {
                swalAlertAuto("warning", "Month & Days should not be empty in Special Hour(s) of Operation");
                isDayMonthEmpty = true;
            } 
            
            sprhr.specialStatusCode = item.status==="Available"?"AVAIL":"NAVAIL";
            sprhr.weekInfo = item.week;
            sprhr.allDayOperation = filterDays.length===7?"Y":"N";
            sprhr.allDayInfo = filterDays;
            sprhr.allMonthInfo = [];
            months.map((ele) => {
              if (filterMonths?.indexOf(ele.values) != -1) {
                sprhr.allMonthInfo.push(ele.month)
              }
            });
            sprhr.startTime = item.open_time
            ? (typeof item.open_time === "object" ? item.open_time?.values : item.open_time) + " " + (item.open_time_meridian ? item.open_time_meridian : "am")
            : "12:00 am";
          sprhr.endTime = item.close_time
            ? (typeof item.close_time === "object" ? item.close_time?.values : item.close_time) + " " +  (item.close_time_meridian ? item.close_time_meridian : "pm")
            : "12:00 pm";
            pgm_splhr.push(sprhr);
          });
          pgm.specialHoursOfOperation = pgm_splhr;
        } else {
          pgm.specialHoursOfOperation = [];
        }

        // if (type === "edit") {
        //     pgm.distribution_option = element?.name?.distribution_option;
        // } else {
          pgm.distributionOption = {}
          order_option.map((item,index) => {
            pgm.distributionOption[item.id] = element?.name?.distribution_option?.[index] !== false ? "Y" : "N";
          });
        // }

        if (pgm?.distributionOption?.['otherType'] === 'Y') {
          pgm.distributionOption['otherTypeDescription']  = element?.name?.othertext?.trim();
        }

        pgm.monthsOperation = {}
        months.map((item,index) => {
          pgm.monthsOperation[item.id] = element?.name?.months_operations?.[index] !== false ?  "Y" : "N";
        });
        let lastUpdtUidNam = "";
        let role = "";
        // let loginId = 0;
        if(type=="edit" && userAuth && userAuth?.user){
          pgm.vendorId = vendorInfo.vendorId;
          if (userAuth.user.Role === "ADMIN") {
              role = "ADM_";
          } else if(userAuth.user.Role === "HIPCRD") {
              role = "CRD_";
          } else {
              role = "VDR_";
          }
          lastUpdtUidNam = role + userAuth?.user?.id;
          // loginId = userAuth.user.id;
        }else if(type=="add"){
          pgm.vendorId = user.id;
          // loginId = user.userId;
          lastUpdtUidNam = "VDR_"+user.userId;
        }
        const timestamp = new Date().getTime();
        const systemInfo = sessionStorage.getItem("systemInfo");
        const systemData = JSON.parse(systemInfo);
        let payloaddata = {
          "createTimeStamp":timestamp,
          "ipAddress": systemData.ip,
          "isActive": element?.name?.storeAvailFlag ? "Y" : "N",
          "lastUpdtTimeStamp": timestamp,
          "lastUpdtUidNam": lastUpdtUidNam, //"VDR_1128",
          // "loginId": loginId,
          "isUpdate" :  element?.name?.id? "Y" : "N",
          "storeId": element?.name?.id ?  element?.name?.id : 0
        }
        pgm = {...pgm,...payloaddata}
        if (element?.name?.id){
            pgm.vendorStoreId = element?.name?.id;
        }
        // pgm.vendorName = user?.vendor_name;
        program.push(pgm);
      });
      
      if(!farmersMarketBoothName){
        popUpAlert({
          // title: "Error",
          text: "Please select Farmers' Market Name",
          icon: "warning",
          timer:1500,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }else {
        if (!program[0].address1) {
          swalAlertAuto("warning", "Address1 should not be empty");
          isAddressFilled = true;
      }  else if (!program[0].city) {
          swalAlertAuto("warning", "City should not be empty");
          isAddressFilled = true;
      } else if (!program[0].state) {
          swalAlertAuto("warning", "State should not be empty");
          isAddressFilled = true;
      } else if (!program[0].zip) {
          swalAlertAuto("warning", "Zipcode should not be empty");
          isAddressFilled = true;
      }
      }
      if (!isDayMonthEmpty && farmersMarketBoothName && !isAddressFilled) {
        setValue(`program[${submitIndex[1]}][${submitIndex[0]}][updated]`, true);
        addProgramData(program);
        const val = Math.floor(1000 + Math.random() * 9000);
        setRefreshKey(val);
      }
    }
  };

  
const swalAlertAuto = (type, msg) => {
    var typeTxt = "";
    if (type === "success") {
      typeTxt = "Success";
    } else if (type === "error") {
      typeTxt = "Failed";
    }
    swal({
      icon: type,
      type: type,
      title: typeTxt,
      text: msg,
      showConfirmButton: false,
    //   timer: 2000
    //   button: false
    });
  };

  const indexSubmit = (index,vendorType) => {
    setSubmitIndex([index,vendorType]);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dh-head">
        <div className="row">
          <div className="col-md-12">
            <h4>{section.title}</h4>
          </div>
        </div>
      </div>
      <div className="dh-content">
        <div className="row">
          <div className="col-md-12">
            <div className="dh-box-mr">
              <div id="" style={{ marginTop: "10px" }}>
                <div className="card-body pl-2 pr-2">
                  <SignupFieldArray
                    {...{
                      type,
                      control,
                      register,
                      watch,
                      //   defaultValues,
                    //   reset,
                      getValues,
                      setValue,
                      errors,
                      section,
                      city,
                      days,
                      months,
                      order_option,
                      programData,
                      farmermarkets,
                      refreshKey                  
                    }}
                    existPgmData={existPgmData}
                    indexSubmit={indexSubmit}
                    selectedCityList={selectedCityList}
                    setZipcodeList={getZipcode}
                    setLatLngList={getLatLng}
                    setFarmerMarketList={getFarmerMarketList}
                    getVendorStoreId={getVendorStoreId}
                    isReset={isReset}
                    deleteStore={deleteStore}
                    programArray={programArray}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {type=== "add" && <div className="dh-footer">
        <div className="row">
          <div className="col-md-12">
            <div className="dh-disp text-center">
              <button
                type="button"
                className={`${ type === "edit" ? "btn btn-primary reg-btns":"btn btn-outline-primary btn-lg reg-btns ml-lg-3 ml-md-3 ml-sm-0 mt-lg-0 mt-2 mt-md-0 mt-sm-0 mr-2"}`}
                onClick={() => onSaveAndFinishClick("finish")}
              >
                <i className="fa fa-check"></i> Finish
              </button>
              {enableMenuList[lastMenuIndex] !== activeTabId && (
                <button
                  type="button"
                  className="btn btn-primary btn-lg reg-btns btn-menuStep3 mt-2 mt-sm- mt-md-0 mt-lg-0"
                  onClick={() => onSaveAndFinishClick("next")}
                >
                  Next <i className="fa fa-angle-right"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>}
    </form>
  );
};

SignupForm.propTypes = {
  type: PropTypes.string,
  vendorType:PropTypes.array,
  location_types_name: PropTypes.any,
  existPgmData:PropTypes.any,
  programData: PropTypes.any,
  enableMenuList: PropTypes.any,
  section: PropTypes.any,
  city: PropTypes.array,
  days: PropTypes.array,
  months: PropTypes.array,
  order_option: PropTypes.array,
  onSaveAndNext: PropTypes.func,
  activeTabId: PropTypes.string,
  user: PropTypes.any,
  userAuth: PropTypes.object,
  vendorInfo: PropTypes.object,
  addProgramData: PropTypes.func,
  removeVendorStore: PropTypes.func,
  locShowHide: PropTypes.func,
  refreshKey: PropTypes.number,
  deleteStore: PropTypes.func,
  programArray: PropTypes.object
};

export default SignupForm;
