/* eslint-disable */

import axios from "axios";

import { takeLatest, put, call } from "redux-saga/effects";

import { API_URL } from "../../config";

import * as vendorActions from "../actionTypes/vendordetails";
import axiosInstance from "../interceptor";
import { actionTypes } from "../actionTypes/vendordetails";
import { popUpAlert, pageRefresh } from "../../utils/globalMethods";
import { ExportVendorReport, ExportVendorLastUpdateReport, ExportVendorLoginReport } from "../../pages/common/components/exportExcel";

const swalAlertAuto = (type, msg) => {
  var typeTxt = "";
  if (type === "success") {
    typeTxt = "Success";
  } else if (type === "error") {
    typeTxt = "Failed";
  }
  swal({
    icon: type,
    type: type,
    title: typeTxt,
    text: msg,
    showConfirmButton: false,
    button: false,
    timer: 2000
  });
};

export function* getUserDetails(vendorId) {
  try {
    const uri = `${API_URL}/vendor/id/${vendorId.value}`;;
    const response = yield call(axios.get, uri);
    if (response) {
      yield put({ type: vendorActions.GET_USER_DETAILS_SUCCESS, value: response.data.data });
    }
  } catch (e) {
    console.log(e);
    yield put({ type: vendorActions.GET_USER_DETAILS_FAILURE, value: e.response.data });
  }

}

export function* getVendorId(user) {
  try {
    const uri = `${API_URL}/StaffAlertServices/authlogin`;
    const result = yield call(axiosInstance.post, uri,user.payload);
    if (result.status) {
      yield put({ type: vendorActions.GET_VENDORID_SUCCESS, value: result?.data?.message?.VendorId });
    }
  }
  catch (e) {
    yield put({ type: vendorActions.GET_VENDORID_FAILURE, value: e.response.data });
    console.log(e);
  }
}

export function* updateVendorDetails(action) {
  console.log(action.value, "action.value");
  //   console.log(action);
  console.log("Updating a user");
  try {
    const uri = `${API_URL}/vendor/update/${action.value.id}`;
    const data = Object.assign({}, action.value);
    delete data.id;
    delete data.message
    // console.log(data);
    const result = yield call(axios.put, uri, data);
    console.log(result);

    if (result.status) {
      swalAlertAuto("success", action.value.message);
      yield put({ type: vendorActions.UPDATE_VENDOR_DETAILS_SUCCESS, value: action.value });

    }
  }
  catch (e) {
    console.log("Failed");
    yield put({ type: vendorActions.UPDATE_VENDOR_DETAILS_FAILURE, value: e });

  }
}

export function* updatePublicDetails(action) {
  console.log(action.value, "action.value");
  //   console.log(action);
  console.log("Updating a user");
  try {
    const uri = `${API_URL}/vendor/update/${action.value.id}`;
    const data = Object.assign({}, action.value);
    delete data.id;
    delete data.message
    // console.log(data);
    const result = yield call(axios.put, uri, data);
    // console.log(result);

    if (result.status) {
      swalAlertAuto("success", action.value.message);
      yield put({ type: vendorActions.UPDATE_PUBLIC_DETAILS_SUCCESS, payload: action.value });

    }

    //   yield put({ type: SET_USER, value: result.data.data});
    //   yield put({ type: GET_USER});
  }
  catch {
    console.log("Failed");
    yield put({ type: vendorActions.UPDATE_PUBLIC_DETAILS_FAILURE, value: e });

  }
}

export function* getVendorLocDetails(vendorId) {
    try {
      const uri = `${API_URL}/store/details/vendor/${vendorId.value}`;
      const result = yield call(axios.get, uri);
      if (result.status) {
        yield put({ type: vendorActions.GET_VENDOR_LOC_DETAILS_SUCCESS, value: result?.data?.data });
      }
    }
    catch (e) {
      yield put({ type: vendorActions.GET_VENDOR_LOC_DETAILS_FAILURE, value: e });
      console.log(e);
    }
  }

  
//   export function* removeVendorStore(id) {
//     try {
//         const uri = `${API_URL}/store/id/${id.value}`;
//         const result = yield call(axios.delete, uri);
//         if (result.status) {
//          swalAlertAuto("success", "Store deleted successfully");
//          yield put({ type: vendorActions.GET_REMOVE_STORE_SUCCESS, value: result });
//         }
//     }
//     catch {
//         yield put({ type: vendorActions.GET_REMOVE_STORE_FAILURE, value: e });
//     }
// }

export function* locShowHide(param) {
    try {
        const uri = `${API_URL}/store/showLocation/${param.value.id}`;
        const result = yield call(axios.put, uri, {"isShowLocation": param.value.show});
        if (result.status) {
         swalAlertAuto("success", "Store updated successfully");
         yield put({ type: vendorActions.GET_LOC_SHOWHIDE_SUCCESS, value: result });
        }
    }
    catch {
        yield put({ type: vendorActions.GET_LOC_SHOWHIDE_FAILURE, value: e });
    }
}


export function* updateProgram(program) {
    console.log("post new program", program);
  
  //   console.log("program value::", program.value);
  
  //   console.log("Adding a program");
    // delete program.value.id;
    try {
      const uri = `${API_URL}/store/update/vendor/${program.value.vendorId}`;
      const result = yield call(axios.put, uri, {vendorStores:program.value.vendorStores});
      if (result.status) {
        swalAlertAuto("success", "Store updated successfully");
        yield put({ type: vendorActions.SET_PROGRAM_UPDATE, value: result.data.data });
      //   console.log("Added a program successfully");
      }
    }
    catch {
      // console.log("Failed");
    }
  }

  function* getManageVendorListSaga(props) {
    let url =  `${API_URL}/vendors/list`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: actionTypes.SET_MANAGE_VENDOR_LIST, payload: result.data });
    } 
    catch {
        console.log("Failed");
    }
  }
  function* exportVendorReportSaga(props) {
    let url =  `${API_URL}/vendors/report`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);

        ExportVendorReport(result.data.data)
    } 
    catch {
        console.log("Failed");
    }
  }

  function* exportVendorLateUpdateReportSaga(props) {
    let url =  `${API_URL}/vendor/lastUpdate/export`;
    try {
        const uri = url;
        const result = yield call(axiosInstance.post, uri, props.payload);
        if(result.data.code==200){
          ExportVendorLastUpdateReport(result.data.data)
        }else{
          popUpAlert({
            text: "No Data Found!",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
    }
    catch(e) {
        console.log("Failed",e);
    }
  }

  function* exportVendorLoginReportSaga(props) {
    let url =  `${API_URL}/vendor/lastLogin/export`;
    try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      if(result.data.code==200){
        ExportVendorLoginReport(result.data.data);
      }else{
        popUpAlert({
          text: "No Data Found!",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: false,
        });
      }
    }
    catch {
        console.log("Failed");
    }
  }

function* manageDeleteVendorDetailsSaga(props) {
  let url =  `${API_URL}/delete/vendor`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      console.log(result.data);
      if(result.data.code==200){
          popUpAlert({
              title: "Success",
              text: "Vendor has been deleted successfully!",
              icon: "success",
              timer:1500,
              showCancelButton: false,
              showConfirmButton: false,
          });
      }else{
          popUpAlert({
              title: "Error",
              text: "Failed to delete the vendor!",
              icon: "error",
              timer:1500,
              showCancelButton: false,
              showConfirmButton: false,
          });
      }
      yield put({ type: actionTypes.REFRESH_KEY });
  } 
  catch {
      popUpAlert({
          title: "Error",
          text: "Failed to delete the vendor!",
          icon: "error",
          timer:1500,
          showCancelButton: false,
          showConfirmButton: false,
      });
  }
}

function* getFollowVendorDetailsSaga(props) {
  let url =  `${API_URL}/vendors/follow/list`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      yield put({ type: actionTypes.SET_FOLLOW_VENDOR_DETAILS, payload: result.data });
  } 
  catch {
      console.log("Failed");
  }
}

function* updateFollowVendorDetailsSaga(props) {
  let url =  `${API_URL}/followup/update`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      popUpAlert({
        title: "Successfully",
        text: "Follow Flag has been updated successfully!",
        icon: "success",
        timer:1500,
        showCancelButton: false,
        showConfirmButton: false,
    });
  } 
  catch {
      console.log("Failed");
      popUpAlert({
        title: "Error",
        text: "Failed to Process the request!",
        icon: "error",
        timer:1500,
        showCancelButton: false,
        showConfirmButton: false,
    });
  }
}

function* updatePrimaryEmailSaga(props) {
  let url =  `${API_URL}/reset/email/request`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      yield put({ type: actionTypes.REFRESH_KEY });
      if(result.data.code==200){
        popUpAlert({
          title: "Successfully",
          text: "Primary Email has been updated successfully!",
          icon: "success",
          timer:1500,
          showCancelButton: false,
          showConfirmButton: false,
      });
    } else if (result.data?.code === "601")  {
        popUpAlert({
            title: "Error",
            text: result?.data?.message ? result?.data?.message : " ",
            icon: "error",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
    } 
  } 
  catch  {
      console.log("Failed");
      
  }
}

function* updateVendorBulkSaga(props) {
  let url =  `${API_URL}/vendors/bulkUpdate`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      yield put({ type: actionTypes.REFRESH_KEY });
      popUpAlert({
          title: "Successfully",
          text: "Vendor has been updated successfully!",
          icon: "success",
          timer:1500,
          showCancelButton: false,
          showConfirmButton: false,
      });
  } 
  catch {
      popUpAlert({
          title: "Error",
          text: "Failed to Process the request!",
          icon: "error",
          timer:1500,
          showCancelButton: false,
          showConfirmButton: false,
      });
  }
}

function* getVendorHistoryListSaga(props) {
  let url =  `${API_URL}/vendor/history`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      console.log(result.data);
      yield put({ type: actionTypes.SET_VENDOR_HISTORY_LIST, payload: result.data });
  } 
  catch {
      console.log("Failed");
  }
}

function* getVendorStoreListSaga(props) {
  let url =  `${API_URL}/store/history`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      console.log(result.data);
      yield put({ type: actionTypes.SET_STORE_HISTORY_LIST, payload: result.data });
  } 
  catch {
      console.log("Failed");
  }
}

function* getVendorInfoListSaga(props) {
  let url =  `${API_URL}/dtahip/vendor/info`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      yield put({ type: actionTypes.SET_VENDOR_INFO_LIST, payload: result.data.data });
  } 
  catch {
      console.log("Failed");
  }
}

function* getVerifyVendorSaga(props) {
  let url =  `${API_URL}/verify/vendor`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      popUpAlert({
        title: "Success",
        text: "Vendor has been Verified successfully!",
        icon: "success",
        timer:1500,
        showCancelButton: false,
        showConfirmButton: false,
    });
      yield put({ type: actionTypes.REFRESH_KEY });
  } 
  catch {
    popUpAlert({
      title: "Error",
      text: "Failed to Process the request!",
      icon: "error",
      timer:1500,
      showCancelButton: false,
      showConfirmButton: false,
  });
      console.log("Failed");
  }
}

function* getMyStoreDetailsSaga(props) {
  let url =  `${API_URL}/mystores/list`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      yield put({ type: actionTypes.SET_MY_STORE_DETAILS, payload: result.data.data });
  } 
  catch {
      console.log("Failed");
  }
}

function* removeVendorStoreSaga(props) {
  let url =  `${API_URL}/remove/vendor/store`;
  try {
      const uri = url;
      const result = yield call(axiosInstance.post, uri, props.payload);
      yield put({ type: actionTypes.SET_REMOVE_VENDOR_STORE, payload: result.data });
      yield put({ type: actionTypes.REFRESH_KEY });
      if(result.data.code==200){
        popUpAlert({
            title: "Successfully",
            text: "Store deleted successfully!",
            icon: "success",
            timer:1500,
            showCancelButton: false,
            showConfirmButton: false,
        });
      } else {
        popUpAlert({
          title: "Error",
          text: "Failed to Process the request!",
          icon: "error",
          timer:1500,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
      setTimeout(()=>{
        pageRefresh();
      },1500); 
  } 
  catch {
      console.log("Failed");
      popUpAlert({
        title: "Error",
        text: "Failed to Process the request!",
        icon: "error",
        timer:1500,
        showCancelButton: false,
        showConfirmButton: false,
    });
  }
}

function* getVendorHistoryDetailSaga(props) {
  let url = `${API_URL}/vendor/history/detail`;
  try {
    const uri = url;
    const result = yield call(axiosInstance.post, uri,props.payload);
    yield put({ type: actionTypes.SET_VENDOR_HISTORY_DETAIL, payload: result.data.data });
  }
  catch {
    console.log("Failed");
  }
}


export const watchGetVendorHistoryDetailSaga = function*() {
  yield takeLatest(actionTypes.GET_VENDOR_HISTORY_DETAIL,getVendorHistoryDetailSaga);
};

export function* watchGetUserDetails() {
  yield takeLatest(vendorActions.GET_USER_DETAILS, getUserDetails);
}


export function* watchGetVendorId() {
  yield takeLatest(vendorActions.GET_VENDORID, getVendorId);
}

export function* watchUpdateVendorDetails() {
  yield takeLatest(vendorActions.UPDATE_VENDOR, updateVendorDetails);
}
export function* watchUpdatePublicDetails() {
  yield takeLatest(vendorActions.UPDATE_PUBLIC, updatePublicDetails);
}

export function* watchGetVendorLocDetails() {
 yield takeLatest(vendorActions.GET_VENDOR_LOC_DETAILS, getVendorLocDetails);
}

// export function* watchRemoveVendorStore() {
//     yield takeLatest(vendorActions.SET_REMOVE_VENDOR_STORE, removeVendorStore);
// };

export function* watchUpdateProgram() {
    yield takeLatest(vendorActions.SET_PROGRAM_UPDATE, updateProgram);
};

export function* watchLocShowHide() {
    yield takeLatest(vendorActions.SET_LOC_SHOWHIDE, locShowHide);
};

export const watchGetManageVendorListSaga = function*() {
  yield takeLatest(actionTypes.GET_MANAGE_VENDOR_LIST, getManageVendorListSaga);
};

export const watchManageDeleteVendorDetailsSaga = function*() {
  yield takeLatest(actionTypes.MANAGE_DELETE_VENDOR_DETAILS, manageDeleteVendorDetailsSaga);
};

export const watchGetFollowVendorDetailsSaga = function*() {
  yield takeLatest(actionTypes.GET_FOLLOW_VENDOR_DETAILS, getFollowVendorDetailsSaga);
};

export const watchUpdateFollowVendorDetailsSaga = function*() {
  yield takeLatest(actionTypes.UPDATE_FOLLOW_VENDOR_DETAILS, updateFollowVendorDetailsSaga);
};

export const watchUpdatePrimaryEmailSaga = function*() {
  yield takeLatest(actionTypes.UPDATE_PRIMARY_EMAIL, updatePrimaryEmailSaga);
}

export const watchUpdateVendorBulkSaga = function*() {
  yield takeLatest(actionTypes.UPDATE_VENDOR_BULK, updateVendorBulkSaga);
};

export const watchExportVendorReportSaga = function*() {
  yield takeLatest(actionTypes.EXPORT_VENDOR_REPORT, exportVendorReportSaga);
}

export const watchExportVendorLateUpdateReportSaga = function*() {
  yield takeLatest(actionTypes.EXPORT_VENDOR_LAST_UPDATE_REPORT, exportVendorLateUpdateReportSaga);
}

export const watchExportVendorLoginReportSaga = function*() {
  yield takeLatest(actionTypes.EXPORT_VENDOR_LOGIN_REPORT, exportVendorLoginReportSaga);
};
export const watchGetVendorHistoryListSaga = function*() {
  yield takeLatest(actionTypes.GET_VENDOR_HISTORY_LIST, getVendorHistoryListSaga);
};

export const watchGetVendorInfoListSaga = function*() {
  yield takeLatest(actionTypes.GET_VENDOR_INFO_LIST, getVendorInfoListSaga);
};

export const watchVerifyVendorSaga = function*() {
  yield takeLatest(actionTypes.VERIFY_VENDOR, getVerifyVendorSaga);
};

export const watchGetMyStoreDetailsSaga = function*() {
  yield takeLatest(actionTypes.GET_MY_STORE_DETAILS, getMyStoreDetailsSaga);
};

export const watchRemoveVendorStoreSaga = function*() {
  yield takeLatest(actionTypes.GET_REMOVE_VENDOR_STORE, removeVendorStoreSaga);
};


export const watchGetVendorStoreListSaga = function*(){
  yield takeLatest(actionTypes.GET_STORE_HISTORY_LIST,getVendorStoreListSaga);
}