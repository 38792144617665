import { applyMiddleware, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import reduxSaga from "redux-saga";
import { rootReducer } from "../reducers";
import rootSaga from "../sagas";

export const getStore = () => {
    // console.log("1 getStore");
    const initialState = {};
    const reduxSagaMiddleware = reduxSaga();
    const logger = createLogger();
    let middleWare = [];
    if(process.env.REACT_APP_ENV=="dev"){
        middleWare = [reduxSagaMiddleware, logger];
    }else{
        middleWare = [reduxSagaMiddleware];
    }
    
    const composedEnhancers = compose(applyMiddleware(...middleWare));
    const store = createStore(rootReducer, initialState, composedEnhancers);
    reduxSagaMiddleware.run(rootSaga);
    return store;
};
