/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm, Controller, set } from "react-hook-form";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import getBase64 from '../../../utils/getbase64';
import { UPDATE_PUBLIC } from "../../../redux/actionTypes/vendordetails";
import {PUT_USER} from "../../../redux/actionTypes/registration";
import { maskMobile, convertUTCDateToLocalDate, popUpAlert, isNumber } from "../../../utils/globalMethods";
import {  getVendorInfoList } from "../../../redux/actionTypes/vendordetails";
import CommonLoading from "../../common/components/commonLoading";

const StyledImageUpload = styled.div`
height: inherit;
width: inherit;
`;

const StyledImage = styled.img`
height: 185px;
object-fit: contain;
width: 200px;
`;

const PublicDetailsTab = (props) => {

    let formInitState = {
        publicEmail: "",
        publicMobile: "",
        publicWebsite: "",
        products: "",
        businessDesc: "",
        publicPhoto: "",
    };

    const [publicDetails, setPublicDetails] = useState(formInitState);

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({ defaultValues: publicDetails });

    const [mode, setMode] = useState("view");
    const [selectedImage, setSelectedImage] = useState({ file: null, base64URL: "" });
    const [update, setUpdate] = useState(false);
    const [publicMobile, setPublicMobile] = useState("");
    const [loading, setLoading] = useState(props.isLoading);
    const [previewImage, setPreviewImage] = useState(null);
    const [isValidImage, setIsValidImage] = useState(true);
    const [byteLength, setByteLength] = useState(0);
    const [initialDescValue, setInitialDescValue] = useState("")

    const formattedNumber = (value) =>{
        const formattedValue = value.replace(
            /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
            '($1) $2-$3'
          );
          return formattedValue;

    }

    const phoneNumber  = (e) => {
        const getValue = e.target.value;
        const formattedValue = formattedNumber(getValue);
        setPublicMobile(formattedValue);
      };
      useEffect(() => {
        const initialByteLength = new Blob([initialDescValue]).size;
        setByteLength(initialByteLength);
      }, [initialDescValue]);

    useEffect(() => {
        if (props.vendorInfo) {
            const formData = props.vendorInfo;
            props.vendorInfo.createTs = props.vendorInfo.createTs ? convertUTCDateToLocalDate(props.vendorInfo.createTs) : ""
            props.vendorInfo.lastUpdtTs = props.vendorInfo.lastUpdtTs ? convertUTCDateToLocalDate(props.vendorInfo.lastUpdtTs) : ""
            if (typeof formData.fnsNum === "string") {
                const format = formData.fnsNum.split(",").map((item, i) => ({id:i,name: item }));
                formData.fnsNum = format;
            }
            setTimeout(() => {
                if (formData?.publicMobile) {
                    setPublicMobile(formattedNumber(formData?.publicMobile));
                }
                reset(props.vendorInfo);
            }, 1000);
            props.vendorInfo.lastUpdtUid = props.vendorInfo.lastUpdtUidNam;
            setPublicDetails(props.vendorInfo);
            setPreviewImage(props.vendorInfo.publicPhoto)
            setInitialDescValue(formData.businessDesc)

        }
    }, [props.vendorInfo]);


    useEffect(() =>{
        setLoading(false)
    },[props.user])

    const onFormSubmit = (data) => {
        if (selectedImage.base64URL != "" && !isValidImage) {
            return false;
        }
        setLoading(true);
        if (data.fnsNum && data.fnsNum.length > 0) {
            data.fnsNum = data.fnsNum.map(item => item.name).toString();
            data.id = data.vendorId;
        }

        data.publicPhoto = selectedImage.base64URL ? selectedImage.base64URL : "";
        let updatedInfo = {
            firstName: data.firstName,
            lastName: data.lastName,
            fnsNum: data.fnsNum,
            vendorName: data.vendorName,
            primaryEmail: data.primaryEmail,
            primaryMobile: data?.primaryMobile,
            publicEmail: data.publicEmail.toLowerCase().trim(),
            publicMobile: data.publicMobile ? data.publicMobile : "",
            publicWebsite: data.publicWebsite.trim(),
            csaText: data.csaText,
            publicPhoto: previewImage,
            csaPickupSite: data.csaPickupSite ? data.csaPickupSite: "N",
            id: data.vendorId,
            csaSnapFlag: data.csaSnapFlag,
            additionalContactInfo: data.additionalContactInfo,
            products: data.products,
            businessDesc: data.businessDesc,
            lastUpdateTime: data.lastUpdateTime,
            userId: props.userId,
            submitForm: "Public",
            type: "edit"
        }
        if (update) {
            props.updateUser(updatedInfo);
            setPublicDetails(updatedInfo);
            setUpdate(false);
            setMode("view");
            setSelectedImage({ file: null, base64URL: "" });
            setIsValidImage(true);
        }
    };

    const handleFileInputChange = useCallback((e) => {
        setLoading(true);
        let file = e.target.files[0];
        getBase64(file)
            .then((result) => {
                file["base64"] = result;
                setPreviewImage(result);
                setSelectedImage({
                    base64URL: result ? result : "",
                    file
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);

            });
    }, []);

    const handleRemoveImage = () => {
        setSelectedImage({ file: null, base64URL: "" });
        setPreviewImage("");
        setIsValidImage(true);
        document.getElementById("vendorPhotoFile").value = "";
    };
    const handleInputChange = (e) => {
        const text = e.target.value;
        const byteLength = new Blob([text]).size;
        setByteLength(byteLength);
        if (byteLength > 3000) {
            e.preventDefault()            
        }
    };

   
     
    return (
        <div style={{ padding: "20px 10px", border: "1px solid #ccc" }}>
            {loading &&
                <CommonLoading />
            }
            <Form onSubmit={handleSubmit(onFormSubmit)}>
                <Row>
                    <Col md={6} xs={12} sm={6} xl={6} lg={6}>
                        <h3 className="resText"> Public Info</h3>
                    </Col>
                    {mode === "view" ? <Col className="txt-right">
                            <div onClick={() => setMode("edit")} className="btn btn-primary">
                                <span ><i className="fa fa-pencil"></i> Edit</span>
                            </div>
                        </Col> :
                        <Col md={6} xs={12} sm={6} xl={6} lg={6} className="txt-right">
                            <Button type="submit"
                                    onClick={() => setUpdate(true)}
                                    className="btn btn-primary mb-2 m-r-5">
                                <span ><i className="fa fa-check"></i> Update</span>
                            </Button>
                            <Button onClick={() => {
                                    setMode("view")
                                    if (props?.vendorInfo?.publicMobile) {
                                        setPublicMobile(formattedNumber(props?.vendorInfo?.publicMobile));
                                    }
                                    reset(props.vendorInfo);
                                    setPreviewImage(props.vendorInfo.publicPhoto)

                                }} 
                                className="btn btn-primary mb-2">
                                <span ><i className="fa fa-times"></i> Cancel</span>
                            </Button>
                        </Col>
                    }
                </Row>
                <br />
                <Row>
                    <Col md={4} sm={4} xl={4} lg={4}>
                        <Form.Group controlId="publicEmail">
                            <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">Public Email Address</Form.Label>
                            <Controller
                                render={({ field }) =>
                                    <Form.Control className={`form-height form-control-lg lowercase ${errors.publicEmail ? "dh-error-box" : ""
                                }`} type="text" {...field}   
                                     {...register('publicEmail', {
                                        pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: 'Invalid Email Format',
                                        },
                                        // validate: validateEmail,
                                      })}
                                     />
                                }
                                name="publicEmail"
                                control={control}
                                disabled={mode === "view"}
                            />
                             {errors.publicEmail && <span className="dh-txt-danger">{errors.publicEmail.message}</span>}
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={4} xl={4} lg={4}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="PublicMobile"
                            >
                                Public Phone
                            </label>
                            <input
                                {...register("publicMobile", {
                                    pattern: {
                                        value:
                                            /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: "Invalid phone number",
                                    },
                                })}
                                type="tel"
                                onKeyDown={isNumber}
                                maxLength={'14'}
                                className={`form-control form-control-lg required dh-input-box ${errors.publicMobile ? "dh-error-box" : ""
                                }`}
                                // value={publicMobile ? publicMobile : maskMobile(publicDetails?.publicMobile)}
                                value={publicMobile}
                                // onChange={(e) =>
                                //     setPublicMobile(maskMobile(e.target.value))
                                // }
                                onChange={phoneNumber}
                                placeholder="(___) ___-____"
                                id="PublicMobile"
                                aria-label="PublicMobile"
                                aria-labelledby="PublicMobile"
                                aria-describedby="PublicMobile"
                                required=""
                                // im-insert="true"
                                disabled={mode === "view"}
                            />
                            {errors.publicMobile && (
                                <span className="dh-txt-danger">
                                    {errors.publicMobile.message}
                                </span>
                            )}
                        </div>
                    </Col>
                    <Col md={4} sm={4} xl={4} lg={4}>
                        <Form.Group controlId="publicWebsite">
                            <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">Public Website</Form.Label>
                            <Controller
                                render={({ field }) =>
                                    <Form.Control className="form-height form-control-lg"  type="text" {...field} />
                                }
                                name="publicWebsite"
                                control={control}
                                disabled={mode === "view"} 
                            />
                        </Form.Group>
                    </Col>

                </Row>
                <Row>
                    <Col md={8} sm={8} xl={8} lg={8}>
                        <Row>
                            <Col>
                                <Form.Group controlId="products">
                                    <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">Products</Form.Label>
                                    <Controller
                                        render={({ field }) =>
                                            <Form.Control className="form-control-lg"  as="textarea"  {...field} rows={4} />
                                        }
                                        name="products"
                                        control={control}
                                        disabled={mode === "view"}
                                        {...register("products", {
                                            maxLength: {
                                                value: 3000,
                                                message: "Maximum character count exceeded.",
                                              },
                                              
                                        })}
                                    />
                                </Form.Group>
                                <span className="float-right  text-muted mt-1 "><i className="fa fa-info-circle mr-1" aria-hidden="true"></i>Max character : 3000</span>
                                {errors.products && (
                                    <span className="dh-txt-danger">
                                    {errors.products.message}
                                    </span>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="businessDesc">
                                    <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">Business Description</Form.Label>
                                    <Controller
                                        render={({ field }) =>
                                            <Form.Control 
                                            className="form-control-lg" 
                                            {...field} 
                                            as="textarea" 
                                            rows={4}  
                                            value={field.value || ""}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChange(e);
                                              }}
                                            />
                                        }
                                        name="businessDesc"
                                        control={control}
                                        disabled={mode === "view"}
                                        {...register("businessDesc", {
                                            maxLength: {
                                                value: 3000,
                                                message: "Maximum character count exceeded.",
                                              },
                                        })}
                                    />
                                </Form.Group>
                                {/* <div>{`Current Byte Length: ${byteLength}`}</div> */}
                                <span className="float-right text-muted mt-1"><i className="fa fa-info-circle mr-1" aria-hidden="true"></i>Max character : 3000</span>
                                {errors.businessDesc && (
                                <span className="dh-txt-danger">
                                {errors.businessDesc.message}
                                </span>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} sm={4} xl={4} lg={4}>
                        <Row>
                            <Col>
                                <Form.Group controlId="publicPhoto">
                                    <Form.Label className="font-14 mb-2 dh-title-lb smallTitle text-brand">Vendor Photo</Form.Label>
                                    <div
                                        id="edit_publicPhoto_box"
                                        className="dh-photo-cover"
                                        style={{ position: 'relative' }}
                                        >
                                        <div
                                            className="dh-store-photo"
                                            id="edit_publicPhoto"
                                        style={{ position: 'relative' }}

                                        >
                                            {previewImage  ?
                                                <StyledImageUpload>
                                                <StyledImage alt="image not found" src={previewImage} />
                                                {mode === "edit" && (
                                                        <>
                                                            <Button title={"Remove"} style={{position:"absolute",top:"0",right:"0",borderRadius:"3px"}} variant="danger" onClick={handleRemoveImage}>
                                                                <i class="fa fa-trash-o"></i> 
                                                            </Button>
                                                        </>
                                                )}
                                            </StyledImageUpload>
                                                :
                                                <div style={{ padding: "15px" }}>
                                                    <h1>
                                                        <i className="fa fa-picture-o fa-3"></i>
                                                    </h1>
                                                    <div className="smallTitle">
                                                        Not Available
                                                    </div>
                                                    <div>Click 'Edit' then 'Choose File' to upload vendor photo</div>
                                                </div>
                                            }
                                        </div>
                                        {mode === "edit" ? <div className="form-group ">
                                        <input
                                                        {...register("publicPhoto", {
                                                            required: false,
                                                            validate: {
                                                              lessThan3MB: (files) => {
                                                                if (selectedImage?.base64URL) {
                                                                    setIsValidImage(files.length == 0 || files[0]?.size/1024 <= 3072 ? false : true);
                                                                    return files.length == 0 || files[0]?.size/1024 <= 3072 || "File should be less than 3MB";
                                                                } else {
                                                                    setIsValidImage(true);
                                                                    return true;
                                                                }
                                                            },
                                                              acceptedFormats: (files) =>{
                                                                if (selectedImage?.base64URL) {
                                                                    return files.length == 0 || ["image/jpeg", "image/png", "image/gif"].includes(
                                                                              files[0]?.type
                                                                            ) || "File format should be PNG, JPEG or GIF";
                                                                } else {
                                                                    return true;
                                                                }
                                                            }}
                                                          })}
                                                        type="file"
                                                        accept="image/*"
                                                        maxLength="100"
                                                        id="vendorPhotoFile"
                                                        className="form-control required uploadImage-style form-control-lg"
                                                        placeholder="Upload Photo"
                                                        onChange={(e) => { handleFileInputChange(e); errors.publicPhoto = "";  }}
                                                    />
                                                    {errors.publicPhoto && (
                                                        <span className="dh-txt-danger">
                                                            {errors.publicPhoto.message}
                                                        </span>
                                                    )}
                                        </div> : null
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>

    );
};

PublicDetailsTab.propTypes = {
    updatePublicDetails: PropTypes.func.isRequired,
    vendorId: PropTypes.string,
    updateUser: PropTypes.func.isRequired,
    userId: PropTypes.number,
    vendorDetails: PropTypes.object,
    getVendorInfoList: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => {
    return {
        updatePublicDetails: (user) => {
            console.log("dispatch update", user);
            dispatch({ type: UPDATE_PUBLIC, value: user });
        },
        updateUser: (user) => {
            dispatch({ type: PUT_USER, value: user });
        },
        getVendorInfoList:(payload) => dispatch(getVendorInfoList(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicDetailsTab);
