import React, { useState } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { Grid } from '@mui/material';
import { getFollowVendorDetails  } from '../../redux/actionTypes/vendordetails';
import TableComponent from "../common/components/table";
import '../../styles/home.scss';
import Typography from "@mui/material/Typography";

const AlertDetailsComponent = (props) => {
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
      
  const handleNext = (page, rowSize) => {
    (size != rowSize) && setSize(rowSize);
    setFrom(rowSize * page);
  }

  const followColumns = [
    {
      id: 'vendorName', label: 'Vendor Name' 
    },
    {
      id: 'primaryEmail', label: 'Primary Email' 
    },
    {
      id: 'fnsNum', label: 'FNS Number' 
    },
    {
      id: 'isVerified', label: 'Verified' 
    }
  ];

return(
    <Grid container className="container-block" rowSpacing={2}>
    <Grid item xs={12}>
          <Typography mb={2} lineHeight="lg">
            Total Vendor(s) <b> {props.followVendorListTotal}</b>
        </Typography>
        </Grid>
<Grid item xs={12}>
      <TableComponent 
        columns={followColumns}
        rows = {props.followRows}
        handlePagination={handleNext}
        total={props.followVendorListTotal}
        page={Math.floor(from/size)}
        rowsPerPage={size}
      />
    </Grid>
    </Grid>

)

}


AlertDetailsComponent.propTypes = {
    getFollowVendorDetails: PropTypes.func.isRequired,
    vendorDetails:PropTypes.object,
    followRows:PropTypes.object,
    followVendorListTotal:PropTypes.object,
};
  
  function mapDispatchToProps(dispatch) {
    return {
      getFollowVendorDetails:(payload) => dispatch(getFollowVendorDetails(payload)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AlertDetailsComponent);