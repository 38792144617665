import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StraightIcon from '@mui/icons-material/Straight';
import '../../../styles/home.scss';
import TableLoading from './tableLoading';

  
  function Row(props) {

    const { row, showDetails, renderDetails, columns, onCheckedHandle, selectedRows} = props;

    const [open, setOpen] = React.useState(props?.expand);

    const isItemSelected = (rowId) => {
      return selectedRows.some(row => (row.id === rowId));
    };

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {columns.map((item) => {
            if(item.id=="checkbox"){
              return <TableCell padding="checkbox" key={item.id}>
                <Checkbox
                  color="primary"
                  checked={isItemSelected(row.id)}
                  onChange={(e) => {onCheckedHandle(e, row)}}
                  disabled={(row.isVerified === "N" ? true :false)}
                  inputProps={{
                    'aria-label': "Vendor Verified",
                  }}
                />
              </TableCell>
            }else if(item.id=="details"){
              return <TableCell key={item.id}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                    }
                  }
                >
                  {open ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                </IconButton>
              </TableCell>
            }else{
              return <TableCell component="td" scope="row" key={item.id}>
              {item.cell ? item.cell(row) : row[item.id]}
              </TableCell>
            }
          })}
        </TableRow>
        {showDetails && <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
              {showDetails && renderDetails(row)}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>}
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.object.isRequired,
    showDetails: PropTypes.bool,
    renderDetails: PropTypes.func,
    columns:PropTypes.array,
    checked: PropTypes.bool,
    onCheckedHandle: PropTypes.func,
    selectedRows: PropTypes.array,
    expand : PropTypes.bool
  };

const TableComponent = (props) => {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {page, rowsPerPage} = props;
  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    props.handlePagination(newPage,rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    // setPage(0);
    props.handlePagination(0,+event.target.value)
  };

    return (
      <>
        <TableContainer component={Paper}>
          <Table sx={{'& > thead > tr > th, & > tbody > tr > td':
                    { border: '1px solid #dee2e6'}}} aria-label="collapsible table">
            <TableHead >
              <TableRow  >
                {props.columns.map((item) => {
                  if(item.id=="checkbox"){
                    return <TableCell padding="checkbox" key={item.id} >
                      <Checkbox
                      color="primary"
                      indeterminate={props.selectedRows.length > 0 && props.selectedRows.length < rowsPerPage}
                      checked={props.selectedRows.length == props.rows.length}
                      onChange={props.handleSelectAll}
                      inputProps={{
                        'aria-label': 'Select All',
                      }}
                    />
                  </TableCell>
                  }else{
                    return ("sort" in item ? <TableCell  key={item.id} sx={{cursor: "pointer"}} style={{ fontWeight: 'bold' }} onClick={() => props.handleSort(item)} display={'block'}>
                            <Typography variant="span" style={{ minWidth: "65%",maxWidth:'78%',float:"left"}}> {/** className={ item.sort === "DESC"? "sorting_desc" : "sorting_asc"} */}
                              {item.label}
                              </Typography>
                            <Typography style={{float:"right"}}><StraightIcon sx={{float:"right", opacity:item.sort === "DESC" ? "0.7" : "0.3",rotate: "180deg",marginLeft: "-16px"}} />
                            <StraightIcon sx={{float:"right", opacity:item.sort === "ASC" ? "0.7" : "0.3"}} /></Typography>
                          </TableCell> :
                          <TableCell key={item.id} style={{ fontWeight: 'bold' }} >
                            {item.label}
                          </TableCell>)
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.dataLoading && 
                <TableLoading />
              }
              {(!props.dataLoading && props.rows.length===0) && 
                <TableRow>
                  <TableCell colSpan={props.columns.length}>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                      {"No data found!"}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
              {!props.dataLoading && props.rows.map((row) => (
                <Row 
                  key={row.id} 
                  row={row}
                  expand={props.expand}
                  showDetails = {props.showDetails}
                  renderDetails = {props.renderDetails}
                  columns={props.columns}
                  checked = {props.selectAll}
                  onCheckedHandle = {props.handleRowClick}
                  selectedRows = {props.selectedRows}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={props.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={"paginationBlock"}
        />
      </>
      );
}

TableComponent.propTypes = {
  columns: PropTypes.array.isRequired ,
  rows: PropTypes.array.isRequired,
  showDetails: PropTypes.bool,
  renderDetails: PropTypes.func,
  handleSort: PropTypes.func,
  handlePagination: PropTypes.func,
  total: PropTypes.number,
  selectAll:PropTypes.bool,
  handleSelectAll: PropTypes.func,
  handleRowClick: PropTypes.func,
  selectedRows: PropTypes.array,
  expand: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  dataLoading: PropTypes.bool
};

export default TableComponent;