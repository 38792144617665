import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from "prop-types";

const StyledButton = ({ onClick, variant, text,label, isIcon, faIcon, direction }) => {
    let styledDropdown = {
        fontSize: '20px',
        padding: '4px 9px',
        border: '1px solid #006e52',
        margin: '0 5px',
        fontFamily: 'Texta-bold',
        boxShadow: 'none',
        background: variant === "primary" ? '#006e52' : (variant === "outline" ? '#fff' : '#006e52'),
        color: variant === "primary" ? '#fff' : (variant === "outline" ? '#495057' : '#fff'),
    };
    return (
        <Button onClick={onClick} className={variant === "primary" ? "button-main" : (variant === "outline") ? 'button-outline' : 'button-main'}
            variant='contained'
            style={styledDropdown}
            sx={{ textTransform: 'capitalize' }}
            aria-label={label}
            >
            {direction ? (
                direction === 'right' ? (
                    <>
                        {text} &nbsp; {isIcon && <small className={`mt-1 ${faIcon}`}></small>}
                    </>
                ) : (
                    <>
                        {isIcon && <small className={faIcon}></small>} &nbsp; {text}
                    </>
                )
            ) : (
                text
            )}
        </Button>
    );
};

StyledButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(["primary", "outline"]),
    text: PropTypes.string,
    isIcon: PropTypes.string.isRequired,
    faIcon: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(["left", "right"]),
    label : PropTypes.string,

};

export default StyledButton;
