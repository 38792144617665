import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

const ForgotPwdComponent = ({ shown, close }) => {
  return (
    <div>
      <Modal
        show={shown}
        onHide={() => close()}
        backdrop="static"
        keyboard={false}
        animation={1}
        id={"forgotModal"}
      >
        <Modal.Header closeButton>
          <h4 className="modal-title modalTitle resText">Reset Password</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBody">
            <form>
              <div className="modal-body">
                <div className="row">
                  <div
                    className="col-md-12 errorMsg mb-2"
                    style={{ fontSize: "19px" }}
                  ></div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="inputLabel">
                        What is your email address? <small>*</small>
                      </label>
                      <input
                        className="form-control form-control-lg lowercase"
                        placeholder=""
                        type="email"
                        id="username"
                        required=""
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <button
                      type="submit"
                      className="boldFont btn btn-block btnModal btnSubmit btn-primary btn-lg"
                    >
                      Submit <i className="fa fa-arrow-right"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ForgotPwdComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
};

export default ForgotPwdComponent;
