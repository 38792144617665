import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Fade, Button, Paper, Popper, Typography } from '@mui/material';
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css' 
import 'react-date-range/dist/theme/default.css'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { formatDTADateForPicker } from '../../../utils/globalMethods';

const DatePickerComponent = ({selectedDateObj, label='Select Date'}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate:  endOfDay(new Date())
    },
  ])

const [selectedDateLabel, setSelectedDateLabel] = useState(label);
const [displayLable, setDisplayLabel] = useState("-");
const [anchorEl, setAnchorEl] = useState(null);
const [dateOpen, setDateOpen] = useState(false);
const [placement, setPlacement] = useState();


const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setDateOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
};

  useEffect(() => {
    const Today = moment(new Date()).format('MM-DD-YYYY');
    const Yesterday = moment(addDays(new Date(), -1)).format('MM-DD-YYYY');
    const selectedDates = selectedDateLabel.split(' - ');
    if(selectedDates[0] === selectedDates[1] && selectedDates[1] === Today){
      setDisplayLabel("Today: "+formatDTADateForPicker(selectedDates[0]))
    }else if(selectedDates[0] === selectedDates[1] && selectedDates[1] === Yesterday){
      setDisplayLabel("Yesterday: "+formatDTADateForPicker(selectedDates[0]))
    }else{
      setDisplayLabel(formatDTADateForPicker(selectedDates[0])+" - "+formatDTADateForPicker(selectedDates[1]))
    }
  }, [selectedDateLabel]);
  


  return (
    <>
      <Button
        className='datepicker-label'
        sx= { {
          textTransform: "capitalize",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          color: "#000",
          padding: "8px"
      } }
        color="primary"
        onClick={handleClick('bottom')}
        startIcon={<CalendarMonthIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        {displayLable}        
      </Button>
      <Popper
        style={{ zIndex: '9999' }}
        open={dateOpen}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography  component="span" sx={{ border: '1px solid #ccc' }}>
                <div className='datepicker-wrapper'>
                  <div className="datepicker">
                    <DateRangePicker
                      onChange={(item) => {
                        setState([item.range1])
                      }}
                      rangeColors={["#002a57"]}
                      showSelectionPreview
                      moveRangeOnFirstSelection={false}
                      showDateDisplay={false}
                      months={2}
                      color="f6be00"
                      retainEndDateOnFirstSelection
                      ranges={state}
                      direction={
                        window.innerWidth > 796 ? 'horizontal' : 'vertical'
                      }
                      
                      staticRanges={createStaticRanges([
                        {
                          label: 'Today',
                          range: () => ({
                            startDate: startOfDay(new Date()),
                            endDate: endOfDay(new Date()),
                          }),
                        },
                        {
                          label: 'Yesterday',
                          range: () => ({
                            startDate: startOfDay(addDays(new Date(), -1)),
                            endDate: endOfDay(addDays(new Date(), -1)),
                          }),
                        },
                        {
                          label: 'Last 7 Days',
                          range: () => ({
                            startDate: startOfDay(addDays(new Date(), -7)),
                            endDate: endOfDay(new Date()),
                          }),
                        },
                        {
                          label: 'Last 30 Days',
                          range: () => ({
                            startDate: startOfDay(addDays(new Date(), -30)),
                            endDate: endOfDay(new Date()),
                          }),
                        },
                        {
                          label: 'Custom Ranges',
                          range: () => ({
                            startDate: startOfDay(addDays(new Date(), -30)),
                            endDate: endOfDay(new Date()),
                          }),
                        },
                      ])}
                      inputRanges={[]}
                    />
                    <br />
                    <Box
                      sx={{
                        display: 'flex',
                        width: '98%',
                        flexDirection: 'row',
                        alignItems: 'right',
                        justifyContent: 'right',
                        m: 1,
                        '& .css-iajp3t-MuiButtonGroup-root .MuiButtonGroup-grouped':
                          {
                            minWidth: '80px',
                          },
                      }}
                    >
                        <Button onClick={() => {
                          setDateOpen(false)
                        }}>Cancel</Button>
                    
                      &nbsp;&nbsp;
                      
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDateOpen(false)
                          selectedDateObj(state)
                          if (state && state[0]?.startDate && state[0]?.endDate) {
                            const d1 = moment(state[0].startDate).format(
                              'MM-DD-YYYY'
                            )
                            const d2 = moment(state[0].endDate).format(
                              'MM-DD-YYYY'
                            )
                            setSelectedDateLabel(`${d1} - ${d2}`)
                          } else {
                            setSelectedDateLabel('Select Date')
                          }
                        }}
                      >
                        OK
                        </Button>
                    
                    </Box>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

DatePickerComponent.propTypes = {
    selectedDateObj: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
}

export default DatePickerComponent;