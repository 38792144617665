
import * as React from 'react';
import spin_loader from "../../../assets/images/spin-loader.svg";
import PropTypes from "prop-types";
import { mapStateToProps } from '../../../redux/reducers';
import { connect } from 'react-redux';

function CommonLoading(props) {
  return (
    <div className="fill-window">
          <div className="alert alert-info">
              <div className="loading">
                  <img src={spin_loader} alt="spin-loader"/>{" "}
              </div>
              <div className="loading-process">
              &nbsp;{props.language["Processing"]}...
              </div>
          </div>
        </div>
  );
}

CommonLoading.propTypes = {
  language: PropTypes.object
}

export default connect(
  mapStateToProps
)(CommonLoading);
