export const actionTypes = {
    GET_LANGUAGE_FULFILLED : "GET_LANGUAGE_FULFILLED",
    UPDATE_SELECTED_LANGUAGE : "UPDATE_SELECTED_LANGUAGE",
    GET_LANGUAGE_REQUESTED : "GET_LANGUAGE_REQUESTED",
    GET_LANGUAGE_FAILED : "GET_LANGUAGE_FAILED",
    SET_LANGUAGE_DATA : "SET_LANGUAGE_DATA"
  };
  
  export  function selectedLanguageAction(SelectedLanguage) {
      return {
        type: actionTypes.UPDATE_SELECTED_LANGUAGE,
        payload: SelectedLanguage
      };
  }