import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { getStore } from "./redux/store/Store";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from 'react-cookie';

// azure integration
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./appConfig";

// azure instance initialization
const msalInstance = new PublicClientApplication(msalConfig);

const store = getStore();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<CookiesProvider>
                <Provider store={store}>
                    <MsalProvider instance={msalInstance}>
                        <App  />
                    </MsalProvider>
                </Provider>
            </CookiesProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
