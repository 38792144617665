import React from "react";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import {Row, Col} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import '../../styles/home.scss';

const VerifyVendorComponent = (props) => {
    const {verifyVendorSubmit} = props;

    const { register, handleSubmit, formState: { errors }} = useForm();

    const onSubmit = data => {
        if(Object.keys(errors).length === 0){
            verifyVendorSubmit(data);
        } 
    };
    
    return (
        <div className="form-block">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col>
                    {Object.keys(errors).length > 0 && <Form.Text className="dh-txt-danger">
                        <b><i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {errors[Object.keys(errors)[0]].message} </b>
                    </Form.Text>}
                        <Form.Group as={Col} controlId="formStaffName">
                            <Form.Label>Staff Name *</Form.Label>
                            <Form.Control type="text" size="lg" {...register("staffName", { required: "Please enter staff Name" })} />
                            <Form.Control.Feedback type="invalid">
                                Please enter staff Name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formDescription">
                            <Form.Label>Description *</Form.Label>
                            <Form.Control
                                {...register("vendorVerfNotes", { required: "Please enter Description" })}
                                as="textarea"
                                placeholder=""
                                style={{ height: '114px' }}
                                />
                            <Form.Text id="formDescription" muted>
                                <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> Note: Vendor will be shown in the map
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="modal-footer">
                    <Col>
                        <Button variant="primary" type="submit" className="float-right">
                            Verify <i className="fa fa-arrow-right"></i>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

VerifyVendorComponent.propTypes = {
    verifyVendorSubmit: PropTypes.func,
};


export default VerifyVendorComponent;
