import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { getVendorHistoryDetail } from '../../redux/actionTypes/vendordetails';
import { convertUTCDateToLocalDate, formatPhoneNumber } from "../../utils/globalMethods";
import CommonLoading from "../common/components/commonLoading";
  
const theme = createTheme({
    typography: {
      h6: {
        fontFamily: "Texta-bold",
      },
      subtitle1: {
        fontFamily: ["Texta-book", "Helvetica", "Arial", "sans-serif"].join(','),
        fontSize: 20,
      },
    },
  });

const VendorHistoryDetail = (props) => { 
    const [isLoading, setIsLoading] = useState();
    useEffect(() => {
        const payload = {
            "role" : "ADMIN",
            "vendorId" : props?.row?.id,
            // "vendorType": props?.vendorType,
            "createTs":props?.row?.createTime
        };
        props.getVendorHistoryDetail(payload);
    }, [props?.row?.id,props.vendorDetails.refreshKey]);

    useEffect(() => {
        // const { loading } =  props.vendorDetails.vendorHistoryDetail;
        if(props.vendorDetails?.vendorHistoryDetail) {
            props.setVendorAddtInfo((prevState) => ({
                ...prevState,
                [props.vendorDetails?.vendorHistoryDetail?.id] :props?.vendorDetails?.vendorHistoryDetail}));
        }
    }, [props.vendorDetails.vendorHistoryDetail]);

    return (
        <Grid container className="collapsibleTable" spacing={2}>
             {/* {isLoading && <CommonLoading />} */}
             <ThemeProvider theme={theme}>
            <Grid item xs={10}>
                <Typography variant="h5" display="inline" className="resText text-capitalize" gutterBottom component="div">
                    Vendor Info for DTA Communications
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h6" gutterBottom component="div">
                    First Name
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {props.vendorAddtInfo[props?.row?.id]?.firstName ? props.vendorAddtInfo[props?.row?.id]?.firstName : '-'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h6" gutterBottom component="div">
                    Last Name
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {props.vendorAddtInfo[props?.row?.id]?.lastName ? props.vendorAddtInfo[props?.row?.id]?.lastName : '-'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h6" gutterBottom component="div">
                    Email Address
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {props.vendorAddtInfo[props?.row?.id]?.primaryEmail}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h6" gutterBottom component="div">
                    Mobile#
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {props.vendorAddtInfo[props?.row?.id]?.primaryMobile ? props.vendorAddtInfo[props?.row?.id]?.primaryMobile : '-'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h6" gutterBottom component="div">
                    Registered Date
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {props.vendorAddtInfo[props?.row?.id]?.createTs ? convertUTCDateToLocalDate(props.vendorAddtInfo[props?.row?.id]?.createTs) : '-'}
                </Typography>
            </Grid>
            {props?.showVendorHistoryDetails &&
            <Grid item xs={3}>
            <Typography variant="h6" gutterBottom component="div">
                Verified Date
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {props.vendorAddtInfo[props?.row?.id]?.verifiedTs ? convertUTCDateToLocalDate(props.vendorAddtInfo[props?.row?.id]?.verifiedTs) : '-'}
            </Typography>
            </Grid>
            }
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">
                    Additional Contact Info
                 </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {props.vendorAddtInfo[props?.row?.id]?.additionalContactInfo ? props.vendorAddtInfo[props?.row?.id]?.additionalContactInfo : "-"}
                </Typography>
            </Grid>
                <Grid item xs={10}>
                    <Typography variant="h5" display="inline" className="resText" gutterBottom component="div">
                        Vendor Info for Public display
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom component="div">
                        Email
                        </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {props?.vendorAddtInfo?.[props?.row?.id]?.publicEmail ? props?.vendorAddtInfo[props?.row?.id]?.publicEmail : '-'}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom component="div">
                        Mobile#
                        </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {props?.vendorAddtInfo?.[props?.row?.id]?.publicMobile ? props?.vendorAddtInfo[props?.row?.id]?.publicMobile : '-'}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom component="div">
                        Website
                        </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {props?.vendorAddtInfo[props?.row?.id]?.publicWebsite ? props?.vendorAddtInfo[props?.row?.id]?.publicWebsite : '-'}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom component="div">
                        Products
                        </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {props.vendorAddtInfo[props?.row?.id]?.products ? props.vendorAddtInfo[props?.row?.id]?.products : '-'}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom component="div">
                        Description
                        </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {props.vendorAddtInfo[props?.row?.id]?.businessDesc ? props.vendorAddtInfo[props?.row?.id]?.businessDesc : '-'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div">
                        Photo
                        </Typography>
                    <Typography variant="subtitle1" gutterBottom component="div" nowrap={true}>
                      { props.vendorAddtInfo[props?.row?.id]?.publicPhoto && (<img src={props.vendorAddtInfo[props?.row?.id]?.publicPhoto} className="img-thumbnail" />)}  
                    </Typography>
                </Grid>
            </ThemeProvider>
        </Grid>
    )
}

VendorHistoryDetail.propTypes = {
    row: PropTypes.object.isRequired,
    showVendorHistoryDetails : PropTypes.bool,
    vendorDetails: PropTypes.object,
    getVendorHistoryDetail: PropTypes.func,
    vendorAddtInfo: PropTypes.object,
    setVendorAddtInfo: PropTypes.func,
    vendorType: PropTypes.string,
    vendorDetailsArray: PropTypes.func,


};

function mapDispatchToProps(dispatch) {
    return {
        getVendorHistoryDetail:(payload) => dispatch(getVendorHistoryDetail(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorHistoryDetail);