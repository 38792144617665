export const actionTypes = {
    GET_VENDOR_STATS : "GET_VENDOR_STATS",
    GET_STORE_STATS : "GET_STORE_STATS",
    SET_VENDOR_STATS : "SET_VENDOR_STATS",
    SET_STORE_STATS : "SET_STORE_STATS",
  };
  
  export  function getVendorStats(payload) {
      return {
        type: actionTypes.GET_VENDOR_STATS,
        payload: payload
      };
  }

  export  function getStoreStats(payload) {
    return {
      type: actionTypes.GET_STORE_STATS,
      payload: payload
    };
  }