/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { Form, Table, Image } from "react-bootstrap";
// import SelectDropdown from "../../common/selectdropdown-component";
import plus from "../../../assets/images/plus.svg";
import minus from "../../../assets/images/minus.svg";
import MySelect from "./MySelect";
import Dropdown from "./Dropdown";
import Select, { components } from "react-select";
import "../../../styles/registration.scss";

const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "140px",
    height: "40px",
    whiteSpace: "nowrap"
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    // display: "none",
  }),
  option: (provided) => ({
    ...provided,
    background: "#fff",
    color: "black",
    padding: "0px 12px"
  }),
  menuList: (provided) => {
    return {
      ...provided,
      "& :first-child": {
        marginBottom: "12px",
        borderBottom: "1px solid rgba(0,0,0,.1)"
      }
    };
  }
};

const Option = (props) => {
  return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.label === "Select all" ? props.selectProps.selectAllOption : props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
  );
};

Option.propTypes = {
  isSelected: PropTypes.any,
  label: PropTypes.any,
};

const SignupNestedFieldArray = ({
  nestIndex,
  id,
  days,
  months,
  question,
  control,
  register,
  getValues,
  programData,
  setValue,
  clear,
  setSpecialHourData,
  field_item,
  onEdit
//   data
}) => {

    const { fields, append, remove } = useFieldArray({
        // remove,
        control,
        // name: `program[${id}][${nestIndex}].special_hoursOfOperation`,
        name: `program[${id}][${nestIndex}].special_hoursOfOperation`,
      });

  const nodeMonths = useRef();
  const nodeDays = useRef();
  const [openMonths, setMonthsOpen] = useState(false);
  const [openDays, setDaysOpen] = useState(false);
  const [renderCount, setRenderCount] = useState(1);
  const [timeFilter, setTimeFilter] = useState();

  const [daysSelected, setDaysSelected] = useState();
  const [monthsSelected, setMonthsSelected] = useState();
  /* eslint-disable */
  const [status, setStatus] = useState();

  const checkIfClickedOutside = (e) => {
    if (
      (nodeMonths.current && nodeMonths.current.contains(e.target)) ||
      (nodeDays.current && nodeDays.current.contains(e.target))
    ) {
      return;
    }
    setDaysOpen("");
    setMonthsOpen("");
  };

  const [selectedOpenTime, setSelectedOpenTime] = useState({
    name: "12:00",
    values: "12:00",
  });
  const [selectedCloseTime, setSelectedCloseTime] = useState({
    name: "12:00",
    values: "12:00",
  });

  const [isOpenTime, setIsOpenTime] = useState();
  const [isCloseTime, setIsCloseTime] = useState();
  const [dropdownValue, setDropdownValue] = useState();

  const MultiValueContainer = ({ selectProps, data }) => {
    const values = selectProps.value;
    const selectAllOption = selectProps.selectAllOption;
        if (values) {
          if(selectAllOption){
            return data.value === values[0].value ? "Selected All":"";
          }else{
            return values[values.length - 1].value === data.value
            ? data.value
            : data.value + ", ";
          }
        } else return "";
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openMonths, openDays]);
  
  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" })
  };

  const clone = _.cloneDeep(programData);
//   let nestedFields = [{...clone[id].special_hoursOfOperation[0]}];
  useEffect(()=>{
    if (fields && fields.length) { 
        fields.map((item,i)=>{
            setValue(
                `program[${id}][${nestIndex}].special_hoursOfOperation[${i}].day`,
                item?.day
            );
            setValue(
                `program[${id}][${nestIndex}].special_hoursOfOperation[${i}].month`,
                item?.month
            );
            setDropdownValue((prevState) => ({
                ...prevState,
                [`program[${id}][${nestIndex}].special_hoursOfOperation[${i}].open_time`] : (typeof item?.open_time === "object" ? item?.open_time : {values: item?.open_time, label: item?.open_time})
              }));
            setDropdownValue((prevState)=>({
                ...prevState,
                [`program[${id}][${nestIndex}].special_hoursOfOperation[${i}].close_time`] :  (typeof item?.close_time === "object" ? item?.close_time : {values: item?.close_time, label: item?.close_time})
            }));
          
        });
    } else {
        fields.push({...clone[id].special_hoursOfOperation[0]});
    }
  },[fields]);

  return (
    <div className="of-scroll">
      <Table
        className="table border special-timings"
        style={{
          background: "#fff",
          marginBottom: "10px !important",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "15%" }}>Status</th>
            <th style={{ width: "5%" }}>Week</th>
            <th style={{ width: "17%" }}>Day(s)</th>
            <th style={{ width: "17%" }}>Month(s)</th>
            <th style={{ width: "46%" }} className="mob-header">
              Start &amp; End Time
            </th>
          </tr>
        </thead>
        <tbody>
          {fields && fields.map((item, k) => { 
            return (
              <tr key={k}>
                <td className="ws-nw">
                  <select
                    {...register(
                      `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].status`
                    )}
                    readOnly={onEdit}
                    className={
                      "s-width s-select form-control available-status drop-down-menu ml-1"
                    }
                    defaultValue={item?.name?.status }
                    onChange={(e) => {
                      setStatus((prevState) => ({
                        ...prevState,
                        [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].status`]:
                          e.target.value,
                      }));
                      setValue(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].status`,
                        e.target.value
                      );
                      setValue(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`,
                        e.target.value === "Available" ? "Custom" : "All Day"
                      );
                    //   setValue(
                    //     `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`,  {values:"12:00",label:"12:00"}
                    //   );
                    //   setValue(
                    //     `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`, {values:"12:00",label:"12:00"}
                    //   );
                      setValue(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time_meridian`,"am"
                      );
                      setValue(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time_meridian`,"pm"
                      );
                        setDropdownValue((prevState)=>({
                            ...prevState,
                            [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] :  {values:"12:00",label:"12:00"}
                        }));
                        setDropdownValue((prevState)=>({
                            ...prevState,
                            [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] :  {values:"12:00",label:"12:00"}
                        }));
                    //   item?.name?.time_filter = (e.target.value === "Available" ? "All Day" : "Custom");
                      
                      setSpecialHourData({
                        index: nestIndex,
                        data: getValues()?.program[id][nestIndex]?.name[
                          question.name
                        ],
                      });
                    }}
                  >
                    {question.specialHoursOperation[0].status.map((data, m) => (
                      <option key={`status${m}`} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="ws-nw s-time-left">
                  <select
                    {...register(
                      `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].week`
                    )}
                    readOnly={onEdit}
                    className="s-width s-select form-control drop-down-menu"
                    defaultValue={item?.name?.week ? item?.name?.week : "1"}
                    onChange={(e) => {
                      setValue(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].week`,
                        e.target.value
                      );
                      setSpecialHourData({
                        index: nestIndex,
                        data: getValues()?.program[id][nestIndex]?.special_hoursOfOperation,
                      });
                    }}
                  >
                    {question.specialHoursOperation[0].week.map((data, n) => (
                      <option key={`week${n}`} value={data.name}>
                        {data.values}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="ws-nw">
                  <div style={{ position: "relative" }}>
                    <MySelect
                      {...register(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].day`
                      )}
                      isDisabled={onEdit}
                      styles={customStyles}                     
                      options={days?.map((item) => ({
                        label: item.values,
                        value: item.values,
                        checked: item.isActive,
                        id: item.id,
                      }))}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option,MultiValueContainer }}
                      selectAllOption={getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k].day?.filter(item=>item.checked).length===days.length}
                      onChange={(selected) => {
                        const data =
                          getValues()?.program[id][nestIndex]?.special_hoursOfOperation;
                        data[k].day = selected;
                        setSpecialHourData({ index: nestIndex, data: data });
                        setValue(
                          `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].day`,
                          selected
                        );
                        setDaysSelected((prevState) => ({
                          ...prevState,
                          [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].day`]:
                            selected,
                        }));
                      }}
                      allowSelectAll={true}
                      value={
                        getValues() &&
                        getValues().program[id][nestIndex].special_hoursOfOperation[k].day ? getValues().program[id][nestIndex].special_hoursOfOperation[k].day?.filter(item=>item.checked) : days.map((item) => ({
                            label: item.values,
                            value: item.values,
                            checked: item.isActive,
                            id: item.id,
                          }))
                      }
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 39,
                        padding: "0px 3px",
                        background: "#ceeece",
                        color: "#000",
                        borderRadius: "3px",
                      }}
                    >
                      {getValues() &&
                      getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.day ?
                      getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.day?.filter(item=>item.checked).length: days.length}
                     
                    </span>
                  </div>
                </td>
                <td className="ws-nw">
                  <div style={{ position: "relative" }}>
                    <MySelect
                      {...register(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].month`
                      )}
                      isDisabled={onEdit}
                      styles={customStyles}
                      options={months?.map((item) => ({
                        label: item.values,
                        value: item.values,
                        checked: item.isActive,
                        id: item.id,
                      }))}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option,MultiValueContainer }}
                      selectAllOption={getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k].month?.filter(item=>item.checked).length===months.length}
                      onChange={(selected) => {
                        const data =
                          getValues()?.program[id][nestIndex]?.special_hoursOfOperation;
                        data[k].month = selected;
                        setSpecialHourData({ index: nestIndex, data: data });
                        setValue(
                          `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].month`,
                          selected
                        );
                        setMonthsSelected((prevState) => ({
                          ...prevState,
                          [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].month`]:
                            selected,
                        }));
                      }}
                      allowSelectAll={true}
                      value={
                        // monthsSelected[
                        //   `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].month`
                        // ]
                        getValues() &&
                        getValues().program[id][nestIndex].special_hoursOfOperation[k].month ? getValues().program[id][nestIndex].special_hoursOfOperation[k].month?.filter(item=>item.checked) : months.map((item) => ({
                            label: item.values,
                            value: item.values,
                            checked: item.isActive,
                            id: item.id,
                          }))
                      }
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 39,
                        padding: "0px 3px",
                        background: "#ceeece",
                        color: "#000",
                        borderRadius: "3px",
                      }}
                    >
                      {getValues() &&
                      getValues().program[id][nestIndex].special_hoursOfOperation[k].month ?
                      getValues().program[id][nestIndex].special_hoursOfOperation[k]?.month?.filter(item=>item.checked).length : months.length}
                    </span>
                  </div>
                </td>
                <td className="ws-nw of-scroll" >
                  <Form.Select
                    {...register(
                      `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`
                    )}
                    readOnly={onEdit}
                    className="s-width s-select form-control drop-down-menu time-filter"
                    defaultValue={
                        // getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.status?.toString() === "Available" ? "All Day" : "Custom"
                      (item?.name?.time_filter
                        ? item?.name?.time_filter
                        : "Custom"
                        )
                    }
                    //getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.status === "Available" ? "All Day" :  
                    // value = {(item?.name?.time_filter
                    //     ? item?.name?.time_filter
                    //     : "Custom"
                    //     )}

                    value = {getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ? getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter : "Custom" }
                    onChange={(e) => {                        
                      setTimeFilter((prevState) => ({
                        ...prevState,
                        [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`]:
                          e.target.value,
                      }));
                      setValue(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`,
                        e.target.value
                      );
                      setSpecialHourData({
                        index: nestIndex,
                        data: getValues()?.program[id][nestIndex]?.special_hoursOfOperation,
                      });
                    }}
                  >
                    {question.specialHoursOperation[0].time_filter.map(
                      (data, l) => (
                        <option key={`time_filter_${l}`} value={data}>
                          {data}
                        </option>
                      )
                    )}
                  </Form.Select>
                  &nbsp;
                  <Dropdown
                    isOpen={isOpenTime && isOpenTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`]}
                    onClose={()=>{
                        setIsOpenTime((prevState)=>({
                            ...prevState,
                            [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`]: isOpenTime && isOpenTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] === false? true : false
                        }));
                    }}
                    target={
                        <input
                            // readOnly={true} //{timeFilter && timeFilter[`program[${id}][${nestIndex}]?.special_hoursOfOperation[${k}]?.time_filter`] === "All Day"}
                            disabled={item?.name?.time_filter === "All Day" }
                            style={{
                            padding: "4px 10px",
                            borderRadius: "3px",
                            border: "1px solid #ccc",
                            width:"65px",
                            backgroundColor: (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom") ? "#fff" : "#eee",
                            pointerEvents: (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom") ? "unset":"none"
                            }}
                            onClick={()=>{
                                setIsOpenTime((prevState)=>({
                                    ...prevState,
                                    [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`]:isOpenTime && isOpenTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] ? false : true
                                }));
                            }}
                            isselected={isOpenTime && isOpenTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`]?true:false}
                            id={`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time_dropdown`}
                            value={dropdownValue && dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] ? dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`].values : "12:00"}
                            // value={getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.open_time ? getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.open_time : "12:00" }
                        />
                    }
                    >
                    <Select
                    {...register(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`
                      )}
                      readOnly={onEdit}
                        autoFocus
                        id={`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`}
                        backspaceRemovesValue={false}
                        components={{  IndicatorSeparator: null }}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen
                        onChange={(value)=>{
                            setIsOpenTime((prevState)=>({
                                ...prevState,
                                [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`]:isOpenTime && isOpenTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] === false ? true : false
                            }));
                            setDropdownValue((prevState)=>({
                                ...prevState,
                                [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] : value
                            }));
                            setValue(`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`, value);
                        }}
                        options={question.specialHoursOperation[0].open_time.map(item=>({values:item.values,label:item.name}))}
                        placeholder=""
                        styles={selectStyles}
                        tabSelectsValue={false}
                        value={dropdownValue && dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] ? dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time`] : {values:"12:00",label:"12:00"}}
                        // value={getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.open_time ? getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.open_time : {values:"12:00",label:"12:00"} }

                    />
                    </Dropdown>
                  &nbsp;
                  <Form.Select
                    {...register(
                      `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time_meridian`
                    )}
                    readOnly={onEdit}
                    // readOnly={
                    //   timeFilter &&
                    //   timeFilter[
                    //     `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`
                    //   ]
                    //     ? timeFilter[
                    //         `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`
                    //       ] === "Custom"
                    //       ? false
                    //       : true
                    //     : true
                    // (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom") ? false : true
                    // }
                    className={`${
                        (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom")
                        ? ""
                        : "pe-none time-meridian-disabled "
                    }time-meridian`}
                    onChange={(e) => {
                    //   setValue(
                    //     `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].open_time_meridian`,
                    //     e.target.value
                    //   );
                      setSpecialHourData({
                        index: nestIndex,
                        data: getValues()?.program[id][nestIndex]?.special_hoursOfOperation,
                      });
                    }}
                  >
                    {question.specialHoursOperation[0].open_time_meridian.map(
                      (data, l) => (
                        <option key={`open_time_${l}`} value={data.name}>
                          {data.values}
                        </option>
                      )
                    )}
                  </Form.Select>{" "}
                  &nbsp;
                  <Dropdown
                    isOpen={isCloseTime && isCloseTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`]}
                    onClose={()=>{
                        setIsCloseTime((prevState)=>({
                            ...prevState,
                            [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`]: isCloseTime && isCloseTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] === false ? true : false
                        }));
                    }}
                    target={
                        <input
                            // readOnly={getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter === "All Day"}
                            style={{
                            padding: "4px 10px",
                            borderRadius: "3px",
                            border: "1px solid #ccc",
                            width:"65px",
                            backgroundColor: (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom") ? "#fff" : "#eee",
                            pointerEvents: (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom") ? "unset":"none"
                            }}
                            onClick={(e)=>{
                                setIsCloseTime((prevState)=>({
                                    ...prevState,
                                    [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`]:isCloseTime && isCloseTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] ? false : true
                                }));
                            }}
                            isselected={isCloseTime && isCloseTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] ? true : false}
                            value={dropdownValue && dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] ? dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`].values : "12:00"}
                            // value={getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.close_time ? getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.close_time.value : "12:00" }
                        />
                    }
                    >
                    <Select
                    {...register(
                        `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`
                      )}
                      readOnly={onEdit}
                        autoFocus
                        backspaceRemovesValue={false}
                        components={{  IndicatorSeparator: null }}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen
                        onChange={(value)=>{
                            setIsCloseTime((prevState)=>({
                                ...prevState,
                                [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`]:isCloseTime && isCloseTime[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] === false ? true : false
                            }));
                            setDropdownValue((prevState)=>({
                                ...prevState,
                                [`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] : value
                            }));
                            setValue(`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`, value);
                        }}
                        options={question.specialHoursOperation[0].close_time.map(item=>({values:item.values,label:item.name}))}
                        placeholder=""
                        styles={selectStyles}
                        tabSelectsValue={false}
                        // defaultValue={dropdownValue && dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] ? dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`].values :"12:00"}
                        value={dropdownValue && dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] ? dropdownValue[`program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time`] : {values:"12:00",label:"12:00"}}
                        // value={getValues() && getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.close_time ? getValues()?.program[id][nestIndex]?.special_hoursOfOperation[k]?.close_time : {values:"12:00",label:"12:00"} }
                    />
                    </Dropdown>
                  &nbsp;
                  <Form.Select
                    {...register(
                      `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].close_time_meridian`
                    )}
                    readOnly={onEdit}
                    // readOnly={
                    //   timeFilter &&
                    //   timeFilter[
                    //     `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`
                    //   ]
                    //     ? timeFilter[
                    //         `program[${id}][${nestIndex}].special_hoursOfOperation[${k}].time_filter`
                    //       ] === "Custom"
                    //       ? false
                    //       : true
                    //     : true
                    // (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom") ? false : true
                    // }
                    className={`${
                        (getValues() && getValues().program[id][nestIndex]?.special_hoursOfOperation[k]?.time_filter ==="Custom")
                        ? ""
                        : "pe-none time-meridian-disabled "
                    }time-meridian`}
                    onChange={(e) => {
                      setSpecialHourData({
                        index: nestIndex,
                        data: getValues()?.program[id][nestIndex]?.special_hoursOfOperation,
                      });
                    }}
                  >
                    {question.specialHoursOperation[0].close_time_meridian.map(
                      (data, l) => (
                        <option key={`close_time_${l}`} value={data.name}>
                          {data.values}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <br />
                  <div style={{ textAlign: "right" }}>
                    <Image
                      title="Add"
                      readOnly={onEdit}
                      width="21"
                      src={plus}
                      style={{
                        cursor: getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length >= 12 || onEdit ? "not-allowed" : "pointer",
                        opacity: getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length >= 12 || onEdit ? 0.5 : 1,
                      }}
                      onClick={() => {
                        if (!onEdit && getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length < 12) {
                          setRenderCount(getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length + 1);
                          append(programData[id].special_hoursOfOperation[0]);  
                        
                          setDaysSelected((prevState) => ({
                            ...prevState,
                            [`program[${id}][${nestIndex}].special_hoursOfOperation[${k+1}].day`]:
                            days.map((item) => ({
                                label: item.values,
                                value: item.values,
                                checked: item.isActive,
                                id: item.id,
                              }))
                          }));
                          setMonthsSelected((prevState)=>({
                            ...prevState,
                            [`program[${id}][${nestIndex}].special_hoursOfOperation[${k+1}].month`]:
                            months.map((item) => ({
                                label: item.values,
                                value: item.values,
                                checked: item.isActive,
                                id: item.id,
                              })),
                          }));                    
                          setSpecialHourData({
                            index: nestIndex,
                            data: getValues()?.program[id][nestIndex]?.special_hoursOfOperation,
                          });
                        }
                      }}
                      fluid
                    />
                    &nbsp;&nbsp;
                    <Image
                      title="Remove"
                      readOnly={onEdit}
                      src={minus}
                      // style={{
                      //   display: getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length > 1 ? "inline-block" : "none",
                      // }}
                      style={{
                        display: getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length > 1 ? "inline-block" : "none",
                        cursor: onEdit ? "not-allowed" : "pointer",
                        opacity: onEdit ? 0.5 : 1,
                      }}
                      width="21"
                      onClick={() => {
                        if (!onEdit && getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length > 1) {
                          remove(k);
                          setRenderCount(getValues()?.program[id][nestIndex]?.special_hoursOfOperation.length - 1);
                          setSpecialHourData({
                            index: nestIndex,
                            data: getValues()?.program[id][nestIndex]?.special_hoursOfOperation,
                          });
                        }
                      }}
                      fluid
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <hr />
    </div>
  );
};

SignupNestedFieldArray.propTypes = {
  nestIndex: PropTypes.number,
  register: PropTypes.func,
  control: PropTypes.object,
  question: PropTypes.object,
  id: PropTypes.string,
  days: PropTypes.array,
  months: PropTypes.array,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  programData: PropTypes.object,
  clear: PropTypes.bool,
  setSpecialHourData: PropTypes.func,
//   data: PropTypes.object,
  field_item: PropTypes.any
};

export default SignupNestedFieldArray;
