//Global Routes
import React from "react";
import { BrowserRouter as Router,Routes, Route, Navigate } from "react-router-dom";
import Header from "./pages/common/header/Header";
import Footer from "./pages/common/footer/Footer";
import DashboardComponent from "./pages/dashboard/dashboard-component";
import HomeComponent from "./pages/home";
import AdminComponent from "./pages/admin";
import VendorComponent from "./pages/vendor";
import FarmerMarkerComponent from "./pages/manage/farmerMarket";
import UsersComponent from "./pages/manage/users";
import AuditLogsComponent from "./pages/manage/auditLogs";
import CoordinatesComponent from "./pages/manage/coordinates";
import UserRegistration from "./pages/user/signup/signup-component";
import Mystore from "./pages/mystore/mystore";
import GreenBanner from "./pages/home/GreenBanner";
import BlueBanner from "./pages/home/BlueBanner";
import YellowBanner from "./pages/home/YellowBanner";

export default function RouterComponent() {
  return (
    <Router>
        <GreenBanner /> 
        <YellowBanner />
        <BlueBanner />
        <Header />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="dtafinder" element={<HomeComponent />} />
          <Route exact path="dtahip" element={<DashboardComponent />} />
          <Route path="dtahip/registration" element={<UserRegistration mode={"add"}/>}/>
          <Route path="dtahip/vendors" element={<Mystore/>}/>
          <Route path="dtahip/mystore" element={<Mystore allowedRoles={["VENDOR"]}/>}/>
          <Route path="dtahip/admin-dashboard" element={<AdminComponent allowedRoles={["ADMIN", "HIPCRD"]} />}/>
          <Route path="dtahip/manage-vendors" element={<VendorComponent allowedRoles={["ADMIN", "HIPCRD"]} />}/>
          <Route path="dtahip/manage-users" element={<UsersComponent allowedRoles={["ADMIN"]} />}/>
          <Route path="dtahip/manage-farmer-markets" element={<FarmerMarkerComponent allowedRoles={["ADMIN"]} />}/>
          <Route path="dtahip/manage-address" element={<CoordinatesComponent allowedRoles={["ADMIN"]} />}/>
          <Route path="dtahip/audit-logs" element={<AuditLogsComponent allowedRoles={["ADMIN"]} />}/>
          <Route path="*" element={<Navigate replace to="/dtahip" />}  />
        </Routes>
        <Footer />
    </Router>
  );
}
