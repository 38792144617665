import React, {useState, useEffect} from "react";
import { Grid, Box, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import CommonLoading from "../common/components/commonLoading";

import {CustomButton} from "../common/components/customButton";
import PageHeaderComponent from "../common/components/pageHeader";

import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";

import SelectDropdown from "../common/selectdropdown-component";

import "../../styles/home.scss";
import TotalCardComponent from "./totalCard";
import GraphComponent from "./graphs";
import { GET_COUNTYCITY } from "../../redux/actionTypes";
import { sortName, checkToken } from "../../utils/globalMethods";
import { getVendorStats, getStoreStats } from "../../redux/actionTypes/adminDashboard";
import {
  useNavigate
} from "react-router-dom";

const AdminComponent = (props) => {
  const navigate = useNavigate();  
 
  const [refreshKey, setRefreshKey] = useState(0);
  const [city, setCity] = useState({name:"Statewide",values:"Statewide"});
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.getCityList();
  },[]);
  
  useEffect(() => {
    const userInfo = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
     if (props?.allowedRoles && userInfo && userInfo !== null && Object.keys(userInfo).length != 0) {
      const checkRole = props.allowedRoles.find((role) => userInfo?.Role.includes(role));
      if (!checkRole) {
        navigate("/dtahip");
        return;
      }
    }
    setIsLoading(true);
    const payload = {
      "cityId":city.name==="Statewide"?"":city.name
    }
    if(checkToken()){
      props.getVendorStats(payload);
      props.getStoreStats(payload);
    }
  }, [refreshKey,city]);

  useEffect(() => {
    setIsLoading(false);
  }, [props.adminDashboard.statisticsData]);
  
  const {stats,statisticsData} = props.adminDashboard

  const chartData = [
    { value: statisticsData.totalCsaPickupSite, name: 'HIP CSA Program' },
    { value: statisticsData.totalFarmStand, name: 'HIP Farm Stand' },
    { value: statisticsData.totalFarmersMarket, name: "HIP Farmers' Market Booth" },
    { value: statisticsData.totalFarmersMarketManagers, name: "HIP Farmers' Market" },
    { value: statisticsData.totalMobileMarket, name: 'HIP Mobile Market Stop' }
  ];

  const barChart = () => {
    let data = [];
    data.push(["City","HIP CSA Program", 'HIP Farm Stand', "HIP Farmers' Market Booth","HIP Farmers' Market",'HIP Mobile Market Stop'])
    statisticsData.totalCity.forEach(ele => {
      data.push([ele.cityName,ele.totalCsaPickupSite,ele.totalFarmStand,ele.totalFarmersMarket,ele.totalFarmersMarketManagers,ele.totalMobileMarket])
    });
    return data
  } 

  const heatChart = () => {
    let data = [];
    let maxNum = 0;
    statisticsData.totalMonths.forEach(ele => {
      let month = ele.month-1;
      maxNum = Math.max(...[maxNum,ele.totalCsaPickupSite,ele.totalFarmStand,ele.totalFarmersMarket,ele.totalFarmersMarketManagers,ele.totalMobileMarket]);
      data.push([month,0,ele.totalCsaPickupSite],[month,1,ele.totalFarmStand],[month,2,ele.totalFarmersMarket],[month,3,ele.totalFarmersMarketManagers],[month,4,ele.totalMobileMarket])
    });
  
    return {data:data,maxNumber:maxNum};
  }

  useEffect(() => {
    let cityOption = props.mapdata.city.map(item => {
      return {
        name: item.id,
        values: item.cityName,
        isActive: 1
      };
    });
    let defaultData = [{name:"Statewide",values:"Statewide"}];
    setCityList(defaultData.concat(sortName(cityOption)));
  }, [props.mapdata.city]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className="dashboard-block">
        { isLoading && 
          <CommonLoading />
        }
        <Grid item xs={12} md={6}>
          <PageHeaderComponent 
            title = {"DASHBOARD - STATEWIDE VIEW"}
            showSubtitle = {false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="flex-end" spacing={{ xs: 2, md: 0.5 }} sx={{ flexGrow: 0.1 }}>
            <FormControl sx={{ width: 250 }} size="small">
              <SelectDropdown
                  value={city}
                  onChange={(v) => {
                    setCity(v);
                  }}
                  options={cityList}
                  isSearch={true}
                  defaultValueProps={{
                      default: true,
                      defaultOption: {name:"Statewide",values:"Statewide"},
                  }}
              />
            </FormControl>
            <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={() => setRefreshKey(refreshKey+1)}>
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <TotalCardComponent
            total={stats.vendorTotalCount}
            subtitle={"Total Vendors (statewide)"}
          />
        </Grid>
        <Grid item xs={4}  tabIndex={"0"} onKeyPress={(e) => { if (e.key === "Enter") {navigate("/dtahip/manage-vendors?isVerified=false")}}}>
          <TotalCardComponent
            total={stats.unverifiedCount}
            subtitle={"Total Un-Verified Vendors (statewide)"}
            link={"/dtahip/manage-vendors?isVerified=false"}
          />
        </Grid>
        <Grid item xs={4}>
          <TotalCardComponent
            total={statisticsData.totalStoreCount}
            subtitle={"Total HIP Location"}
          />
        </Grid>
        <Grid item xs={6}>
          <GraphComponent 
            title = {"HIP Locations"}
            graphType = {"pie"}
            chartData = {chartData}
          />
        </Grid>
        <Grid item xs={6}>
          <GraphComponent 
            title = {"Total HIP Locations By City"}
            graphType = {"bar"}
            chartData = {barChart()}
          />
        </Grid>
        <Grid item xs={12}>
          <GraphComponent 
            title = {"Month(s) of Operation By Type"}
            graphType = {"heat"}
            chartData = {heatChart().data}
            maxNumber = {heatChart().maxNumber}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

AdminComponent.propTypes = {
  getVendorStats: PropTypes.func.isRequired,
  adminDashboard :PropTypes.object,
  getStoreStats: PropTypes.func.isRequired,
  getCityList: PropTypes.func.isRequired,
  mapdata: PropTypes.object,
  allowedRoles: PropTypes.object,
  userAuth: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
      getVendorStats: (payload) => dispatch(getVendorStats(payload)),
      getStoreStats: (payload) => dispatch(getStoreStats(payload)),
      getCityList: (city_county) => dispatch({ type: GET_COUNTYCITY, city:city_county }),
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminComponent);