// import axios from "axios";
import { takeEvery,put } from "redux-saga/effects";//,  put
import { actionTypes } from "../actionTypes/language";
// import {JsonServerRouter} from "json-server"; //, create, router,defaults

function* getLanguageData(props) {
    try {
        yield put({type: actionTypes.SET_LANGUAGE_DATA, value: props.payload});
    } catch {
        // yield put({type: GET_USERS_FAILED});
    }
}

export const watchGetLanguageData = function*() {
    yield takeEvery(actionTypes.UPDATE_SELECTED_LANGUAGE, getLanguageData);
};
