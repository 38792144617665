import * as React from "react";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import CardContent from '@mui/material/CardContent';
import PropTypes from "prop-types";
import {
    useNavigate
  } from "react-router-dom";

const CustomCardContent = styled(CardContent)(({link}) => ({
    border: "3px solid rgba(56, 133, 87, 0.5)",
    textAlign: "center",
    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.25)",
    backgroundColor: link ? "#dc3545" : "transparent",
    color: link ? "#ffffff" : "#000000",
    cursor: link ? "pointer" : "default",
}));

const TotalCardComponent = (props) => {
    const navigate = useNavigate();
    const handleClick = () => {
      if(props.link){
          navigate(props.link)
      }  
    }
    return (
        <CustomCardContent link={props.link} onClick={handleClick}>
            <Typography variant="h4" component="div" className="card-total">
              {props.total}
            </Typography>
            <Typography sx={{ mb: 1.5 }} className="card-title">
                {props.subtitle}
            </Typography>
        </CustomCardContent>
    )
}

TotalCardComponent.propTypes = {
    total: PropTypes.number.isRequired,
    subtitle: PropTypes.string.isRequired,
    link: PropTypes.string,
};

export default TotalCardComponent;