export const GET_USERS_STARTED = "GET_USERS_STARTED";
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const GET_MAPDATA_STARTED = "GET_MAPDATA_STARTED";
export const GET_MAPDATA = "GET_MAPDATA";
export const SET_MAPDATA = "SET_MAPDATA";
export const GET_MAPDATA_FAILED = "GET_MAPDATA_FAILED";

export const SET_COUNTYCITY = "SET_COUNTYCITY";
export const GET_COUNTYCITY = "GET_COUNTYCITY";
export const GET_COUNTY = "GET_COUNTY";
export const SET_COUNTY = "SET_COUNTY";
export const GET_AREA = "GET_AREA";
export const SET_AREA = "SET_AREA";
export const GET_TYPE = "GET_TYPE";
export const SET_TYPE = "SET_TYPE";
export const GET_ORDER_OPTION = "GET_ORDER_OPTION";
export const SET_ORDER_OPTION = "SET_ORDER_OPTION";
export const GET_MONTH_OPTION = "GET_MONTH_OPTION";
export const SET_MONTH_OPTION = "SET_MONTH_OPTION";
export const GET_DAY_OPTION = "GET_DAY_OPTION";
export const SET_DAY_OPTION = "SET_DAY_OPTION";
export const GET_VENDOR_LIST = "GET_VENDOR_LIST";
export const SET_VENDOR_LIST = "SET_VENDOR_LIST";
export const CHANGE_LOADING = "CHANGE_LOADING";
export const GET_STORE_LIST = "GET_STORE_LIST";
export const GET_STORE_REPORT = "GET_STORE_REPORT";
export const GET_STORE_DETAILS = "GET_STORE_DETAILS";
export const SET_STORE_LIST = "SET_STORE_LIST";
export const SET_STORE_REPORT = "SET_STORE_REPORT";
export const SET_STORE_DETAILS = "SET_STORE_DETAILS";
export const GET_SPECIAL_HOUR = "GET_SPECIAL_HOUR";
export const SET_SPECIAL_HOUR = "SET_SPECIAL_HOUR";
export const SET_MAP_SEARCH = "SET_MAP_SEARCH";