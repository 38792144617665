import axios from "axios";
import { takeEvery, takeLatest, call, put } from "redux-saga/effects"; //,delay, delayGET_COUNTY,GET_COUNTYCITY,SET_COUNTYCITY,SET_COUNTY,
import { CHANGE_LOADING,GET_MAPDATA, 
    GET_COUNTYCITY,SET_COUNTYCITY, SET_AREA, GET_AREA, GET_TYPE, SET_TYPE,
    SET_ORDER_OPTION,GET_ORDER_OPTION,SET_MAPDATA, GET_STORE_LIST, GET_STORE_REPORT, GET_STORE_DETAILS, SET_STORE_LIST, SET_STORE_REPORT, SET_STORE_DETAILS, GET_DAY_OPTION, GET_MONTH_OPTION, SET_MONTH_OPTION, SET_DAY_OPTION, GET_VENDOR_LIST, SET_VENDOR_LIST, SET_SPECIAL_HOUR, GET_SPECIAL_HOUR } from "../actionTypes";
// import {JsonServerRouter} from "json-server"; //, create, router,defaults
import { API_URL, PUBLIC_API } from "../../config";
import axiosInstance from "../interceptor";
import { ExportStoresReport } from "../../pages/common/components/exportExcel";
import { popUpAlert } from "../../utils/globalMethods";

export const watchGetMapData = function*() {
    yield takeLatest(GET_MAPDATA, getMapData);
};

export const watchGetCityList = function* () {
    yield takeEvery(GET_COUNTYCITY, getCityList);
};

export const watchGetArea = function* () {
    yield takeEvery(GET_AREA, getArea);
};

export const watchGetType= function* () {
    yield takeEvery(GET_TYPE, getType);
};

export const watchGetOrderOption= function* () {
    yield takeEvery(GET_ORDER_OPTION, getOrderOption);
};
export const watchGetMonthOption= function* () {
    yield takeEvery(GET_MONTH_OPTION, getMonthOption);
};
export const watchGetDayOption= function* () {
    yield takeEvery(GET_DAY_OPTION, getDayOption);
};
export const watchGetVendorList= function* () {
    yield takeEvery(GET_VENDOR_LIST, getVendorList);
};

export const watchGetMapStoresList= function* () {
    yield takeLatest(GET_STORE_LIST, getMapStoresList);
};

export const watchGetStoresReport= function* () {
    yield takeLatest(GET_STORE_REPORT, getStoresReport);
};

export const watchGetStoreDetails= function* () {
    yield takeLatest(GET_STORE_DETAILS, getStoreDetails);
};

export const watchGetSpecialHourData= function* () {
    yield takeLatest(GET_SPECIAL_HOUR, getSpecialHourData);
};

function* getMapData(props) {
    // let cancelToken;
    // const cancelSource = axios.CancelToken.source();
    //Check if there are any previous pending requests
    // if (typeof cancelToken != typeof undefined) {
    //     cancelToken.cancel("Operation canceled due to new request.")
    //   }
    yield put({
        type: CHANGE_LOADING,
        payload: true
      });
    const data = {
        address: props?.search?.address,
        latitude:props?.search?.latitude,
        longitude:props?.search?.longitude,
        vendor_name:props?.search?.vendor_name,
        city: "all", // props?.search?.city,
        types:(Array.isArray(props?.search?.type) && props?.search?.type.length ===0) ? "all" : props?.search?.type,//props?.search?.type,
        order_options:(Array.isArray(props?.search?.order_options) && props?.search?.order_options.length ===0) ? "all" : props?.search?.order_options, //props?.search?.order_options, 
        days_operations:(Array.isArray(props?.search?.days) && props?.search?.days.length ===0) ? "all" : props?.search?.days,//props.search.city
        months_operations:(Array.isArray(props?.search?.months) && props?.search?.months.length ===0) ? "all" : props?.search?.months, 
    };
  
    console.log(data);
    //Save the cancel token for the current request
    // cancelToken = axios.CancelToken.source();

    try {
        const uri = `${API_URL}/vendor/Dashboard`;       
        const response = yield call(axios.post, uri, data);//, { cancelToken: cancelSource.token }
        // console.log(response);  
        // if (response) {
            yield put({
                type: CHANGE_LOADING,
                payload: false
              });
           yield put({type: SET_MAPDATA, payload: response.data.message}); //response.data.result.data.data
        // }      
    } catch {
        yield put({
            type: CHANGE_LOADING,
            payload: false
          });
          yield put({type: SET_MAPDATA, payload: []});
    } 
    // finally {
    //     if (yield cancelled()) {
    //       yield call(cancelSource.cancel);
    //     }
    // }
    
}
function* getCityList() {
    let url =  `${PUBLIC_API}/city/list`;
    try {
        const uri = url;
        const data = {};
        const result = yield call(axiosInstance.post, uri, data);
        yield put({ type: SET_COUNTYCITY, payload: result.data.data });
    } 
    catch(e) {
        console.log("Failed",e);
    }
}

function* getArea() {

    // console.log("getting location type ques");
    try {
        const uri = `${API_URL}/lov/area_with_in`;
        const result = yield call(axios.get, uri);
        // console.log(result);
          yield put({ type: SET_AREA, payload: result.data.data });
        // console.log("loc type ques loaded successfully");
    } 
    catch {
        console.log("Failed");
    }
}

function* getType() {
    // console.log("getting location type ques");
    try {
        const uri = `${API_URL}/lov/type`;
        const result = yield call(axios.get, uri);
        // console.log(result);
          yield put({ type: SET_TYPE, payload: result.data.data });
        // console.log("loc type ques loaded successfully");
    } 
    catch {
        console.log("Failed");
    }
}

function* getOrderOption() {
    // console.log("getting location type ques");
    try {
        const uri = `${API_URL}/lov/orderOptionType`;
        const result = yield call(axios.get, uri);
        // console.log(result);
          yield put({ type: SET_ORDER_OPTION, payload: result.data.data });
        // console.log("loc type ques loaded successfully");
    } 
    catch {
        console.log("Failed");
    }
}

function* getMonthOption() {
    // console.log("getting location type ques");
    try {
        const uri = `${API_URL}/lov/months_operation`;
        const result = yield call(axios.get, uri);
        // console.log(result);
          yield put({ type: SET_MONTH_OPTION, payload: result.data.data });
        // console.log("loc type ques loaded successfully");
    } 
    catch {
        console.log("Failed");
    }
}

function* getDayOption() {
    // console.log("getting location type ques");
    try {
        const uri = `${API_URL}/lov/days_operation`;
        const result = yield call(axios.get, uri);
        // console.log(result);
          yield put({ type: SET_DAY_OPTION, payload: result.data.data });
        // console.log("loc type ques loaded successfully");
    } 
    catch {
        console.log("Failed");
    }
}

function* getVendorList(props) {
    try {
        const uri = `${PUBLIC_API}/vendors/name/list`;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: SET_VENDOR_LIST, payload: result.data.data });
    } 
    catch(err) {
        console.log("Failed",err);
        yield put({ type: SET_VENDOR_LIST, payload: [] });
    }
}


function* getMapStoresList(props) {
    try {
        const uri = `${PUBLIC_API}/stores/list`;
        yield put({type: CHANGE_LOADING, payload: true });
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: SET_STORE_LIST, payload: result.data });
    } 
    catch(e) {
        console.log("Failed");        
        if (e?.response?.data?.code == "11") {
            yield put({type: SET_STORE_LIST, payload: {
                code: '11',
                data: []
            }});
        }
    }
}

function* getStoresReport(props) {
    try {
        const uri = `${PUBLIC_API}/stores/report`;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: SET_STORE_REPORT, payload: result.data.data });
        ExportStoresReport(result.data.data);
    }
    catch(e) {
        console.log("Failed",e);
    }
}

function* getStoreDetails(props) {
    try {
        const uri = `${PUBLIC_API}/store/details`;
        const result = yield call(axiosInstance.post, uri, props.payload);
        yield put({ type: SET_STORE_DETAILS, payload: result.data.data });
    }
    catch {
        console.log("Failed");
    }
}

function* getSpecialHourData(props) {
    // console.log("getting location type ques");
    try {
        // const uri = `${API_URL}/vendor/list/vendornames";
        const uri = `${API_URL}/store/id/${props.value}/specialHour`;
        const result = yield call(axios.get, uri);
        console.log("specialhour saga::",result);
          yield put({ type: SET_SPECIAL_HOUR, payload: result.data.data });
        // console.log("loc type ques loaded successfully");
    } 
    catch {
        console.log("Failed");
    }
}