import axios from "axios";
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_BANNER, SET_BANNER } from "../actionTypes/banner";
import { PUBLIC_API } from "../../config";

export const watchBannerSaga = function*() {
    yield takeEvery(GET_BANNER, watchBanner);
};

function* watchBanner() {
    try {
        const uri = `${PUBLIC_API}/banner`;
        const response = yield call(axios.post, uri);
        yield put({type: SET_BANNER, value: response.data});
    } catch {
        console.log("Failed")
    }
    
}
