
const initialState = {
    stats:{
        vendorTotalCount:0,
        unverifiedCount:0,
        verifiedCount:0,
    },
    statisticsData: {
        totalStoreCount: 0,
        totalCsaPickupSite: 0,
        totalFarmStand: 0,
        totalFarmersMarket: 0,
        totalFarmersMarketManagers: 0,
        totalMobileMarket: 0,
        totalMonths: [],
        totalCity: []
    }
};
  
  const adminDashboardReducer = (state=initialState, action) => {
      switch(action.type) {
          case "SET_VENDOR_STATS":{
              return {...state,stats:{
                vendorTotalCount:action.payload.vendorTotalCount,
                unverifiedCount:action.payload.unverifiedCount,
                verifiedCount:action.payload.verifiedCount
              }};  
          }
          case "SET_STORE_STATS" :{
            return {...state,statisticsData:action.payload.statisticsData};
          }
        default:
          return state;
      }
    };
  
    export default adminDashboardReducer;