// import { sections } from "./data/signup-section";

const actionTypes = {
	locTypeProgramPagesAction: "LOCTYPE_PROGRAM_PAGES_FULLFILLED",
	serviceFailedAction: "PROGRAM_NETWORK_FAILED_LOAD_DATA",
	postProgramData: "POST_LOCTYPE_PROGRAM_INFO_DATA",
	fillInputValuesAction: "FILL_PROGRAM_INPUT_VALUES_STATE",
	locTypeSectionData: "LOCTYPE_PROGRAM_SNAP_SECTIONS_FULLFILLED",
};

// export function locTypeProgramQuestionsSections(sections) {
// 	return dispatch => {
// 		return dispatch(locTypeProgramSections(sections));
// 	};
// }
export function locTypeProgramQuestionsSections(sections) {
	return {
		type: actionTypes.locTypeProgramPagesAction,
		payload: sections
	};
}

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(UserRegistrationComponent);


