import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";
import LoginComponent from "../user/login/login";
import AdminLoginComponent from "../user/login/admin-login";
import '../../styles/bluebanner.scss';
import { AppBar, Grid, Menu, MenuItem } from '@mui/material';
// import Button from '@mui/material/Button';
import LanguageSelect from '../common/language/languageSelect';
import HelpComponent from "../../pages/user/help";
import { Link, useNavigate } from 'react-router-dom';
import { authUserDetails, tokenRevoke } from '../../redux/actionTypes/auth';
import ForgotPwdComponent from '../user/forgotpassword/forgotpassword';
import { pageRefresh } from '../../utils/globalMethods';
import { Button, Dropdown} from 'react-bootstrap';



const BlueBanner = (props) => {
  const navigate = useNavigate();

  const [language, setLanguage] = useState({});
  const [helpModalShow, setHelpModalShow] = useState(false);

  const [modalShown, toggleModal] = useState(false);
  const [modalShownAdmin, toggleModalAdmin] = useState(false);
  const [forgotShown, toggleForgotModal] = useState(false);
  // const [state, dispatch] = useReducer(reducer, initialState);
  const [fullName, setFullName] = useState(null);
  const [menuList, SetMenuList] = useState(false);
  const [pathname, setPathName] = useState(window.location.pathname);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const { authenticated, user } = props.userAuth;

  useEffect(() => {
    if (authenticated && Object.keys(user).length == 0) {
      const payload = {
        "primaryEmailAddr": authenticated,
        navigate: navigate
      }
      props.authUserDetails(payload)
    }
  }, [props.userAuth.authenticated])

  useEffect(() => {
    if (Object.keys(user).length != 0) {
      setFullName(user.Name)
    }
  }, [props.userAuth.user])

  useEffect(() => {
    setLanguage(props.language)
  }, [props.language]);

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [window.location.pathname])

  useEffect(() => {
    const pathList = pathname.split('/')
    SetMenuList(pathList.includes("dtahip"));
    let path = pathList[pathList.length - 1]
    if (pathname.slice(-1) == "/") {
      path = pathList[pathList.length - 2]
    }

    if (user.Role === "VENDOR") {
      if (!(path == "" || path == "dtahip" || path == "mystore")) {
        navigate("/dtahip");
      }
    } else if (Object.keys(user).length == 0) {
      if (!(path == "" || path == "dtahip" || path == "registration")) {
        navigate("/dtahip");
      }
    }

  }, [pathname]);



  const tokenRevoke = () => {
    const payload = {
      navigate: navigate
    };
    props.tokenRevoke(payload);
  }

  useEffect(() => {
    if (props?.userAuth?.tokenRevoke && props?.userAuth?.tokenRevoke?.status == "SUCCESS") {
      pageRefresh();
    }
  }, [props.userAuth.tokenRevoke]);
  const openLogin = () => {
    setAnchorEl2(null);
    toggleModal(true);
  };

  const openLoginAdmin = () => {
    toggleModalAdmin(true);
  };

  const openForgotPwd = () => {
    toggleForgotModal(true);
  };

  const toggleHelpModal = () => {
    setHelpModalShow(true);
  }

  const stlyedHeader = {
    backgroundColor: '#14558F',
    // borderBottom: "5px solid #006e52",
    color: '#ffffff',
    // padding: "8px 0px"
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose2 = () => {
    setAnchorEl2(null);
  };

  const handleMenuOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleMenuClose3 = () => {
    setAnchorEl3(null);
  };

  return (<>
    {forgotShown ?
      (
        <ForgotPwdComponent
          shown={forgotShown}
          close={() => {
            toggleForgotModal(false);
          }}
        />
      )
      : (
        ""
      )}
    {helpModalShow ?
      (
        <HelpComponent
          shown={helpModalShow}
          close={() => {
            setHelpModalShow(false);
          }}
          language={language}
        />
      )
      : (
        ""
      )}
    <Grid container item xs={12} className='blue-wrapper'>
      {modalShown && (
        <LoginComponent
          shown={modalShown}
          close={(forgotpwd = "") => {
            toggleModal(false);
            if (forgotpwd) {
              openForgotPwd();
            }
          }}
          language={language}
        />
      )}
      {modalShownAdmin && (
        <AdminLoginComponent
          shown={modalShownAdmin}
          close={(forgotpwd = "") => {
            toggleModalAdmin(false);
            if (forgotpwd) {
              openForgotPwd();
            }
          }}
          language={language}
        />
      )}
      {(authenticated && menuList) ? (
        // <Grid container maxWidth="lg" className="wrapper-bluebanner">
      <>
        <Grid item xs={12} sx={{ mx: 2 }} display={{ xs: 'none', md: 'block' }} className="banner div-center blue-menu  ">
          <AppBar className='blue-header-root' position="static" sx={stlyedHeader} >
            {(user.Role === "ADMIN" || user.Role === "HIPCRD") &&
              <>
                <Button className='menu-item mr-3 font-regular16 textTransform-capitalize' href="/dtahip/admin-dashboard" tabIndex={0}>Dashboard</Button>
                <Button className='menu-item mr-3 font-regular16 textTransform-capitalize' href='/dtahip/manage-vendors'>Vendors</Button>
                {user.Role === "ADMIN" && <>
                  <Button className='menu-item mr-3 font-regular16 textTransform-capitalize'
                    onClick={handleMenuOpen}>
                    <span>Administration</span>
                    <i className="fa fa-caret-down caretIcon-position" aria-hidden="true"></i>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem className='language-menu-item font-regular16' onClick={handleMenuClose}>
                      <Link to={"/dtahip/manage-users"} style={{ textDecoration: 'none', color: 'inherit' }}>
                        Manage Users
                      </Link>
                    </MenuItem>
                    <MenuItem className='language-menu-item font-regular16' onClick={handleMenuClose}>
                      <Link to={"/dtahip/manage-farmer-markets"} style={{ textDecoration: 'none', color: 'inherit' }}>
                        Manage Farmer Markets
                      </Link>
                    </MenuItem>
                    <MenuItem className='language-menu-item font-regular16' onClick={handleMenuClose}>
                      <Link to={"/dtahip/manage-address"} style={{ textDecoration: 'none', color: 'inherit' }}>
                        Manage Coordinates
                      </Link>
                    </MenuItem>
                    <MenuItem className='language-menu-item font-regular16' onClick={handleMenuClose}>
                      <Link to={"/dtahip/audit-logs"} style={{ textDecoration: 'none', color: 'inherit' }}>
                        Audit Logs
                      </Link>
                    </MenuItem>
                  </Menu>
                </>}
              </>}
              <Button className='menu-item textTransform-capitalize' onClick={handleMenuOpen3}>
                <span className="d-inline-block  mr-2" >
                  <i className="fa fa-user" aria-hidden="true"></i>{" "}
                  {fullName}</span> {"  "}
                  <i className="fa fa-caret-down caretIcon-position" aria-hidden="true"></i>
              </Button>
              <Menu
                id="simple-user"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleMenuClose3}
              >
                {user.Role === "VENDOR" && <MenuItem className='language-menu-item font-regular16'>
                  <Link to={"/dtahip/mystore"} style={{ textDecoration: 'none', color: 'inherit' }}>My Business</Link>
                </MenuItem>}
                <MenuItem className='language-menu-item font-regular16' onClick={() => { tokenRevoke(); }}>Logout</MenuItem>
              </Menu>
          </AppBar>
        </Grid>
        <Grid item className='blue-hamburger'>
         <nav className="header navbar navbar-expand-lg navbar-light blue-wrapper">
           <button className="navbar-toggler" type="button" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
             <span className="navbar-toggler-icon">
             </span>
           </button>
           <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}  id="navbarSupportedContent">
             <ul className="navbar-nav topMenu ml-auto mt-4">
             {(user.Role==="ADMIN" || user.Role==="HIPCRD") &&
               <>
                 <li className="nav-item dropdown">
                   <a
                     href="/dtahip/admin-dashboard"
                     className="btn btn-outline-primary btn-block pl-3 text-left nav-text  width-100"
                   >
                     Dashboard
                   </a>
                 </li>
                 <li className="nav-item dropdown">
                   <a
                     // onClick={openLogin}
                     href="/dtahip/manage-vendors"
                     className="btn btn-outline-primary btn-block pl-3 text-left nav-text width-100"
                   >
                     Vendors
                   </a>
                 </li>
                 {user.Role==="ADMIN" && <li className="nav-item dropdown">
                   <Dropdown>
                     < Dropdown.Toggle className="nav-text">
                       <span className="d-inline-block mr-3 font-medium16">Administration</span>
                       <small className="fa fa-chevron-down mt-2"></small>
                     </Dropdown.Toggle>
                     <Dropdown.Menu style={{border:'1px solid #006e52'}}>
                       <Dropdown.Item href="/dtahip/manage-users">
                         Manage Users
                       </Dropdown.Item>
                       <Dropdown.Item href="/dtahip/manage-farmer-markets">
                         Manage Farmer Markets
                       </Dropdown.Item>
                       <Dropdown.Item  href="/dtahip/manage-address">
                         Manage Coordinates
                       </Dropdown.Item>
                       <Dropdown.Item href="/dtahip/audit-logs">
                         Audit Logs
                       </Dropdown.Item>
                     </Dropdown.Menu>
                   </Dropdown>
                 </li>}
               </>}
               <li className="nav-item dropdown">
                 <Dropdown>
                   <Dropdown.Toggle className="nav-text">
                     <span className="d-inline-block mr-3 font-medium16" >
                       <i className="fa fa-user" aria-hidden="true"></i>{" "}
                       {fullName}</span> {"  "}
                     <small className="fa fa-chevron-down mt-2"></small>
                   </Dropdown.Toggle>
                   <Dropdown.Menu style={{border:'1px solid #006e52'}}>
                     {user.Role==="VENDOR" && <Dropdown.Item href="/dtahip/mystore">My Business</Dropdown.Item>}
                     <Dropdown.Item onClick={() => {tokenRevoke();}}>Logout</Dropdown.Item>
                   </Dropdown.Menu>
                 </Dropdown>
               </li>
             </ul>
           </div>
         </nav>
        </Grid>
      </>
      ) : (<>
        <Grid item xs={12} sx={{ mx: 2 }} display={{ xs: 'none', md: 'block' }} className="banner div-center  blue-menu">
          {/* <Grid item xs={12} sx={{ mx: 2,cursor: 'pointer' }} display="flex" alignItems="center"> */}
          <AppBar className='blue-header-root' position="static" sx={stlyedHeader} >
            <span className="sr-only">Header Section</span>
            <Button
              className='menu-item mr-3 font-regular16 textTransform-capitalize'
              onClick={handleMenuOpen}
              aria-label='Service have the popup listbox'
            >
              <span >{language.Services}</span>
              <i className="fa fa-caret-down caretIcon-position" aria-hidden="true"></i>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              aria-haspopup="false"
              aria-owns={anchorEl ? "simple-menu" : undefined}
            >
              <MenuItem className='language-menu-item font-regular16' onClick={handleMenuClose}>
                <Link to={"/dtahip"} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {language.HealthyIncentivesProgram} {language.hip}
                </Link>
              </MenuItem>
               
            </Menu>
            <span className='nav-item'>
              <LanguageSelect className='menu-item font-regular16' />
            </span>
            {menuList && (<>
              <Button
                className='menu-item mr-3 font-regular16 textTransform-capitalize' 
                aria-label='For Providers have popup list box'
                onClick={handleMenuOpen2}
              >
                <span className='mr-2'>{language["For Providers"]}</span>
                <i className="fa fa-caret-down caretIcon-position" aria-hidden="true"></i>
              </Button>
              <Menu
                id="menu2"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleMenuClose2}
              >
                <MenuItem className='language-menu-item font-regular16' onClick={() => { openLogin() }}>{language.HealthyIncentivesProgram} {language.hip}</MenuItem>

              </Menu>
              <Button className='menu-item mr-3 font-regular16 textTransform-capitalize' onClick={() => { openLoginAdmin() }}>{language.AdminLogin}</Button>
              <Button className="menu-item textTransform-capitalize" onClick={toggleHelpModal}>
                <span>{language.help}&nbsp;</span>
                <i className="fa fa-question-circle"></i>
              </Button>
            </>)}
          </AppBar>
        </Grid>
        <Grid item className='blue-hamburger'>
                  <nav className="header navbar navbar-expand-lg navbar-light blue-wrapper">
                    <button className="navbar-toggler" type="button" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                      <span className="navbar-toggler-icon">
                      </span>
                    </button>
                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}  id="navbarSupportedContent">
                      <ul className="navbar-nav topMenu ml-auto">
                        <li className="nav-item dropdown">
                          <Dropdown>
                            < Dropdown.Toggle className="nav-text width-100 text-left">
                              <span className="d-inline-block mr-3">{language.Services}</span>
                              <small className="fa fa-chevron-down mt-2"></small>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="/dtahip">
                              {language.HealthyIncentivesProgram} {language.hip}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li className="nav-item dropdown">
                          <LanguageSelect className="form-control language-select border-brand" />
                        </li>
                        {menuList && (
                          <>
                            <li className="nav-item dropdown">
                              <Dropdown>
                                < Dropdown.Toggle className="nav-text width-100 text-left">
                                  <span className="d-inline-block mr-3  ">{language["For Providers"]}</span>
                                  <small className="fa fa-chevron-down mt-2"></small>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => {openLogin()}}>
                                  {language.HealthyIncentivesProgram} {language.hip}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                            <li className="nav-item">
                              <button 
                                tabIndex="0"
                                onClick={() => {openLoginAdmin()}}
                                className="nav-text btn button-outline btn-block pl-3 text-left width-100"
                              >
                                {language.AdminLogin}
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                tabIndex="0"
                                onClick={toggleHelpModal}
                                className="nav-text btn button-outline btn-block pl-3 text-left width-100"
                              >
                                <span className="d-inline-block mr-3">{language.help}</span> 
                                <small className="fa fa-question-circle mt-2"></small>
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </nav>
                </Grid>
      </>)}
    </Grid>
  </>
  )

}

BlueBanner.propTypes = {
  vendorDetails: PropTypes.object,
  language: PropTypes.object,
  mapdata: PropTypes.object,
  selectedLanguage: PropTypes.string,
  userAuth: PropTypes.object,
  authUserDetails: PropTypes.func,
  tokenRevoke: PropTypes.func.isRequired

};

function mapDispatchToProps(dispatch) {
  return {
    authUserDetails: (payload) => dispatch(authUserDetails(payload)),
    tokenRevoke: (payload) => dispatch(tokenRevoke(payload))

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BlueBanner);