


import * as React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const SearchComponent = (props) => {
    return (
        <Stack direction="row" spacing={0.5} justifyContent={"flex-end"} >
            <Typography display="inline" mt={1} variant="body2" >Search:</Typography>
            <TextField  label="" inputProps={{style:{fontSize:'12px' }}} size="small" id="outlined-size-normal" value={props?.searchText} onChange={(e)=>props.handleChange(e)} placeholder={props.placeholder} />
        </Stack>
    )
}

SearchComponent.propTypes = {
    placeholder: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    searchText: PropTypes.string
    // showSubtitle: PropTypes.bool.isRequired,
    // count:PropTypes.number,
};

export default SearchComponent;