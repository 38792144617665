export const GET_BANNER = "GET_BANNER";
export const SET_BANNER = "SET_BANNER";

export const actionTypes = {
  GET_BANNER : "GET_BANNER",
  SET_BANNER : "SET_BANNER"
}

export  function getBanner(payload) {
    return {
      type: actionTypes.GET_BANNER,
      payload: payload
    };
}