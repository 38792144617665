import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import { Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import '../../styles/home.scss';
import Typography from "@mui/material/Typography";
import MapComponent from "../common/components/mapComponent";

const CoordinatesEditComponent = (props) => {
    const { validated, handleSubmit, onEdit, row } = props;
    const [lat, setLat] = useState(row.latitude);
    const [lng, setLng] = useState(row.longitude);

    const onMarkerChange = (e) => {
        setLat(e.latLng.lat());
        setLng(e.latLng.lng());
    }
    
    return (
        <div className="form-block">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Typography mb={2} maxWidth={400} lineHeight="lg">
                            <Typography variant="subtitle2" display="inline">Store / Vendor Name - </Typography>{row.vendorName}
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group as={Col} controlId="formLatitude">
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control type="text" size="lg" value={lat} disabled  />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group as={Col} controlId="formLongitude">
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control type="text" size="lg" value={lng} disabled  />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Control type="text" defaultValue={row.storeId} hidden />
                <Form.Control type="text" defaultValue={row.addrId} hidden />
                <Row>
                    <Col className="mt-3">
                        <MapComponent 
                            lat={lat}
                            lng={lng}
                            row={row}
                            onMarkerChange={onMarkerChange} 
                            draggable={true}
                        />
                    </Col>
                </Row>
                <Row className="modal-footer">
                    <Col>
                        <Button variant="primary" type="submit" className="float-right">
                            {onEdit ? 'Update' : 'Update'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

CoordinatesEditComponent.propTypes = {
    validated: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onEdit: PropTypes.bool,
    row: PropTypes.object
};


export default CoordinatesEditComponent;
